import React, { useState } from "react";
import TimezoneSelect from "react-timezone-select";

import Loader from "../common/loader";
import { convertToNumber, isAdmin } from "../../utils";
import { updateUser } from "../../services";
import { CloseIcon } from "../icons";

import icTick from "../../assets/icons/ic_tick.svg";
import icCircleTick from "../../assets/icons/ic_circle-tick.svg";

export default function SubscriptionModal({
  user,
  departments,
  handleClose,
  reload,
}) {
  const [loading, setLoading] = useState(false);

  //   const [username, setUsername] = useState(user.name);
  //   const [compensation, setCompensation] = useState(user.compensation);
  //   const [department, setDepartment] = useState(user.departmentId);
  //   const [timezone, setTimezone] = useState(
  //     user.timezone
  //       ? user.timezone
  //       : Intl.DateTimeFormat().resolvedOptions().timeZone
  //   );

  //   const handleSave = async () => {
  //     const params = {
  //       name: username,
  //       department,
  //       timezone: typeof timezone === "string" ? timezone : timezone.value,
  //     };
  //     if (isAdmin()) {
  //       params["compensation"] = compensation;
  //     }

  //     setLoading(true);
  //     const res = await updateUser(user.id, params);
  //     if (res && res.success) {
  //       reload();
  //       handleClose();
  //     }
  //     setLoading(false);
  //   };

  return (
    <div className="pop-wrapper subscription-modal">
      <div className="inner-wrapper wrapper">
        <div className="pop-content subscription-content">
          <div className="current-plan">
            <div className="button-close">
              <CloseIcon />
            </div>
            <div className="fs-48 fw-700 tc-body lh-56 mt-16">
              Save <span className="text-blue-gradient">more</span> time & money
            </div>
            <div className="mt-4">
              <div className="tc-secondary">
                Add more than 3 users and save even more time & money
              </div>
            </div>
            <div className="current-plan-box mt-16">
              <div className="current-plan-badge">Current Plan</div>
              <div className="fs-30 fw-700 tc-body mt-4">Free</div>
              <div className="fs-14 tc-secondary">$0/month</div>
              <div className="d-flex flex-column gap-4 mt-6">
                <div className="d-flex align-items-center gap-2">
                  <img src={icTick} />
                  <span className="fs-16 tc-body">up to 3 users</span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img src={icTick} />
                  <span>This is a feature</span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img src={icTick} />
                  <span>This is a feature</span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img src={icTick} />
                  <span>This is a feature</span>
                </div>
              </div>
            </div>
          </div>
          <div className="paid-plan">
            <div className="d-flex flex-column align-items-center">
              <div className="fs-30 fw-700 tc-white">Team plan</div>
              <div className="d-flex align-items-center gap-2">
                <div className="fs-48 fw-700 tc-white">$3</div>
                <div className="d-flex flex-column fs-14 fw-700 tc-secondary">
                  <span>/ user</span>
                  <span>/ month</span>
                </div>
              </div>
            </div>
            <button className="add-button my-16">Add Users</button>
            <div className="d-flex flex-column gap-4 ps-4">
              <div className="d-flex align-items-center gap-2">
                <img src={icCircleTick} />
                <span className="fs-14 tc-white">This is a feature</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img src={icCircleTick} />
                <span className="fs-14 tc-white">This is a feature</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img src={icCircleTick} />
                <span className="fs-14 tc-white">This is a feature</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img src={icCircleTick} />
                <span className="fs-14 tc-white">This is a feature</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <img src={icCircleTick} />
                <span className="fs-14 tc-white">This is a feature</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

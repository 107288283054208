import React, { memo, useEffect, useState } from "react";
import ChartComponent from "./ChartComponent";
import Loader from "../common/loader";
import { Chart, Unit } from "../../constants";
import { formattedLabel } from "../../utils";

const BarChart = ({ loading, chartData, height, unit = Unit.HR }) => {
  const [data, setData] = useState();
  const [options, setOptions] = useState();

  const formatter = (value, context) => {
    if (value === 0) return 0;
    return formattedLabel(value, unit);
  };

  const yTicksCallback = (value) => {
    return formattedLabel(value, unit);
  };

  useEffect(() => {
    if (
      chartData &&
      typeof chartData.labels !== "undefined" &&
      typeof chartData.data !== "undefined"
    ) {
      const tempData = JSON.parse(JSON.stringify(Chart.Bar.DATA));
      tempData["labels"] = chartData.labels;
      tempData.datasets[0]["data"] = chartData.data;
      tempData.datasets[0].backgroundColor =
        Chart.Bar.Background.LinearGradient;
      tempData.datasets[0].datalabels.color = Chart.Bar.DataLabels.Color;
      tempData.datasets[0].datalabels.formatter = formatter;
      setData(tempData);

      const tempOptions = JSON.parse(JSON.stringify(Chart.Bar.OPTIONS));
      tempOptions.scales.y.ticks["callback"] = yTicksCallback;
      tempOptions.layout = {
        padding: {
          top: 40,
        },
      };
      setOptions(tempOptions);
    }
  }, [chartData]);

  return (
    <div>
      {loading && <Loader className="mx-auto" />}
      {!loading && chartData && chartData.labels && chartData.data && (
        <ChartComponent
          chartType={Chart.Type.BAR}
          data={data}
          options={options}
          height={height}
        />
      )}
    </div>
  );
};

export default memo(BarChart);

import Cookies from "js-cookie";
import { Cookie } from "../constants";

export function getTokenFromCookie() {
  return Cookies.get(Cookie.Keys.TOKENS);
}

export function getHeaders() {
  const token = getTokenFromCookie();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
}

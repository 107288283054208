import { useState } from "react";

import SideBar from "../../component/sidebar/SideBar";
import TeamPofile from "../../component/settings/TeamPofile";
import BillingSettings from "../../component/settings/BillingSettings";
import WebhookSettings from "../../component/settings/webhookSettings";
import Header from "../../component/common/header";
import { HEADER, SettingTabs } from "../../constants";
import { isAdmin } from "../../utils";

function Settings() {
  const userEmail = localStorage.getItem("email");

  const [tab, setTab] = useState(SettingTabs.TEAM);

  const [innerData, setInnerData] = useState(false);
  const [transactions, setTransactions] = useState();
  const [settings, setSettings] = useState();
  const [latestTransactions, setLatestTransactions] = useState();
  const [currentSubscription, setCurrentSubscription] = useState();
  const [paginationData, setPaginationData] = useState();
  const [maximumLimit, setMaximumLimit] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [reloadData, setReloadData] = useState(false);

  const isVisibleWebhookSetting = () => {
    if (userEmail && userEmail.indexOf("@calendyze.com") !== -1) {
      return true;
    }
    return false;
  };

  // const getBillingDetails = async () => {
  //   await axios
  //     .get(
  //       `${process.env.REACT_APP_API_BASE_URL}/user/billing-history/?page=${pageCount}&per_page=10`,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.data.is_active_subscription) {
  //         setInnerData(true);
  //         setTransactions(res.data.data.transactions);
  //         setLatestTransactions(res.data.data.latest_transaction);
  //         setCurrentSubscription(res.data.data.latest_subscription);
  //         setSettings(res.data.data.settings);
  //         setPaginationData(res.data.data.paginationData);
  //         setMaximumLimit(res.data.data.maximum_limit);
  //       }
  //     });
  // };

  // useEffect(() => {
  //   getBillingDetails();
  // }, [pageCount, reloadData]);

  return (
    <div
      className="d-flex vh-min-100 bg-blue-lt"
      data-kt-app-sidebar-fixed="true"
    >
      <div className="d-flex flex-column flex-root app-root">
        <div className="app-page flex-column flex-column-fluid">
          <div className="app-wrapper flex-column flex-row-fluid">
            <SideBar />
            <div className="app-main flex-column flex-row-fluid">
              <Header heading={HEADER.Title.Settings} />
              <div className="app-content flex-column-fluid content-wrapper">
                <div className="setting-page">
                  <div className="tab-header">
                    <div
                      className={`tab-item ${
                        tab === SettingTabs.TEAM ? "active" : ""
                      }`}
                      onClick={() => setTab(SettingTabs.TEAM)}
                    >
                      Team
                    </div>
                    <div
                      className={`tab-item ${
                        tab === SettingTabs.BILLING ? "active" : ""
                      }`}
                      onClick={() => setTab(SettingTabs.BILLING)}
                    >
                      Billing
                    </div>
                    {isVisibleWebhookSetting() && (
                      <div
                        className={`tab-item ${
                          tab === SettingTabs.WEBHOOK ? "active" : ""
                        }`}
                        onClick={() => setTab(SettingTabs.WEBHOOK)}
                      >
                        Webhook
                      </div>
                    )}
                  </div>
                  <div className="tab-content">
                    {tab === SettingTabs.TEAM && <TeamPofile />}
                    {tab === SettingTabs.BILLING && (
                      <BillingSettings
                        settings={settings}
                        maximum_limit={maximumLimit}
                        setReloadData={setReloadData}
                        innerData={innerData}
                        transactions={transactions}
                        latestTransactions={latestTransactions}
                        currentSubscription={currentSubscription}
                        paginationData={paginationData}
                        setPageCount={setPageCount}
                      />
                    )}
                    {isAdmin() &&
                      isVisibleWebhookSetting() &&
                      tab === SettingTabs.WEBHOOK && <WebhookSettings />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Settings;

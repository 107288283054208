import React, { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";

import Loader from "../common/loader";
import { Goal } from "../../constants";
import { deleteGoal } from "../../services";

import icThreeDots from "../../assets/icons/ic_three-dots.svg";
import { archiveGoal } from "../../services/goal.service";
import { getGoalStatus, getGoalStatusIcon, getTargetName } from "../../utils";

const GoalTable = ({ reload, activeGoals, archivedGoals }) => {
  const [loading, setLoading] = useState(false);

  const [active, setActive] = useState(true);
  const [goals, setGoals] = useState([]);

  const handleActiveAndArchive = (value) => {
    setActive(value);
    if (value) {
      setGoals(activeGoals);
    } else {
      setGoals(archivedGoals);
    }
  };

  const handleClickArchive = async (goalId, isArchived) => {
    const params = {
      goalId,
      isArchived: !isArchived,
    };
    const res = await archiveGoal(params);
    if (res) {
      reload();
      toast.success(
        `Goal ${isArchived ? "unarchived" : "archived"} successfully.`
      );
    }
  };

  const handleClickDelete = async (goalId) => {
    const res = await deleteGoal(goalId);
    if (res) {
      reload();
      toast.success("Goal deleted successfully.");
    }
  };

  useEffect(() => {
    if (active) {
      setGoals(activeGoals);
    } else {
      setGoals(archivedGoals);
    }
  }, [activeGoals, archivedGoals]);

  return (
    <div className="w-100 goal-list">
      <div className="d-flex align-items-center">
        <div className="d-flex ms-6 btn-switch">
          <div
            className={`d-flex align-items-center px-6 button ${
              active ? "active" : "inactive"
            }`}
            onClick={() => handleActiveAndArchive(true)}
          >
            <span>Active Goals</span>
          </div>
          <div
            className={`d-flex align-items-center px-6 button ${
              active ? "inactive" : "active"
            }`}
            onClick={() => handleActiveAndArchive(false)}
          >
            <span>Archived</span>
          </div>
        </div>
      </div>
      {loading && <Loader className="mx-auto" />}
      <div>
        <div className="d-flex align-items-center rounded-4 p-3 gap-2 item-header">
          <div>Status</div>
          <div className="flex-grow-1 ms-3">Goal</div>
          <div className="success">Success</div>
        </div>
        {goals.map((item, index) => (
          <div
            key={`item_${index}`}
            className={`d-flex align-items-center rounded-4 p-3 gap-2 mb-2 item status-${getGoalStatus(
              item.status
            )}`}
          >
            <div>
              <img src={getGoalStatusIcon(item.status)} />
            </div>
            <div className="flex-grow-1 ms-3">
              <span className="mx-2">{Goal.Text[item.type][0]}</span>
              <span className="badge">{getTargetName(item)}</span>
              <span className="mx-2">{Goal.Text[item.type][1]}</span>
              <span className="badge">
                {item.type === Goal.Type.MONEY
                  ? `$${item.targetValue}`
                  : item.targetValue}
              </span>
              {item.type !== Goal.Type.MONEY && (
                <span className="mx-2">{Goal.Text[item.type][2]}</span>
              )}
              {item.type === Goal.Type.TIME && (
                <span className="badge">{item.duration}</span>
              )}
            </div>
            <div className="d-flex gap-2 success">
              {item.successList.map((s, si) => (
                <div
                  key={`si_${si}`}
                  className={`${
                    typeof s.success === "number" ? "align-h-center me-2" : ""
                  }`}
                >
                  <div
                    className={`${typeof s.success === "number" ? "" : "ps-2"}`}
                  >
                    {s.title}
                  </div>
                  {typeof s.success === "number" ? (
                    <div
                      className={`circle-status ${getGoalStatus(s.success)}`}
                    />
                  ) : (
                    <div className="d-flex gap-2 mx-2">
                      {s.success.map((st, st_i) => (
                        <div
                          key={`st_i_${st_i}`}
                          className={`circle-status ${getGoalStatus(st)}`}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="btn-action">
              <Dropdown>
                <Dropdown.Toggle>
                  <img src={icThreeDots} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleClickArchive(item.id, item.isArchived)}
                  >
                    {item.isArchived ? "Unarchive" : "Archive"}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleClickDelete(item.id)}
                    className="text-red-i"
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(GoalTable);

import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export function getToken() {
  const token = Cookies.get("tokens");
  return token;
}

export function setToken(token) {
  Cookies.set("tokens", token, { expires: 7 });
}

export function isAdmin() {
  const token = getToken();
  if (token) {
    return jwt_decode(token).isAdmin;
  }
  return false;
}

export function getEmail() {
  const token = getToken();
  if (token) {
    return jwt_decode(token).email;
  }
  return null;
}

export function removeToken() {
  Cookies.remove("tokens");
}

import { capitalizeFirstLetter } from "../utils";

export const Goal = {
  Type: {
    TIME: "TIME",
    MONEY: "MONEY",
    PARTICIPANTS: "PARTICIPANTS",
  },
  Status: {
    COMPLETE: "complete",
    ALMOST_DONE: "almost_done",
    IN_PROGRESS: "in_progress",
  },
  Text: {
    TIME: ["", "should spend less than", "hours in meetings per"],
    MONEY: ["meetings should cost less than"],
    PARTICIPANTS: ["Meetings", "is in should have less than", "people in them"],
  },
  Placeholder: {
    TIME: ["Select a dept or person", "Select hours", "Select duration"],
    MONEY: ["Select a dept", "Select cost"],
    PARTICIPANTS: ["Select a dept or person", "Select number"],
  },
  Duration: {
    WEEK: "week",
    MONTH: "month",
  },
};

export const DurationOptions = [
  { value: Goal.Duration.WEEK, label: capitalizeFirstLetter(Goal.Duration.WEEK) },
  { value: Goal.Duration.MONTH, label: capitalizeFirstLetter(Goal.Duration.MONTH) },
];

import React, { memo } from "react";
import PieChart from "../charts/PieChart";
import { MetricsType, Unit } from "../../constants";

const TimePieChart = ({
  className,
  loading,
  chartData1,
  chartData2,
  metricsType,
}) => {
  const unit = () => {
    return metricsType === MetricsType.COST ? Unit.DOLLAR : Unit.HR;
  };

  return (
    <div className={`d-flex ${className ? className : ""}`}>
      <PieChart
        loading={loading}
        title="One Time vs. Recurring"
        chartData={chartData1}
        unit={unit()}
      />
      <PieChart
        loading={loading}
        title="Internal vs External"
        chartData={chartData2}
        unit={unit()}
      />
    </div>
  );
};

export default memo(TimePieChart);

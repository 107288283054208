import axios from "axios";
import { appConfig } from "../constants";
import { getHeaders } from "../utils";

export async function fetchSettings() {
  try {
    const url = `${appConfig.apiBaseUrl}/settings`;
    const headers = getHeaders();
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (err) {
    console.log(err);
  }
  return null;
}

export async function saveSettings(params) {
  try {
    const url = `${appConfig.apiBaseUrl}/settings`;
    const headers = getHeaders();
    const res = await axios.put(url, params, { headers });
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function updateSeats(params) {
  try {
    const url = `${appConfig.apiBaseUrl}/settings/update-seats`;
    const headers = getHeaders();
    const res = await axios.post(url, params, { headers });
    return res.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

import React, { memo } from "react";

const TimesInMettings = ({ totalTime, avgTime }) => {
  return (
    <div className="d-flex w-100 times-in-meetings my-8">
      <div className="left">
        <div className="title">Avg Time in Meeting</div>
        <div className="value text-blue-gradient">{avgTime} min</div>
      </div>
      <div className="right">
        <div className="title">Total Time in Meeting</div>
        <div className="value text-blue-gradient">{totalTime} hr</div>
      </div>
    </div>
  );
};

export default memo(TimesInMettings);

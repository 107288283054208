export const appConfig = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  google: {
    clientId: process.env.REACT_APP_CLIENT_ID,
  },
};

export const Colors = {
  PRIMARY: "#216df4",
  WHITE: "#FFFFFF",
  BG: {
    PURPLE: "#7047EB",
    GREEN: "#2DCA72",
    PINK: "#F75FDE",
    BLUE: "#00ACFF",
    YELLOW: "#FFC233",
    ORANGE: "#FF7D52",
    RED: "#F53D6B",
  },
  Status: {
    GREEN: "#2DCA72",
    RED: "#F53D6B",
    YELLOW: "#FFC233",
    GREY: "#D1D1DB",
  },
  Gradient: {
    Green: {
      START: "#019843",
      END: "#2DCA72",
    },
    Pink: {
      START: "#c728ad",
      END: "#f75fde",
    },
    Purple: {
      START: "#7b57e5",
      END: "#7047eb",
    },
    Blue: {
      START: "#216df4",
      END: "#1815b7",
    },
    Orange: {
      START: "#f99472",
      END: "#ff7d52",
    },
    Yellow: {
      START: "#F2D183",
      END: "#EFB327",
    },
    Red: {
      START: "#F53D6B",
      END: "#D51948",
    },
    Grey: {
      START: "#D1D1DB",
      END: "#D1D1DB",
    },
  },
  Text: {
    DEFAULT: "#121217",
    DISABLED: "#a9a9bc",
    SECONDARY: "#6C6C89",
    BLUE: "#1E61DA",
    GREEN: "#019843",
    RED: "#D51948",
    YELLOW: "#EFB327",
    Accent: {
      BLUE: "#0075AD",
      RED: "#D50B3E",
      GRAY: "#3F3F50",
    },
  },
  Icon: {
    DEFAULT: "#8A8AA3",
  },
  Sidebar: {
    MenuItem: "#8A8AA3",
    MenuItemActive: "#216DF4",
  },
};

export const Paths = {
  DASHBOARD: "/dashboard",
  METRICS: "/metrics",
  GOALS: "/goals",
  MEETINGS: "/meetings",
  USERS: "/users",
  MY_PROFILE: "/my-profile",
  SETTINGS: "/settings",
};

export const HEADER = {
  Title: {
    Dashboard: "Dashboard",
    Metrics: "Metrics",
    Goals: "Goals",
    Meetings: "Meetings",
    Users: "Users",
    MyProfile: "My Profile",
    Settings: "Settings",
  },
};

export const MetricsType = {
  COST: "cost",
  TIME: "time",
};

export const Unit = {
  DOLLAR: "$",
  HR: "hr",
  HRS: "hrs",
  HOURS: "hours",
};

export const FilterOption = {
  All: "all",
  EVERYONE: "everyone",
  USER: "user",
  DEPARTMENT: "department",
};

export const DateRange = {
  THIS_WEEK: "this-week",
  THIS_MONTH: "this-month",
  LAST_WEEK: "last-week",
  LAST_MONTH: "last-month",
  LAST_QUARTR: "last-quarter",
  LAST_6_MONTHS: "last-6-months",
};

export const Currency = {
  DEFAULT: "$",
};

export const Timezone = {
  DEFAULT: "America/Phoenix",
};

export const UserRole = {
  ADMIN: "Admin",
  USER: "User",
};

export const SettingTabs = {
  TEAM: "team",
  BILLING: "billing",
  WEBHOOK: "webhook",
};

export const CurrencyOptions = [
  { label: "USD", value: "$" },
  { label: "EUR", value: "€" },
  { label: "GBP", value: "£" },
];

export const OnboardingFlow = {
  Title: [
    "What is your teams average hourly rate?",
    "Add your company's departments",
    "Select your department",
    "Select your timezone",
    "Finally, invite your team",
  ],
  Subtitle: [
    "This could also be your called your bill rate or blended rate.",
    "Add all departments to analyze meeting data across teams",
    "",
    "You can always chage this later",
    "This is some text if needed",
  ],
  Steps: {
    COMPENSATION: 0,
    DEPARTMENT_LIST: 1,
    DEPARTMENT: 2,
    TIMEZONE: 3,
    INVITE_TEAM: 4,
  },
};

export const INVITE_LIST_STORAGE_KEY = "inviteUsersList";

export const Subscription = {
  Price: {
    DEFAULT: 3,
  },
};

import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import currencyJS from "currency.js";
import { getHeaders } from "../../utils";

import {
  firstLetter,
  getFormattedDate,
  getFormattedTimeRange,
  isAdmin,
} from "../../utils";

import icArrowRight from "../../assets/icons/ic_arrow-right.svg";
import MeetingDetailModal from "./MeetingDetailModal";
import { getPaginatedMeetings } from "../../services";
import { appDataSelector } from "../../redux/reducer";
import { formatCurrency } from "../../utils/common";
import { assignBrandingColorsToUsersList } from "../../utils/user";
import Avatar from "../common/avatar";
import axios from "axios";

const SortField = {
  NONE: "none",
  MEETING: "summary",
  DATE: "start",
  DURATION: "durationHours",
  PARTICIPANTS: "otherThanDeclinedCount",
  COST: "cost",
};

const SortOrder = {
  BOTH: "both",
  ASC: "asc",
  DESC: "desc",
};

const MeetingTable = () => {
  const { dateRange, compensation, currency } = useSelector(appDataSelector);

  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [meetingList, setMeetingList] = useState([]);
  const [sortField, setSortField] = useState(SortField.NONE);
  const [sortOrder, setSortOrder] = useState({
    [SortField.MEETING]: SortOrder.BOTH,
    [SortField.DATE]: SortOrder.BOTH,
    [SortField.DURATION]: SortOrder.BOTH,
    [SortField.PARTICIPANTS]: SortOrder.BOTH,
    [SortField.COST]: SortOrder.BOTH,
  });
  const [sortSetting, setSortSetting] = useState({
    field: undefined,
    order: undefined,
  });

  const [showModal, setShowModal] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [disableSyncButton, setDisableSyncButton] = useState(
    sessionStorage.getItem("calendar_synced") === "1"
  );

  const loadPaginatedMeetings = async () => {
    if (dateRange) {
      const params = {
        dateRange: dateRange.value,
        searchText,
        page,
        pageSize,
      };

      const { field, order } = sortSetting;

      if (field && order) {
        params["sortField"] = field;
        params["sortOrder"] = order;
      }

      setLoading(true);

      const res = await getPaginatedMeetings(params);
      if (res && res.success) {
        const data = res.data;
        setTotalCount(data.totalCount);
        setTotalPages(data.totalPages);
        setMeetingList(data.meetingList);
      }
      setLoading(false);
    }
  };

  const searchHandler = useCallback(
    (e) => {
      setPage(1);
      setSearchText(e.target.value);
    },
    [dateRange]
  );

  const handlePageClick = useCallback(
    (e) => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setPage(e.selected + 1);
    },
    [dateRange, searchText]
  );

  const handleMeetingClick = (meetingId) => {
    setMeetingId(meetingId);
    setShowModal(true);
  };

  const handleSort = (field) => {
    if (sortSetting.field === field) {
      let order = sortSetting.order;
      switch (order) {
        case SortOrder.ASC:
          order = SortOrder.DESC;
          break;
        case SortOrder.DESC:
          order = SortOrder.BOTH;
          break;
        case SortOrder.BOTH:
          order = SortOrder.ASC;
          break;
        default:
          break;
      }
      setSortSetting({
        field,
        order,
      });
    } else {
      setSortSetting({
        field,
        order: SortOrder.ASC,
      });
    }
  };

  useEffect(() => {
    loadPaginatedMeetings();
  }, [sortSetting]);

  const syncMeetings = async () => {
    let tempDisableButton = disableSyncButton;

    try {
      setDisableSyncButton(true);
      const headers = getHeaders();
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/meetings/sync-calendar`,
        {},
        { headers }
      );
      sessionStorage.setItem("calendar_synced", "1");
      await loadPaginatedMeetings();
    } catch (error) {
      setDisableSyncButton(tempDisableButton);
    }
  };

  useEffect(() => {
    loadPaginatedMeetings();
  }, [dateRange, searchText, page]);

  const getSortClassName = (field) => {
    if (sortSetting.field === field) {
      return sortSetting.order;
    } else {
      return "both";
    }
  };

  return (
    <div className="data-tables overflow-hidden">
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="search">
          <input
            className="inputlabel"
            placeholder="Search meetings"
            type="text"
            value={searchText}
            onChange={searchHandler}
          />
        </div>
        <button
          className="btn-primary"
          onClick={syncMeetings}
          disabled={disableSyncButton}
        >
          Sync Calendar
        </button>
      </div>
      <div className="table-responsive pagination mt-4">
        <table className="table table-row-bordered align-middle gs-0 gy-3 my-0">
          <thead>
            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
              <th
                className={`min-w-175px text-start lt-grey sortable ${getSortClassName(
                  SortField.MEETING
                )}`}
                onClick={() => handleSort(SortField.MEETING)}
              >
                Meeting
              </th>
              <th
                className={`text-start lt-grey sortable ${getSortClassName(
                  SortField.DATE
                )}`}
                onClick={() => handleSort(SortField.DATE)}
              >
                Date
              </th>
              <th className="text-start lt-grey">Time</th>
              <th
                className={`text-start lt-grey sortable ${getSortClassName(
                  SortField.DURATION
                )}`}
                onClick={() => handleSort(SortField.DURATION)}
              >
                Duration
              </th>
              <th
                className={`text-start lt-grey sortable ${getSortClassName(
                  SortField.PARTICIPANTS
                )}`}
                onClick={() => handleSort(SortField.PARTICIPANTS)}
              >
                Participants
              </th>
              {isAdmin() && (
                <th
                  className={`text-start lt-grey sortable ${getSortClassName(
                    SortField.COST
                  )}`}
                  onClick={() => handleSort(SortField.COST)}
                >
                  Cost
                </th>
              )}
              <th className="text-start lt-grey"></th>
            </tr>
          </thead>
          <tbody>
            {meetingList.length > 0 ? (
              meetingList.map((item, index) => (
                <tr
                  key={index}
                  className="tablerow"
                  onClick={() => handleMeetingClick(item.meetingId)}
                >
                  <td className="title">{item.summary}</td>
                  <td>{getFormattedDate(item.start)}</td>
                  <td>{getFormattedTimeRange(item.start, item.end)}</td>
                  <td>
                    {item.durationHours}{" "}
                    {item.durationHours <= 1 ? "hr" : "hrs"}
                  </td>
                  <td>
                    <div className="image-grp">
                      {assignBrandingColorsToUsersList(item.attendees)
                        .slice(0, 4)
                        .map((user, i) => (
                          <Avatar
                            color={user.brandingColor}
                            userName={user?.name || user?.email}
                            size={32}
                          />
                        ))}
                      {item.attendees.length > 4 && (
                        <div className="avatar bg-m align-center">
                          <span>+{item.attendees.length - 4}</span>
                        </div>
                      )}
                    </div>
                  </td>
                  {isAdmin() && (
                    <td className="cost">
                      {currency}
                      {formatCurrency(item.cost)}
                    </td>
                  )}
                  <td className="text-end">
                    <img src={icArrowRight} />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tablerow">
                <td colSpan="6">
                  <p className="py-2 fs-5 text-cl-grey fw-bold text-center">
                    No Data Available
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {meetingList?.length > 0 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageCount={totalPages}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className={"pagination"}
          />
        ) : (
          ""
        )}
      </div>
      <MeetingDetailModal
        open={showModal}
        handleClose={setShowModal}
        meetingId={meetingId}
        compensation={compensation}
      />
    </div>
  );
};

export default MeetingTable;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import SelectType from "./SelectType";

import { CircleDollarIcon, ClockIcon } from "../icons";

import { Colors, MetricsType } from "../../constants";

import icCalendar from "../../assets/icons/ic_calendar.svg";
import icUser from "../../assets/icons/ic_user.svg";
import { Paths } from "../../constants/app-config";
import {
  dateFilterOptions,
  getDepartmentOptions,
  getFilterOptions,
  isActivePath,
  isAdmin,
} from "../../utils";

import {
  appDataSelector,
  updateDateRange,
  updateDepartment,
  updateMeetingType,
  updateMetricsType,
} from "../../redux/reducer";
import { getDepartments, getUsers } from "../../redux/actions";

const Header = React.memo(({ heading, handleAddGoal }) => {
  const dispatch = useDispatch();

  const {
    users,
    departments,
    metricsType,
    meetingType,
    dateRange,
    department,
  } = useSelector(appDataSelector);

  const [meetingTypes, setMeetingTypes] = useState([]);
  const [dateRanges, setDateRanges] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const handleChangeMetricsType = (value) => {
    dispatch(updateMetricsType(value));
  };

  const handleChangeMeetingType = (value) => {
    dispatch(updateMeetingType(value));
  };

  const handleChangeDateRange = (value) => {
    dispatch(updateDateRange(value));
  };

  const handleChangeDepartment = (value) => {
    dispatch(updateDepartment(value));
  };

  useEffect(() => {
    const params = {
      searchText: "",
      page: 1,
      pageSize: 1000,
    };
    dispatch(getUsers(params));
    dispatch(getDepartments());

    const dateRangeOptions = dateFilterOptions();
    setDateRanges(dateRangeOptions);
  }, []);

  useEffect(() => {
    const tempMeetingTypes = getFilterOptions(departments, users);
    setMeetingTypes(tempMeetingTypes);
    handleChangeMeetingType(tempMeetingTypes[0]);

    const tempDepartmentOptions = getDepartmentOptions(departments);
    setDepartmentOptions(tempDepartmentOptions);
    handleChangeDepartment(tempDepartmentOptions[0]);
  }, [users, departments]);

  const isCost = () => {
    return metricsType === MetricsType.COST;
  };

  return (
    <div className="d-flex justify-content-between align-items-center header border-bottom">
      <div className="d-flex align-items-center">
        <h2 className="fs-24 fw-bold m-0">{heading}</h2>
        {isActivePath(Paths.METRICS) && (
          <div className="d-flex ms-6 btn-switch">
            {isAdmin() && (
              <div
                className={`d-flex align-items-center px-6 button ${
                  isCost() ? "active" : "inactive"
                }`}
                onClick={() => handleChangeMetricsType(MetricsType.COST)}
              >
                <CircleDollarIcon
                  color={isCost() ? Colors.WHITE : Colors.Icon.DEFAULT}
                />
                <span>Cost</span>
              </div>
            )}
            <div
              className={`d-flex align-items-center px-6 button ${
                isCost() ? "inactive" : "active"
              }`}
              onClick={() => handleChangeMetricsType(MetricsType.TIME)}
            >
              <ClockIcon
                color={!isCost() ? Colors.WHITE : Colors.Icon.DEFAULT}
              />
              <span>Time</span>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end gap-2">
        {isAdmin() &&
          (isActivePath(Paths.METRICS) || isActivePath(Paths.GOALS)) && (
            <div className="d-flex button">
              <img src={icUser} className="w-20p" />
              <SelectType
                className="min-w-140"
                value={meetingType}
                isSearchable={false}
                options={meetingTypes}
                handleChange={(e) => handleChangeMeetingType(e)}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
        {!isActivePath(Paths.USERS) && !isActivePath(Paths.SETTINGS) && (
          <div className="d-flex button">
            <img src={icCalendar} className="w-20p" />
            <SelectType
              className="min-w-234"
              value={dateRange}
              isSearchable={false}
              options={dateRanges}
              handleChange={(e) => handleChangeDateRange(e)}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        )}
        {isActivePath(Paths.USERS) && (
          <div className="d-flex button">
            <img src={icCalendar} className="w-20p" />
            <SelectType
              className="min-w-156"
              value={department}
              isSearchable={false}
              options={departmentOptions}
              handleChange={(e) => handleChangeDepartment(e)}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        )}
        {isActivePath(Paths.GOALS) && (
          <button
            type="button"
            className="btn-primary"
            onClick={() => {
              if (handleAddGoal) {
                handleAddGoal();
              }
            }}
          >
            Add Goal
          </button>
        )}
      </div>
    </div>
  );
});

export default Header;

import { Colors } from "./app-config";

export const Chart = {
  Type: {
    BAR: "bar",
    DOUGHNUT: "doughnut",
  },
  Tooltip: {
    callbacks: {
      label: function (context) {
        return context.formattedValue;
      },
    },
  },
  Bar: {
    Y_TICKS_CALLBACK: function (value) {
      return `${value} hr`;
    },
    Background: {
      LinearGradient: function (context) {
        const { chart, datasetIndex, index } = context;
        const ds = chart.data.datasets[datasetIndex];
        const value = ds.data[index];

        const y = chart.scales.y.getPixelForValue(value);
        const meta = chart.getDatasetMeta(datasetIndex);
        const data = meta.data[index];
        if (data) {
          const { x, base } = data;
          if (x) {
            const ctx = chart.ctx;
            const gradient = ctx.createLinearGradient(x, y, x, base);
            gradient.addColorStop(0, Colors.Gradient.Blue.START);
            gradient.addColorStop(1, Colors.Gradient.Blue.END);
            return gradient;
          }
        } else {
          return Colors.PRIMARY;
        }
      },
    },
    DataLabels: {
      Color: function (context) {
        const { dataIndex, dataset } = context;
        const value = dataset.data[dataIndex];
        if (value === 0) return Colors.Text.DISABLED;
        return Colors.Text.BLUE;
      },
      Formatter: function (value, context) {
        if (value === 0) return 0;
        return `${value} hr`;
      },
    },
    DATA: {
      datasets: [
        {
          backgroundColor: Colors.PRIMARY,
          borderWidth: 1,
          barThickness: 6,
          borderRadius: 4,
          borderDash: [5, 5], // Define the pattern of dashes and gaps for the bars
          barThickness: 27,
          datalabels: {
            color: Colors.Text.DEFAULT,
            anchor: "end",
            align: "top",
            offset: 5,
            labels: {
              title: {
                font: { size: "16px", weight: "700" },
              },
            },
          },
        },
      ],
    },
    OPTIONS: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          grid: { display: false },
          border: { display: false },
          ticks: {
            // count: 6,
            color: Colors.Text.SECONDARY,
            font: { size: "12px", weight: "400" },
          },
        },
        x: {
          barPercentage: 0.1, // Adjust bar width, where 1.0 is the default (full width)
          grid: { display: false },
          border: { display: false },
          ticks: {
            color: Colors.Text.SECONDARY,
            font: { size: "12px", weight: "400" },
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: { display: false },
      },
    },
  },
  Pie: {
    DATA: {
      datasets: [{ backgroundColor: Colors.PRIMARY }],
    },
    OPTIONS: {
      rotation: 180,
      responsive: true,
      maintainAspectRatio: false,
      cutout: "70%",
      plugins: {
        tooltip: { enabled: false },
        datalabels: { display: false },
        legend: { display: false },
      },
    },
  },
};

import { Colors } from "../../constants";

export const ClockIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99999 6.66683V10.0002L11.6667 11.6668M16.0417 10.0002C16.0417 13.3369 13.3367 16.0418 9.99999 16.0418C6.66327 16.0418 3.95833 13.3369 3.95833 10.0002C3.95833 6.66344 6.66327 3.9585 9.99999 3.9585C13.3367 3.9585 16.0417 6.66344 16.0417 10.0002Z"
      stroke={color ?? Colors.Icon.DEFAULT}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

import React, { memo } from "react";
import BarChart from "../charts/BarChart";
import { MetricsType, Paths, Unit } from "../../constants";
import { formattedLabel, isActivePath } from "../../utils";

const TotalChart = ({
  className,
  loading,
  chartData,
  totalTime,
  metricsType,
}) => {
  return (
    <div className={className ? className : ""}>
      {!isActivePath(Paths.DASHBOARD) && (
        <div className="d-flex align-items-center justify-content-between pb-8">
          <h3 className="fs-24 tc-body fw-700">
            {metricsType === MetricsType.COST ? "Total Cost" : "Total Time"}
          </h3>
          <h3 className="fs-32 text-blue-gradient fw-700">
            {formattedLabel(
              totalTime,
              metricsType === MetricsType.COST ? Unit.DOLLAR : Unit.HOURS
            )}
          </h3>
        </div>
      )}
      <BarChart
        loading={loading}
        chartData={chartData}
        height={400}
        unit={metricsType === MetricsType.COST ? Unit.DOLLAR : Unit.HR}
      />
    </div>
  );
};

export default memo(TotalChart);

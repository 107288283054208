import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { checkout } from "../../services";
import { getDecodedUserData } from "../../services/auth.service";
import { Check, CircleCheckBig, X } from "lucide-react";

const freePlanFeatures = [
  "up to 3 users",
  "This is feature",
  "This is feature",
  "This is feature",
  "This is feature",
];
const teamPlanFeatures = [
  "This is feature",
  "This is feature",
  "This is feature",
  "This is feature",
  "This is feature",
];

export default function UpgradeModal({ open, onClose: onCloseProp, quantity }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckout = useCallback(async () => {
    try {
      setIsLoading(true);
      const userData = await getDecodedUserData();
      // if (seats > 3) {
      const params = {
        lookup_keys: ["calendyze_per_seat_test"],
        customer_email: userData.email,
        // customer_email: "andrew@calendyze.com",
        // quantity: 3,
        req_from_page: "",
      };

      if (quantity) params["quantity"] = quantity;

      const res = await checkout(params);
      if (res.error) {
        toast.error(res.error.response.data.message);
      } else {
        window.location.href = res.url;
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [quantity]);

  const onClose = onCloseProp || (() => {});

  return (
    <Dialog className="tw-relative tw-z-1000" open={open} onClose={onClose}>
      <DialogBackdrop
        transition
        className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity tw-data-[closed]:opacity-0 tw-data-[enter]:duration-300 tw-data-[leave]:duration-200 tw-data-[enter]:ease-out tw-data-[leave]:ease-in"
      />

      <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
        <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4 tw-text-center tw-sm:items-center tw-sm:p-0">
          <DialogPanel
            transition
            className="tw-relative tw-w-full tw-max-w-screen-md tw-transform tw-overflow-hidden tw-rounded-3xl tw-bg-white tw-text-left tw-shadow-xl tw-transition-all tw-data-[closed]:translate-y-4 tw-data-[closed]:opacity-0 tw-data-[enter]:duration-300 tw-data-[leave]:duration-200 tw-data-[enter]:ease-out tw-data-[leave]:ease-in tw-sm:my-8 tw-sm:w-full tw-sm:max-w-lg tw-data-[closed]:sm:translate-y-0 tw-data-[closed]:sm:scale-95"
          >
            <div className="tw-bg-white tw-p-12 tw-flex tw-flex-col md:tw-flex-row tw-gap-8">
              <div>
                {onCloseProp && (
                  <div className="tw-mb-10">
                    <button
                      className="tw-p-3 tw-bg-gray-200 hover:tw-bg-gray-100 tw-rounded-xl"
                      onClick={onClose}
                    >
                      <X className="tw-text-gray-400" />
                    </button>
                  </div>
                )}
                <h3 className="tw-text-6xl tw-font-bold tw-mb-5">
                  Save{" "}
                  <span className="tw-text-transparent tw-bg-gradient-to-b tw-from-palette-blue tw-from-32% tw-to-palette-primary tw-to-100% tw-bg-clip-text">
                    more
                  </span>{" "}
                  time & money
                </h3>
                <p className="tw-text-gray-500 tw-text-lg tw-max-w-72 tw-mb-12">
                  Add more than 3 users and save even more time & money
                </p>
                <div className="tw-p-10 tw-border tw-rounded-xl">
                  <p className="tw-rounded-2xl tw-bg-gray-100 tw-px-4 tw-py-2 tw-inline tw-font-bold">
                    Current Plan
                  </p>
                  <h2 className="tw-font-bold tw-text-4xl tw-mt-8">Free</h2>
                  <p className="tw-text-gray-500 tw-mt-5 tw-mb-12">
                    $0 / month
                  </p>
                  <ul className="tw-list-none tw-pl-0">
                    {freePlanFeatures.map((item, index) => (
                      <li key={index}>
                        <Check className="tw-inline tw-text-palette-primary tw-mr-3 tw-mb-3" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gradient-to-b tw-from-palette-primary tw-from-32% tw-to-palette-blue tw-to-100% tw-grow tw-srink-0 tw-basis-[400px] tw-rounded-3xl tw-p-6 tw-text-white">
                <h2 className="tw-text-center tw-text-4xl tw-text-inherit tw-mb-6">
                  Team Plan
                </h2>
                <div className="tw-flex tw-justify-center tw-items-center tw-opacity-65">
                  <span className="tw-text-6xl tw-font-bold">$3</span>
                  <div className="tw-flex tw-flex-col tw-ml-2">
                    <span>/ user</span>
                    <span>/ month</span>
                  </div>
                </div>
                <button
                  type="button"
                  className="tw-max-w-64 tw-w-full tw-text-xl tw-text-white tw-bg-palette-primary hover:tw-bg-blue-500 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-4 tw-my-16 focus:tw-outline-none"
                  onClick={handleCheckout}
                  disabled={isLoading}
                >
                  Subscribe
                </button>
                <ul className="tw-list-none tw-pl-4 tw-w-full">
                  {teamPlanFeatures.map((item, index) => (
                    <li key={index} className="tw-mb-3">
                      <CircleCheckBig
                        size={22}
                        className="tw-inline tw-text-blue-300 tw-mr-3 text-base"
                      />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

import Cookies from "js-cookie";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import AddEditWebhookUrlModal from "./addEditWebhookUrlModal";
import { useDispatch, useSelector } from "react-redux";
import { adminDataSelector } from "../../redux/reducer/adminSlice";
import { adminWebhookListData } from "../../redux/actions/adminAction";
import axios from "axios";
import { appConfig } from "../../constants";
import { toast } from "react-toastify";

export default function WebhookSettings({ }) {

  const token = useMemo(() => Cookies.get("tokens"), []);

  const dispatch = useDispatch();
  const { webhookList, mode } = useSelector(adminDataSelector);

  const [selWebhook, setSelWebhook] = useState();
  const [showModal, setShowModal] = useState(false);

  const [pageCount, setPageCount] = useState(1);
  const [paginationData, setPaginationData] = useState();

  const loadAdminWebhookList = useCallback(() => {
    dispatch(adminWebhookListData("", token, mode, pageCount));
  }, [dispatch, token, mode]);

  const handleAddWebhook = () => {
    setSelWebhook(null);
    setShowModal(true);
  }

  const handleEditWebhook = (value) => {
    setSelWebhook(value);
    setShowModal(true);
  }

  const handleDeleteWebhook = (value) => {
    if (value._id) {
      axios.delete(`${appConfig.apiBaseUrl}/webhooks/${value._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if(res.data.success) {
          toast.success(res.data.message);

          loadAdminWebhookList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
    }
  }

  const handlePageClick = useCallback((event) => {
    setPageCount(event.selected + 1);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    loadAdminWebhookList();
  }, [dispatch, token, mode, pageCount])

  return (
    <>
      {showModal && <AddEditWebhookUrlModal
        reload={loadAdminWebhookList}
        handleClose={() => setShowModal(false)}
        webhook={selWebhook}
      />}
      <div className="mt-4 p-4 bg-white  rounded-3">
        <div className="d-flex align-items-center justify-content-between ">
          <h5 className="fw-bold text-cl-grey m-0">Webhook Settings</h5>
          <div className="d-flex justify-content-end">
            <button
              className="btn primary-button tc-white fw-bold d-flex gap-3 align-items-center"
              type="button"
              onClick={handleAddWebhook}
            >
              Add
            </button>
          </div>
        </div>

        <div className="table-responsive pagination">
          <table className="table table-row-dashed align-middle gs-0 gy-3 my-0 ">
            <thead>
              <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                <th className=" min-w-175px text-start lt-grey">Webhook url</th>
                <th className=" text-start lt-grey">Event type</th>
                <th className=" text-start lt-grey">Platform</th>
                <th className=" text-start lt-grey">Action</th>
              </tr>
            </thead>
            <tbody>
              {webhookList.length > 0 && webhookList?.map((item) => (
                <tr>
                  <td>
                    <h5 className="fs-14 fw-bold m-0 lt-grey text-capitalize">
                      {item.url}
                    </h5>
                  </td>
                  <td>
                    <h5 className="fs-14 fw-bold m-0 lt-grey text-capitalize">
                      {item.eventType}
                    </h5>
                  </td>
                  <td>
                    <h5 className="fs-14 fw-bold m-0 lt-grey">
                      {item.platform}
                    </h5>
                  </td>
                  <td>
                    <div className="d-flex">
                      <button
                        className="btn primary-button tc-white fw-bold d-flex gap-3 align-items-center"
                        onClick={() => handleEditWebhook(item)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn danger-button tc-white fw-bold d-flex gap-3 align-items-center mx-2"
                        onClick={() => handleDeleteWebhook(item)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              {webhookList.length == 0 && <div className="w-100"></div>}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageCount={paginationData?.total}
          previousLabel="<"
          renderOnZeroPageCount={null}
          className={"pagination"}
        />
      </div>
    </>
  );
}

import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useEffect, useState } from "react";
import Input from "../../component/common/input";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

function PaymentSelect() {
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);
  const [error, setError] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [seatsQuantity, setSeatsQuantity] = useState(0);

  const token = Cookies.get("tokens");
  const tokenDecoded = () => {
    if (token) {
      return jwt_decode(token);
    }
  };
  // const paymentSelected = async () => {
  //   await axios
  //     .get(`${process.env.REACT_APP_API_BASE_URL}/payment-selected`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((res) => {
  //       Cookies.set("tokens", res.data.data.updatedToken, { expires: 180 });
  //       navigate("/send-invite");
  //     })
  //     .catch((err) => {
  //       toast.error("Token Validation failed");
  //     });
  // };
  async function handleSubmit() {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/stripe/checkout`,
        {
          lookup_keys: ["calendyze_per_seat_test"],
          customer_email: tokenDecoded().email,
          quantity: seatsQuantity,
          req_from_page: "paymentSelect",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const url = resp.data.url;
        window.location.href = url;
      })
      .catch((err) => toast.error(err.response.data.message));
  }
  useEffect(() => {
    if (tokenDecoded()?.payment_selected) {
      navigate("/send-invite");
    }
  }, [tokenDecoded()]);

  return (
    <>
      <div className="vh-min-100 flex-column d-flex pt-15 px-4 justify-content-center align-items-center half-blue">
        {popUp && (
          <>
            <div className="pop-wrapper">
              <div className="inner-wrapper">
                <div className="head p-24 border-bottom">
                  <h3 className="fw-bold">Add users</h3>
                  <span
                    className="cross-btn"
                    onClick={() => {
                      setPopUp(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <path
                        d="M13 1.19482L1 13.1948M1 1.19482L13 13.1948"
                        stroke="#3F4254"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
                <div className="pop-content p-24 py-3">
                  <label className="mb-2 fw-bold">
                    How many users would you like to add?
                  </label>
                  <Input
                    type="text"
                    handleSelectChange={(e) => {
                      const inputValue = e.target.value;

                      if (inputValue && inputValue < 1) {
                        setErrorMessage("Please enter a number greater than 0");
                        setError(true);
                      } else if (/^\d+$/.test(inputValue)) {
                        setSeatsQuantity(inputValue);
                        setError(false);
                      } else {
                        setErrorMessage("Only Numbers are allowed");
                        setError(true);
                      }
                    }}
                    className="flex-1"
                  />
                  {error && (
                    <span className="error-message">{errorMessage}</span>
                  )}
                </div>
                <div className="footer-pop p-24 border-top d-flex justify-content-center">
                  <button
                    className="btn grey-button fw-bold me-3"
                    type="button"
                    onClick={() => {
                      setPopUp(false);
                    }}
                  >
                    Back
                  </button>
                  <button
                    className="btn primary-button tc-white fw-bold"
                    type="button"
                    disabled={!seatsQuantity || error}
                    onClick={() => handleSubmit()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="position-relative z-index-3 flex-column d-flex w-100 justify-content-center align-items-center">
          <h2 className="fs-32 fw-bold mb-12">Choose your plan</h2>
          <div className="col-xxl-7 col-xl-8 col-lg-9 row d-flex">
            <div className="col-6 plan-box">
              <div className="p-6 overflow-hidden rounded-3 d-flex flex-column justify-content-between bg-white">
                <div>
                  <h2 className="fs-32 fw-bold mb-0">Basic</h2>
                  <p className="text-muted font-12 my-2 fw-bold">
                    Simple calendar analysis for free
                  </p>
                  <h3 className="fs-24 fw-bold mb-0">FREE</h3>
                  <div className="plan-detail">
                    <ul>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Dashboard overview</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Detailed meeting costs</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>User profile dashboard</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Calendar API</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Customizable currency</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="mt-4 btn primary-button tc-white fw-bold w-max-content mx-auto"
                  type="button"
                  onClick={() => {
                    navigate("/send-invite");
                  }}
                >
                  Select
                </button>
              </div>
            </div>
            <div className="col-6 plan-box tc-white">
              <div className="p-6 overflow-hidden rounded-3 d-flex flex-column justify-content-between">
                <div>
                  <h2 className="fs-32 fw-bold mb-0">Pro</h2>
                  <p className="tc-white font-12 my-2 fw-bold">
                    Real time costs in-calendar with more data and metrics to
                    help your team thrive
                  </p>
                  <h3 className="fs-24 fw-bold mb-0">$3 user / month</h3>
                  <p className="font-12 fw-semibold mt-3">
                    You'll enjoy all the benefits of our Basic plan along with
                    additional premium features.
                  </p>
                  <div className="plan-detail">
                    <ul>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>In calendar costs plugin</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Advanced company metrics</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Advanced user metrics</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Meeting data from the last six months</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          viewBox="0 0 15 16"
                          fill="none"
                        >
                          <path
                            d="M14.2585 7.38674V8.00007C14.2577 9.43769 13.7922 10.8365 12.9314 11.988C12.0706 13.1394 10.8607 13.9817 9.48208 14.3893C8.10346 14.797 6.63001 14.748 5.28148 14.2498C3.93296 13.7516 2.78161 12.8308 1.99914 11.6248C1.21668 10.4188 0.845032 8.99211 0.939622 7.55761C1.03421 6.12312 1.58998 4.75762 2.52402 3.66479C3.45807 2.57195 4.72036 1.81033 6.12263 1.4935C7.5249 1.17668 8.99202 1.32163 10.3052 1.90674M14.2585 2.66674L7.59184 9.34007L5.59184 7.34007"
                            stroke="#6C96FE"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Dashboard overview</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="mt-4 btn white-button fw-bold w-max-content mx-auto"
                  type="button"
                  onClick={() => {
                    setPopUp(true);
                  }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PaymentSelect;

export const EventTypes = [
  { value: "user-created", label: "User Created" },
  { value: "other", label: "Other" }
]

export const Platforms = [
  { value: "salesforce", label: "Salesforce" },
  { value: "zapier", label: "Zapier" },
  { value: "other", label: "Other" }
]

export const PlatformFields = {
  "salesforce": [
    { field: "clientId", label: "Client Id" },
    { field: "clientSecret", label: "Client Secret" },
    { field: "username", label: "Username" },
    { field: "password", label: "Password" },
    { field: "securityToken", label: "Security Token" }
  ]
}
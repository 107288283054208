import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useEffect, useRef } from "react";

const ChartComponent = ({
  chartType,
  data,
  options,
  width = 200,
  height = 200,
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null); // Maintain a reference to the chart instance

  useEffect(() => {
    if (
      chartType &&
      typeof data !== "undefined" &&
      typeof options !== "undefined" &&
      data &&
      options
    ) {
      const ctx = chartRef.current.getContext("2d");

      if (chartInstance.current) {
        chartInstance.current.destroy(); // Destroy the previous chart instance
      }

      chartInstance.current = new Chart(ctx, {
        type: chartType,
        plugins: [ChartDataLabels],
        data: data,
        options: options,
      });
    }
  }, [chartType, data, options]);

  return (
    <div className={`${chartType}-chart-container`}>
      <canvas
        ref={chartRef}
        id={`my${chartType.charAt(0).toUpperCase() + chartType.slice(1)}Chart`}
        width={width}
        height={height}
      ></canvas>
    </div>
  );
};

export default ChartComponent;

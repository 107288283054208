import React, { useMemo, useCallback } from "react";
import Select from "react-select";

const SelectType = React.memo(
  ({
    handleChange,
    type,
    className,
    options,
    value,
    defaultValue,
    isSearchable = true,
    components,
  }) => {
    const handleSelectChange = useCallback(
      (selectedOption) => {
        if (handleChange) {
          handleChange(selectedOption);
        }
      },
      [handleChange]
    );

    return (
      <Select
        placeholder={defaultValue}
        defaultValue={defaultValue}
        value={value}
        filterOption={null}
        isMulti={type !== undefined}
        options={options}
        className={`basic-select bg-blue-lt text-start ${
          className !== undefined ? className : ""
        }`}
        classNamePrefix="select"
        onChange={handleSelectChange}
        isSearchable={isSearchable}
        components={components}
        menuPosition="fixed"
        theme={(theme) => ({
          ...theme,
          colors: { ...theme.colors, primary: "#216df4", primary25: "#f7f7f8" },
        })}
        styles={{
          menu: (baseStyles) => ({
            ...baseStyles,
            padding: "8px",
            borderRadius: "8px",
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            padding: "0px",
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            borderRadius: "8px",
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "none !important",
            outline: "none !important",
            boxShadow: "none !important",
          }),
        }}
      />
    );
  }
);

export default SelectType;

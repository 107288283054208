import React, { memo, useEffect, useState } from "react";
import Loader from "../common/loader";
import ChartComponent from "../charts/ChartComponent";
import { Chart, Colors, MetricsType } from "../../constants";
import { CircleDollarIcon, ClockIcon } from "../icons";

const DepartmentsChart = ({
  loading,
  departments,
  metricsType = MetricsType.TIME,
  currency,
  isAdmin = false,
}) => {
  const [data, setData] = useState();
  const [options, setOptions] = useState();

  const getStopPos = (values, index) => {
    let sum = 0;
    let st = 0;
    let ed = 0;
    for (const v of values) {
      sum += v;
    }

    for (let i = 0; i < index; i++) {
      st = st + values[i];
    }

    ed = st + values[index];

    st = st / sum;
    ed = ed / sum;

    return { start: st, end: ed };
  };

  const startColor = (index) => {
    const colors = Object.values(Colors.Gradient);

    // if (index === departments.length - 1) {
    //   return Colors.Gradient.Grey.START;
    // }

    return colors[index].START;
  };

  const stopColor = (index) => {
    const colors = Object.values(Colors.Gradient);

    // if (index === departments.length - 1) {
    //   return Colors.Gradient.Grey.END;
    // }

    return colors[index].END;
  };

  const backgroundColor = (context) => {
    const { chart, datasetIndex, index } = context;
    const ds = chart.data.datasets[datasetIndex];
    const meta = chart.getDatasetMeta(datasetIndex);
    const data = meta.data[index];
    const { x, y } = data;
    if (x && y && typeof x !== "undefined" && typeof y !== "undefined") {
      const stopPos = getStopPos(ds.data, index);
      const ctx = chart.ctx;
      const gradient = ctx.createConicGradient(Math.PI / 2, x, y);
      gradient.addColorStop(stopPos.start, startColor(index % 7));
      gradient.addColorStop(stopPos.end, stopColor(index % 7));
      return gradient;
    }
  };

  useEffect(() => {
    if (departments && departments.length > 0) {
      const chartData = [];
      for (const department of departments) {
        chartData.push(department.value);
      }

      const tempData = JSON.parse(JSON.stringify(Chart.Pie.DATA));
      tempData.datasets[0].data = chartData;
      tempData.datasets[0].backgroundColor = backgroundColor;
      setData(tempData);
      const tempOptions = JSON.parse(JSON.stringify(Chart.Pie.OPTIONS));
      setOptions(tempOptions);
    }
  }, [departments]);

  return (
    <div className="departments-chart mt-4">
      {loading && <Loader className="mx-auto" />}
      {!loading && departments && departments?.length > 0 && (
        <div className="d-flex w-100">
          <div>
            <ChartComponent
              chartType={Chart.Type.DOUGHNUT}
              data={data}
              options={options}
              width={110}
              height={110}
            />
          </div>
          <div className="d-flex flex-column justify-content-evenly w-100 ps-4 gap-2">
            {departments.map((item, index) => (
              <div
                key={`department_${index}`}
                className="d-flex justify-content-between"
              >
                <div className="label d-flex align-items-center gap-2">
                  <div className={`dot bg-${index % 7}`} />
                  <span>{item.name}</span>
                </div>
                <>
                  {metricsType === MetricsType.TIME ? (
                    <div className="value d-flex align-items-center gap-2 min-w-56">
                      <ClockIcon color={Colors.Text.Accent.BLUE} />{" "}
                      {`${item.value}${item.value > 1 ? "hrs" : "hr"}`}
                    </div>
                  ) : isAdmin ? (
                    <div className="value d-flex align-items-center gap-2 min-w-56 value-cost">
                      <CircleDollarIcon color={Colors.Text.Accent.RED} />
                      {`${currency}${item.value}`}
                    </div>
                  ) : null}
                </>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(DepartmentsChart);

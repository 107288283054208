import React from "react";

const Input = React.memo(({ handleSelectChange, className, type, value }) => {
  return (
    <div className={`input-wrapper w-100 ${className || ""}`}>
      <input
        min={0}
        type={type}
        className="bg-blue-lt border-0 w-100 rounded-3"
        onChange={handleSelectChange}
        defaultValue={value}
      />
    </div>
  );
});

export default Input;

import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const SelectField = forwardRef(
  (
    {
      className,
      name,
      label,
      control,
      options,
      error,
      handleChange,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={`${className || ""}`}>
        {label && <label htmlFor={name}>{label}</label>}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange } }) => (
            <Select
              name={name}
              options={options}
              {...rest}
              onChange={(e) => {
                onChange(e.value);
                if (handleChange) {
                  handleChange(e);
                }
              }}
            />
          )}
        />
        {error && <p className="error">This is required</p>}
      </div>
    );
  }
);

export default SelectField;

import { forwardRef } from "react";

const InputField = forwardRef(({ className, name, label, error, ...rest }, ref) => {
  return (
    <div className={`input-wrapper w-100 ${className || ""}`}>
      <label htmlFor={name}>{label}</label>
      <input
        name={name}
        className="bg-blue-lt border-0 w-100 rounded-2"
        ref={ref}
        {...rest}
      />
      {error && <p className="error">This is required</p>}
    </div>
  )
})

export default InputField;
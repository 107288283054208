import axios from "axios";
import { appConfig } from "../constants";
import { getHeaders } from "../utils";

export async function fetchUsers(params) {
  try {
    const url = `${appConfig.apiBaseUrl}/users`;
    const headers = getHeaders();
    const res = await axios.get(url, { headers, params });
    // console.log("===== res.data", res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }

  return null;
}

export async function fetchUser(userId) {
  try {
    const url = `${appConfig.apiBaseUrl}/users/${userId}`;
    const headers = getHeaders();
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (err) {
    console.log(err);
  }
  return null;
}

export async function updateUser(userId, userInfo) {
  try {
    const url = `${appConfig.apiBaseUrl}/users/${userId}`;
    const headers = getHeaders();
    const res = await axios.put(url, userInfo, { headers });
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function deleteUser(userId) {
  try {
    const url = `${appConfig.apiBaseUrl}/users/${userId}`;
    const headers = getHeaders();
    const res = await axios.delete(url, { headers });
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function inviteUsers(emails) {
  try {
    const url = `${appConfig.apiBaseUrl}/users/invitations`;
    const headers = getHeaders();
    const res = await axios.post(url, { emails }, { headers });
    return res.data;
  } catch (err) {
    throw err;
  }
}

export async function reInvite(email) {
  try {
    const url = `${appConfig.apiBaseUrl}/users/re-invite`;
    const headers = getHeaders();
    const res = await axios.post(url, { email }, { headers });
    return res.data;
  } catch (err) {
    throw err;
  }
}

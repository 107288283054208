import axios from "axios";
import { appConfig } from "../constants";
import { getHeaders } from "../utils";

export async function checkout(params) {
  try {
    const url = `${appConfig.apiBaseUrl}/stripe/checkout`;
    const headers = getHeaders();
    const res = await axios.post(url, params, { headers });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    return { error };
  }
}

export async function editCard(params) {
  try {
    const url = `${appConfig.apiBaseUrl}/stripe/edit-card-details`;
    const headers = getHeaders();
    const res = await axios.post(url, params, { headers });
    if (res) {
      return res.data;
    }
  } catch (error) {
    return { error };
  }
}

import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import icBtnArrowRight from "../../assets/icons/ic_btn_arrow-right.svg";
import { Goal } from "../../constants";
import { getGoalStatus, getGoalStatusIcon, getTargetName } from "../../utils";

const GoalList = ({ goals }) => {
  const navigate = useNavigate();

  const handleShowAll = () => {
    navigate("/goals");
  };

  return (
    <div className="top-goals">
      <div className="d-flex align-items-center justify-content-between">
        <span className="fs-24 fw-700 tc-body">Goals</span>
        <div className="d-flex align-items-center gap-2">
          <button
            type="button"
            className="btn-primary"
            onClick={handleShowAll}
          >
            Add Goal
          </button>
          <img className="cursor-pointer" src={icBtnArrowRight} onClick={handleShowAll} />
        </div>
      </div>

      <div className="d-flex flex-column py-4 gap-2 goal-list">
        {goals.map((item, index) => (
          <div
            key={`item_${index}`}
            className={`d-flex align-items-center rounded-4 p-3 gap-2 mb-2 item status-${getGoalStatus(
              item.status
            )}`}
          >
            <div>
              <img src={getGoalStatusIcon(item.status)} />
            </div>
            <div className="flex-grow-1 ms-3">
              <span className="mx-2">{Goal.Text[item.type][0]}</span>
              <span className="badge">{getTargetName(item)}</span>
              <span className="mx-2">{Goal.Text[item.type][1]}</span>
              <span className="badge">
                {item.type === Goal.Type.MONEY
                  ? `$${item.targetValue}`
                  : item.targetValue}
              </span>
              {item.type !== Goal.Type.MONEY && (
                <span className="mx-2">{Goal.Text[item.type][2]}</span>
              )}
              {item.type === Goal.Type.TIME && (
                <span className="badge">{item.duration}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(GoalList);

import React, { memo, useCallback, useEffect, useState } from "react";
import Loader from "../common/loader";
import ChartComponent from "../charts/ChartComponent";
import { Chart, Colors, MetricsType } from "../../constants";
import { CircleDollarIcon, ClockIcon } from "../icons";
import { formatCurrency } from "../../utils/common";
import Avatar from "../common/avatar";

const ParticipantsChart = ({
  loading,
  participants,
  metricsType = MetricsType.TIME,
  currency,
  isAdmin = false,
}) => {
  const [data, setData] = useState();
  const [options, setOptions] = useState();

  const updateData = useCallback(async (participants) => {
    const chartData = [];
    const chartColors = [];
    const labels = [];
    for (let i = 0; i < participants.length; i++) {
      const p = participants[i];
      chartData.push(p.value);
      labels.push(p.name);
      chartColors.push(p.brandingColor);
    }

    const tempData = JSON.parse(JSON.stringify(Chart.Pie.DATA));
    tempData.datasets[0].data = chartData;
    tempData.datasets[0].backgroundColor = chartColors;
    tempData.labels = labels;
    setData(tempData);
    const tempOptions = JSON.parse(JSON.stringify(Chart.Pie.OPTIONS));
    setOptions(tempOptions);
  }, []);

  useEffect(() => {
    if (participants && participants.length > 0) {
      updateData(participants);
    }
  }, [participants, updateData]);

  return (
    <div className="participants-chart mt-4">
      {loading && <Loader className="mx-auto" />}
      {!loading && participants && participants?.length > 0 && (
        <div className="d-flex w-100">
          {isAdmin && (
            <div className="pe-4">
              <ChartComponent
                chartType={Chart.Type.DOUGHNUT}
                data={data}
                options={options}
                width={110}
                height={110}
              />
            </div>
          )}
          <div className="d-flex flex-column justify-content-evenly w-100 gap-4">
            {participants.map((item, index) => (
              <div
                key={`participant_${index}`}
                className="d-flex justify-content-between"
              >
                <div className="user-info d-flex align-items-center gap-2">
                  <Avatar userName={item.name} color={item.brandingColor} />
                  <div>
                    <div className="name">{item.name}</div>
                    <div className="department">{item.departmentName}</div>
                  </div>
                </div>
                {isAdmin && item.value !== undefined && (
                  <>
                    {metricsType === MetricsType.TIME ? (
                      <div className="value d-flex align-items-center gap-2">
                        <ClockIcon color={Colors.Text.Accent.RED} />
                        {`${item.value}${item.value > 1 ? "hrs" : "hr"}`}
                      </div>
                    ) : (
                      <div className="value d-flex align-items-center gap-2">
                        <CircleDollarIcon color={Colors.Text.Accent.RED} />
                        {`${currency}${formatCurrency(item.value)}`}
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ParticipantsChart);

import React, { useEffect, useState } from "react";

import icClose from "../../assets/icons/ic_close.svg";
import { CalendarIcon, ClockIcon } from "../icons";
import SelectType from "../common/SelectType";
import DepartmentsChart from "./DepartmentsChart";
import ParticipantsChart from "./ParticipantsChart";
import { getMeetingDetail } from "../../services";
import {
  capitalizeFirstLetter,
  getFormattedDate,
  getFormattedTimeRange,
  isAdmin,
} from "../../utils";
import { MetricsType } from "../../constants";
import { excludeGoal, excludeMetrics } from "../../services/meeting.service";
import { assignBrandingColorsToUsersList } from "../../utils/user";

const Options = [
  { label: "Time", value: "time" },
  { label: "Cost", value: "cost" },
];

export default function MeetingDetailModal({
  open,
  handleClose,
  meetingId,
  compensation,
}) {
  const [loading, setLoading] = useState(false);

  const [deptOption, setDeptOption] = useState(Options[0]);
  const [partOption, setPartOption] = useState(Options[1]);

  const [totalCost, setTotalCost] = useState(0);
  const [meeting, setMeeting] = useState({});
  const [departments, setDepartments] = useState([]);
  const [participants, setParticipants] = useState([]);

  const [isExcludeGoal, setIsExcludeGoal] = useState(false);
  const [isExcludeMetrics, setIsExcludeMetrics] = useState(false);

  const initDepartments = (data) => {
    if (typeof data.attendees === "undefined") {
      return;
    }

    const tempDepartments = {};
    const keyOther = "other";
    for (const attendee of data.attendees) {
      if (typeof attendee.departmentId === "undefined") {
        continue; // dont count external attendees
        // if (typeof tempDepartments[keyOther] === "undefined") {
        //   tempDepartments[keyOther] = {
        //     name: "Other",
        //     value: 0,
        //   };
        // }
        // if (deptOption.value === MetricsType.COST) {
        //   tempDepartments[keyOther].value += data.durationHours * compensation;
        // } else {
        //   tempDepartments[keyOther].value += data.durationHours;
        // }
      } else {
        if (typeof tempDepartments[attendee.departmentId] === "undefined") {
          tempDepartments[attendee.departmentId] = {
            name: capitalizeFirstLetter(attendee.departmentName),
            value: 0,
          };
        }
        if (deptOption.value === MetricsType.COST) {
          const compensation = attendee.compensation || 0;
          tempDepartments[attendee.departmentId].value +=
            data.durationHours * compensation;
        } else {
          tempDepartments[attendee.departmentId].value += data.durationHours;
        }
      }
    }

    const formattedDepartments = [];
    for (const key in tempDepartments) {
      if (key !== keyOther) {
        formattedDepartments.push({
          name: tempDepartments[key].name,
          value: tempDepartments[key].value,
        });
      }
    }

    if (typeof tempDepartments[keyOther] !== "undefined") {
      formattedDepartments.push({
        name: tempDepartments[keyOther].name,
        value: tempDepartments[keyOther].value,
      });
    }

    setDepartments(formattedDepartments);
  };

  const initParticipants = (data) => {
    if (typeof data.attendees === "undefined") return;

    console.log(data.attendees);

    const tempParticipants = [];
    for (const attendee of data.attendees) {
      const tempAttendee = {
        name: attendee.email,
        imageUrl: attendee.imageUrl || "",
        departmentName: attendee.departmentName
          ? capitalizeFirstLetter(attendee.departmentName)
          : "",
        value: undefined,
      };

      if (partOption.value === MetricsType.COST) {
        const value =
          typeof attendee.compensation === "number"
            ? data.durationHours * attendee.compensation
            : undefined;
        tempAttendee.value = value;
      } else {
        tempAttendee.value = data.durationHours;
      }

      tempParticipants.push(tempAttendee);
    }

    setParticipants(assignBrandingColorsToUsersList(tempParticipants));
  };

  const initMeeting = async (data) => {
    setMeeting(data);

    if (typeof data.attendees) {
      let tCost = data.attendees.reduce((prev, next) => {
        if (next.compensation) {
          return prev + next.compensation * data.durationHours;
        } else {
          return prev;
        }
      }, 0);
      setTotalCost(tCost);
    }

    initDepartments(data);
    initParticipants(data);

    setIsExcludeGoal(
      typeof data.isExcludeGoal === "undefined" ? false : data.isExcludeGoal
    );
    setIsExcludeMetrics(
      typeof data.isExcludeMetrics === "undefined"
        ? false
        : data.isExcludeMetrics
    );
  };

  const loadMeetingDetail = async () => {
    setLoading(true);
    const res = await getMeetingDetail(meetingId);
    if (res && res.success) {
      initMeeting(res.data);
    }
    setLoading(false);
  };

  const handleChangeExcludeGoal = async (e) => {
    if (meetingId) {
      setLoading(true);
      const params = {
        isExcludeGoal: e.target.checked,
      };
      const res = await excludeGoal(meetingId, params);
      if (res && res.success) {
        setIsExcludeGoal(res.data.isExcludeGoal);
      }
      setLoading(false);
    }
  };

  const handleChangeExcludeMetrics = async (e) => {
    if (meetingId) {
      setLoading(true);
      const params = {
        isExcludeMetrics: e.target.checked,
      };
      const res = await excludeMetrics(meetingId, params);
      if (res && res.success) {
        setIsExcludeMetrics(res.data.isExcludeMetrics);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (meetingId) {
      loadMeetingDetail();
    }
  }, [meetingId]);

  useEffect(() => {
    initDepartments(meeting);
    initParticipants(meeting);
  }, [deptOption, partOption]);

  return (
    <>
      <div className={`pop-modal ${open ? "show" : ""}`}>
        <div className="close" onClick={() => handleClose(false)}>
          <img src={icClose} />
        </div>
        <div className="meeting-detail">
          <div className="meeting-detail-header">
            <div className="title">{meeting?.summary}</div>
            <div className="d-flex gap-2 meeting-info">
              <div className="d-flex align-items-center">
                <CalendarIcon />
                <span>{getFormattedDate(meeting?.start)}</span>
              </div>
              <div className="d-flex align-items-center">
                <ClockIcon />
                <span>
                  {getFormattedTimeRange(meeting?.start, meeting?.end)}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <ClockIcon />
                <span>
                  {meeting?.durationHours}{" "}
                  {meeting?.durationHours > 1 ? "hrs" : "hr"}
                </span>
              </div>
            </div>
          </div>
          <div className="meeting-detail-content">
            <div className="d-flex box-container gap-4">
              <div className="box">
                <div className="title">Frequency</div>
                <div className="value text-blue-gradient">
                  {meeting.recurring ? "Recurring" : "One Time"}
                </div>
              </div>
              <div className="box">
                <div className="title">Type</div>
                <div className="value text-blue-gradient">
                  {meeting.isInternal ? "Internal" : "External"}
                </div>
              </div>
              {isAdmin() && (
                <div className="box">
                  <div className="title">Total Cost</div>
                  <div className="value text-blue-gradient">
                    {`${meeting?.currency}${totalCost}`}
                  </div>
                </div>
              )}
            </div>
            <div className="container-block mb-6">
              <div className="d-flex align-items-center justify-content-between container-block-header">
                <div className="title">
                  {departments.length > 0 ? departments.length : "-"}{" "}
                  Departments
                </div>
                {isAdmin() && (
                  <SelectType
                    className="select"
                    value={deptOption}
                    options={Options}
                    handleChange={setDeptOption}
                  />
                )}
              </div>
              <DepartmentsChart
                loading={loading}
                departments={departments}
                metricsType={deptOption.value}
                currency={meeting.currency}
                isAdmin={isAdmin()}
              />
            </div>
            <div className="container-block mb-6">
              <div className="d-flex align-items-center justify-content-between container-block-header">
                <div className="title">
                  {participants.length > 0 ? participants.length : "-"}{" "}
                  Participants
                </div>
                {/* <SelectType
                  className="select"
                  value={partOption}
                  options={Options}
                  handleChange={setPartOption}
                /> */}
              </div>
              <ParticipantsChart
                loading={loading}
                participants={participants}
                metricsType={partOption.value}
                currency={meeting.currency}
                isAdmin={isAdmin()}
              />
            </div>
            {isAdmin() && (
              <div className="container-block mb-10">
                <div className="d-flex align-items-center justify-content-between container-block-header mb-4">
                  <div className="title">Admin Controls</div>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isExcludeGoal}
                    onChange={handleChangeExcludeGoal}
                  />
                  <div className="form-switch-label ms-2">
                    <div className="title">Exclude from Goals</div>
                    <div className="description">
                      This is some text that explains what this means for the
                      user
                    </div>
                  </div>
                </div>
                <div className="form-check form-switch mt-6">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isExcludeMetrics}
                    onChange={handleChangeExcludeMetrics}
                  />
                  <div className="form-switch-label ms-2">
                    <div className="title">Exclude from Metrics</div>
                    <div className="description">
                      This is some text that explains what this means for the
                      user
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`pop-modal-overlay ${open ? "show" : ""}`}
        onClick={() => handleClose(false)}
      />
    </>
  );
}

import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { confirmable, createConfirmation } from "react-confirm";

const ConfirmModal = ({
  okLabel = "OK",
  cancelLabel = "Cancel",
  title = "Confirmation",
  confirmation,
  show,
  proceed,
  enableEscape = true,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      backdrop={enableEscape ? true : "static"}
      keyboard={enableEscape}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmation}</Modal.Body>
      <Modal.Footer>
        <button
          className="btn gre-button fw-bold me-3"
          onClick={() => proceed(false)}
        >
          {cancelLabel}
        </button>
        <button className="btn-primary" onClick={() => proceed(true)}>
          {okLabel}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
};

export function confirm(
  confirmation,
  proceedLabel = "OK",
  cancelLabel = "cancel",
  options = {}
) {
  return createConfirmation(confirmable(ConfirmModal))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options,
  });
}

import React, { memo } from "react";
import { formatCurrency } from "../../utils/common";

import iconClock from "../../assets/icons/clock.svg";
import iconDollar from "../../assets/icons/dollar.svg";
import { firstLetter, isAdmin } from "../../utils";

const MetWithMost = ({ data, currency, compensation }) => {
  return (
    <div className="met-with-most">
      <div className="">
        <span className="fs-24 fw-700 tc-body">Met with Most</span>
      </div>
      <div className="d-flex flex-column py-4 gap-2 list">
        {data.map((item, index) => (
          <div
            key={index}
            className="d-flex align-items-center rounded-4 p-3 gap-2 item"
          >
            <div>
              {item.avatar ? (
                <img src={item.avatar} className="avatar" alt="Avatar" />
              ) : (
                <div className={`avatar bg-green align-center`}>
                  <span>
                    {firstLetter(item.name || item.email).toUpperCase()}
                  </span>
                </div>
              )}
            </div>
            <div className="content d-flex align-items-center flex-fill">
              <div>
                <div className="fw-bold">{item.name || item.email}</div>
                <div className="text-disabled">{item.departmentName || ""}</div>
              </div>
              <div className="align-center text-accent-blue">
                <img src={iconClock} alt="Clock icon" />
                <span>{item.hours ?? "0"} hr</span>
              </div>
              {isAdmin() && (
                <div className="align-center text-accent-red">
                  <img src={iconDollar} alt="Dollar icon" />
                  <span>{`${formatCurrency(item.hours * compensation)}`}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(MetWithMost);

import React, { useMemo, useState } from "react";
import UserDetailsModal from "../common/userDetailsModal";
import Loader from "../common/loader";
import { firstLetter, isAdmin } from "../../utils";

const UserInfo = React.memo(
  ({ loading, handleEdit, userInfo, totalTime, isMyProfileInfo = true }) => {
    const [userModal, setUserModal] = useState(false);

    const compensationString = useMemo(() => {
      if (userInfo && userInfo.compensation) {
        return `${userInfo.currency || "$"}${userInfo.compensation}`;
      }

      return "---";
    }, [userInfo]);

    return (
      <>
        <div className="user-info">
          {loading && <Loader className="mx-auto" />}
          <div className="w-100 d-flex justify-content-end">
            {(isMyProfileInfo || isAdmin()) && (
              <button className="btn-gray" onClick={handleEdit}>
                Edit
              </button>
            )}
          </div>
          <div className="basic-info">
            <div
              className={`user-avatar ${userInfo?.imageUrl ? "" : "no-image"}`}
            >
              {userInfo?.imageUrl && <img src={userInfo?.imageUrl} />}
              {!userInfo?.imageUrl && (
                <span>{firstLetter(userInfo?.name)}</span>
              )}
            </div>
            <div className="user-name">{userInfo?.name}</div>
          </div>
          <div className="profile-info mt-10">
            <div className="info-wrap">
              <div className="info-title">Department</div>
              <div className="info-value text-blue-gradient">
                {userInfo?.departmentName || "---"}
              </div>
            </div>
            <div className="info-wrap">
              <div className="info-title">Timezone</div>
              <div className="info-value text-blue-gradient">
                {userInfo?.timezone || "---"}
              </div>
            </div>
            {isAdmin() && (
              <div className="info-wrap">
                <div className="info-title">Compensation</div>
                <div className="info-value text-blue-gradient">
                  {compensationString}
                </div>
              </div>
            )}
            <div className="info-wrap">
              <div className="info-title">Total time this week</div>
              <div className="info-value text-blue-gradient">{`${
                totalTime || "---"
              } ${totalTime === 1 ? "hr" : "hrs"}`}</div>
            </div>
          </div>
        </div>
        {userModal && <UserDetailsModal setUserModal={setUserModal} />}
      </>
    );
  }
);

export default UserInfo;

import { DateRange } from "../constants";
import {
  getLastMonth,
  getLastQuarter,
  getLastSixMonths,
  getLastWeek,
  getThisWeek,
} from "./date";

export const option1 = [
  { value: "all-meeting", label: "All Meetings" },
  { value: "recurring", label: "Recurring Meetings" },
  { value: "one-off", label: "One-off Meeting" },
];

export const option2 = [
  { value: DateRange.THIS_WEEK, label: "This week" },
  { value: DateRange.LAST_WEEK, label: "Last week" },
  { value: DateRange.LAST_MONTH, label: "Last month" },
  { value: DateRange.LAST_QUARTR, label: "Last Quarter" },
  { value: DateRange.LAST_6_MONTHS, label: "Last 6 months" },
];

export const option4 = [
  { value: DateRange.THIS_WEEK, label: "This week" },
  { value: DateRange.LAST_WEEK, label: "Last week" },
];

export const option3 = [
  { value: "cost", label: "Costs" },
  { value: "number", label: "Count" },
  { value: "hours", label: "Time" },
];

export function dateFilterOptions() {
  const thisWeek = getThisWeek();
  const lastWeek = getLastWeek();
  const lastMonth = getLastMonth();
  const lastQuarter = getLastQuarter();
  const lastSixMonths = getLastSixMonths();

  return [
    {
      value: DateRange.THIS_WEEK,
      label: `This week (${thisWeek.startDate} - ${thisWeek.endDate})`,
    },
    {
      value: DateRange.LAST_WEEK,
      label: `Last week (${lastWeek.startDate} - ${lastWeek.endDate})`,
    },
    {
      value: DateRange.LAST_MONTH,
      label: `Last month (${lastMonth.startDate} - ${lastMonth.endDate})`,
    },
    {
      value: DateRange.LAST_QUARTR,
      label: `Last Quarter (${lastQuarter.startDate} - ${lastQuarter.endDate})`,
    },
    {
      value: DateRange.LAST_6_MONTHS,
      label: `Last 6 months (${lastSixMonths.startDate} - ${lastSixMonths.endDate})`,
    },
  ];
}

import axios from "axios";
import Cookies from "js-cookie";
import React, { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CloseIcon } from "../../assets/icons/meetingIcons";
import Input from "./input";
import SelectType from "./SelectType";
import { useDispatch, useSelector } from "react-redux";
import { adminUserDetailData } from "../../redux/actions/adminAction";
import { adminDataSelector } from "../../redux/reducer/adminSlice";

const UserDetailsModal = ({ setUserModal, departmentData }) => {
  const { filterData } = useSelector(adminDataSelector);

  const userDetails = useMemo(
    () => departmentData?.usersData,
    [departmentData]
  );
  const { id } = useParams();
  const token = Cookies.get("tokens");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    defaultValues: {
      compensation: userDetails?.compensation,
      department: {
        value: userDetails?.department_id,
        label: userDetails?.department_name,
      },
      pto: { value: userDetails?.pto, label: userDetails?.pto },
    },
  });

  const departmentDataMemo = useMemo(
    () => departmentData?.usersData,
    [departmentData]
  );
  const departmentList = useMemo(
    () => departmentDataMemo?.allDepartments,
    [departmentDataMemo]
  );

  const departmentOption = useMemo(
    () =>
      departmentList?.length > 0 &&
      departmentList?.map((item) => {
        return { value: item._id, label: item.name };
      }),
    [departmentList]
  );

  const countToTenOptions = useMemo(
    () => ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    []
  );

  const editDepartment = useCallback(
    async (data) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/editUserDetails/`,
          {
            _id: id,
            compensation: data?.compensation,
            pto: data?.pto?.value || data?.pto,
            department_id: data?.department?.value || data?.department,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success(response.data.message);
        setUserModal(false);
        dispatch(adminUserDetailData(id, token, filterData.timePeriod));
      } catch (error) {
        toast.error(error.message);
      }
    },
    [id, token, setUserModal]
  );

  return (
    <div className="modal-user">
      <div className="modal-user-content">
        <div className="d-flex justify-content-between align-items-center header-div">
          <h3 className="fs-24 fw-bold m-0 text-cl-grey">
            Edit user details
          </h3>
          <button
            type="button"
            className="close-user-modal p-0 m-0 border-0 bg-white"
            onClick={() => {
              setUserModal(false);
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <form onSubmit={handleSubmit(editDepartment)}>
          <div className="form-control border-0">
            <label className="fs-14 text-cl-grey fw-bold w-100 text-start mb-2">
              Department
            </label>
            <div className="input-wrapper">
              <Controller
                name="department"
                control={control}
                {...register("department", { required: false })}
                render={({ field, formState }) => (
                  <SelectType
                    {...register("department", { required: false })}
                    // value={departmentOption[0]}
                    defaultValue={{
                      value: userDetails?.department_id,
                      label: userDetails?.department_name,
                    }}
                    options={departmentOption}
                    isSearchable={false}
                    handleChange={(e) => {
                      field.onChange(e.value);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="form-control mt-4 border-0 mb-5">
            <label className="fs-14 text-cl-grey fw-bold w-100 text-start mb-2">
              Total hourly cost
            </label>
            <div className="input-wrapper">
              <Controller
                name="compensation"
                control={control}
                {...register("compensation", { required: true })}
                render={({ field }) => (
                  <Input
                    value={userDetails?.compensationNumber.toFixed(2)}
                    {...register("compensation", {
                      required: true,
                      max: {
                        value: 999999,
                        message: `Compensation amount must not exceed 999999`,
                      },
                      min: {
                        value: 1,
                        message: `Compensation amount must be at least 1`,
                      },
                      validate: (value) => {
                        if (!/^[0-9]/g.test(Number(value))) {
                          return "Only number allowed";
                        } else if (
                          (value.toString().split(".")[1] || "")?.length > 2
                        ) {
                          return "Only up to 2 decimal places are allowed";
                        }
                        return true;
                      },
                    })}
                    handleSelectChange={(e) => {
                      field.onChange(e);
                    }}
                    type="text"
                    className="flex-1"
                  />
                )}
              />
              {/*<Controller
                name="compensation"
                control={control}
                {...register("compensation", { required: false })}
                render={({ field, formState }) => (
                  <SelectType
                    {...register("compensation", { required: false })}
                    // value={compensationOption[0]}
                    //defaultValue={compensationOption[0]}
                    options={compensationOption}
                    isSearchable={false}
                    handleChange={(e) => {
                      field.onChange(e.value);
                    }}
                  />
                )}
              />*/}
            </div>
            {errors?.compensation && (
              <span className="error-message">
                {errors?.compensation.message === ""
                  ? errors?.compensation.type
                  : errors?.compensation.message}
              </span>
            )}
          </div>
          {/* <div className="form-control mt-4 border-0 mb-5">
            <label className="fs-14 text-cl-grey fw-bold w-100 text-start mb-2">
              PTO
            </label>
            <div className="input-wrapper">
              <Controller
                name="pto"
                control={control}
                {...register("pto", { required: false })}
                render={({ field, formState }) => (
                  <SelectType
                    {...register("pto", { required: false })}
                    defaultValue={{
                      value: userDetails.pto,
                      label: userDetails.pto,
                    }}
                    isSearchable={false}
                    options={countToTenOptions.map((item) => {
                      return { value: item, label: item };
                    })}
                    handleChange={(e) => {
                      field.onChange(e.value);
                    }}
                  />
                )}
              />
            </div>
          </div> */}
          <div className="d-flex justify-content-center gap-3 pt-3 btn-grp">
            <button
              className="mt-4 btn grey-button tc-white fw-bold"
              onClick={() => {
                setUserModal(false);
              }}
              type="button"
            >
              Discard
            </button>
            <button
              type="submit"
              className="mt-4 btn primary-button tc-white fw-bold cursor-pointer"
              disabled={!isValid}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserDetailsModal;

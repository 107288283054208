// import "./assets/theme/css/style.bundle.css";
import "./assets/css/style.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Router from "./router";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./redux/store";
import { appConfig } from "./constants";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={appConfig.google.clientId}>
          <ToastContainer />
          <Router />
        </GoogleOAuthProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;

import { createSlice } from "@reduxjs/toolkit";
import { DateRange, FilterOption, MetricsType } from "../../constants";

const initialState = {
  loading: false,
  error: null,
  userInfo: null,
  metricsType: MetricsType.TIME,
  meetingType: {
    value: FilterOption.EVERYONE,
    label: FilterOption.EVERYONE,
    group: FilterOption.EVERYONE,
  },
  dateRange: {
    value: DateRange.LAST_WEEK,
    label: DateRange.LAST_WEEK,
  },
  department: null,
  departments: [],
  users: [],
  compensation: 0,
  currency: 0,
  numOfUsers: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    failure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    authSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.userInfo = action.payload.user;
      state.compensation = action.payload.compensation;
      state.currency = action.payload.currency;
      state.numOfUsers = action.payload.numOfUsers;
    },
    onboardSuccess: (state, action) => {
      const userInfo = state.userInfo || {};
      state.userInfo = { ...userInfo, isRegister: true };
    },
    logout: (state, action) => {
      state.userInfo = null;
    },
    updateMetricsType: (state, action) => {
      state.metricsType = action.payload;
    },
    updateMeetingType: (state, action) => {
      state.meetingType = action.payload;
    },
    updateDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    updateDepartment: (state, action) => {
      state.department = action.payload;
    },
    updateDepartments: (state, action) => {
      state.loading = false;
      state.error = null;
      state.departments = action.payload;
    },
    updateUsers: (state, action) => {
      state.loading = false;
      state.error = null;
      state.users = action.payload;
    },
    updateCompensation: (state, action) => {
      state.compensation = action.payload;
    },
    updateCurrency: (state, action) => {
      state.currency = action.payload;
    },
    updateNumOfUsers: (state, action) => {
      state.numOfUsers = action.payload;
    },
  },
});

export const {
  startLoading,
  failure,
  authSuccess,
  logout,
  updateMetricsType,
  updateMeetingType,
  updateDateRange,
  updateDepartment,
  updateDepartments,
  updateUsers,
  updateCompensation,
  updateCurrency,
  updateNumOfUsers,
  onboardSuccess,
} = appSlice.actions;

export const appDataSelector = (state) => state.app;

export default appSlice.reducer;

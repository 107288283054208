import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Loader from "../common/loader";

import { CloseIcon } from "../icons";
import { Colors, OnboardingFlow, Timezone } from "../../constants";
import { convertToNumber, getGMTOffset, isAdmin } from "../../utils";

import icTick from "../../assets/icons/ic_tick.svg";
import { confirm } from "../modal/ConfirmModal";
import { saveOnboardingData } from "../../services/onboarding.service";
import { useDispatch, useSelector } from "react-redux";
import { appDataSelector } from "../../redux/reducer";
import { onboardSuccess } from "../../redux/reducer/app.slice";
import { getDecodedUserData } from "../../services/auth.service";
import UpgradeModal from "../modal/UpgradeModal";
import Cookies from "js-cookie";
import axios from "axios";
import { INVITE_LIST_STORAGE_KEY } from "../../constants/app-config";

export default function OnboardingModal({
  handleClose,
  reload,
  onOpenUpgradeModal,
}) {
  const { departments: departmentList } = useSelector(appDataSelector);

  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(
    isAdmin()
      ? OnboardingFlow.Steps.COMPENSATION
      : OnboardingFlow.Steps.DEPARTMENT
  );

  const [compensation, setCompensation] = useState(30);
  const [departments, setDepartments] = useState([]);
  const [typedDept, setTypedDept] = useState("");
  const [department, setDepartment] = useState("");
  const [timezones, setTimezones] = useState([]);
  const [timezone, setTimezone] = useState(Timezone.DEFAULT);
  const [inviteList, setInviteList] = useState([]);
  const [typedInvite, setTypedInvite] = useState("");

  const dispatch = useDispatch();

  const handleAddDepartment = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (typedDept.length > 0) {
        const dept = departments.find(
          (item) => item.toLowerCase() === typedDept.toLowerCase()
        );

        if (typeof dept === "undefined") {
          setDepartments([...departments, typedDept]);
        }
      }
      setTypedDept("");
    }
  };

  const handleRemoveDepartment = (dept) => {
    const idx = departments.indexOf(dept);

    if (idx > -1) {
      if (departments[idx] === department) {
        setDepartment("");
      }

      departments.splice(idx, 1);
      setDepartments([...departments]);
    }
  };

  const handleAddInvite = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (typedInvite.length > 0) {
        const invite = inviteList.find(
          (item) => item.toLowerCase() === typedInvite.toLowerCase()
        );

        if (typeof invite === "undefined") {
          setInviteList([...inviteList, typedInvite]);
        }
      }
      setTypedInvite("");
    }
  };

  const handleRemoveInvite = (invite) => {
    const idx = inviteList.indexOf(invite);
    if (idx > -1) {
      inviteList.splice(idx, 1);
      setInviteList([...inviteList]);
    }
  };

  const handleBack = () => {
    if (isAdmin()) {
      if (step > 0) {
        setStep(step - 1);
      }
    } else {
      if (step > 2) {
        setStep(step - 1);
      }
    }
  };

  const handleNext = async () => {
    if ((isAdmin() && step < 4) || (!isAdmin() && step < 3)) {
      if (
        isAdmin() &&
        step === OnboardingFlow.Steps.COMPENSATION &&
        compensation === 0
      ) {
        toast.error("The compensation must be more than zero.");
        return;
      }

      if (
        isAdmin() &&
        step === OnboardingFlow.Steps.DEPARTMENT_LIST &&
        departments.length === 0
      ) {
        toast.error("Please add departments");
        return;
      }

      if (step === OnboardingFlow.Steps.DEPARTMENT && department === "") {
        toast.error("Please select your department");
        return;
      }

      if (step === OnboardingFlow.Steps.TIMEZONE && timezone === null) {
        toast.error("Please select your timezone");
        return;
      }

      setStep(step + 1);
    } else {
      if (
        !isAdmin() &&
        step === OnboardingFlow.Steps.TIMEZONE &&
        timezone === null
      ) {
        toast.error("Please select your timezone");
        return;
      }

      const addedDepartments = [];

      for (const dept of departments) {
        const idx = departmentList.findIndex((item) => item.name === dept);
        if (idx === -1) {
          addedDepartments.push(dept);
        }
      }

      const data = {
        compensation,
        departments: addedDepartments,
        department,
        timezone,
        inviteList,
      };

      const userData = await getDecodedUserData();
      const token = Cookies.get("tokens");
      const fetchUsers = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users?searchText=`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const totalUsers = fetchUsers.data.data.totalCount;
      const invitesCount = inviteList.length;
      const totalCount = totalUsers + invitesCount;

      if (userData.isAdmin && totalCount > 3) {
        sessionStorage.setItem(
          INVITE_LIST_STORAGE_KEY,
          JSON.stringify(inviteList)
        );
        data.inviteList = [];
        onOpenUpgradeModal(totalCount);
      }

      setLoading(true);
      const res = await saveOnboardingData(data);
      setLoading(false);

      if (res && res.success) {
        dispatch(onboardSuccess());
        handleClose();
        reload();
      } else {
        toast.error("Failed");
      }
    }
  };

  const handleCloseButton = async () => {
    if (
      await confirm(
        "You need to complete these steps. Do you want to complete these steps later?"
      )
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    if (departmentList.length > 0) {
      const tempDepartmentList = [];
      for (const dept of departmentList) {
        tempDepartmentList.push(dept.name);
      }
      setDepartments(tempDepartmentList);
    }
  }, [departmentList]);

  useEffect(() => {
    const timezoneList = Intl.supportedValuesOf("timeZone");
    const formattedTimezones = [];
    for (const timezone of timezoneList) {
      const { gmtOffset, offset } = getGMTOffset(timezone);
      formattedTimezones.push({ gmtOffset, offset, timezone });
    }

    formattedTimezones.sort((a, b) => (a.offset > b.offset ? 1 : -1));
    setTimezones(formattedTimezones);
  }, []);

  return (
    <div className="pop-wrapper">
      <div className="inner-wrapper onboarding-modal">
        <div className="onboarding-header">
          <div className="btn-wrap">
            <div className="btn-close" onClick={() => handleCloseButton()} />
          </div>
          <div className="title-wrap">
            <div className="onboarding-title">{OnboardingFlow.Title[step]}</div>
            <div className="onboarding-subtitle">
              {OnboardingFlow.Subtitle[step]}{" "}
            </div>
          </div>
        </div>
        <div className="onboarding-content-wrap">
          {step === OnboardingFlow.Steps.COMPENSATION && (
            <div className="onboarding-content compensation-wrap">
              <div className="compensation-info">
                <span className="text-blue-gradient compensation-currency">
                  $
                </span>
                <input
                  className="text-blue-gradient compensation-value"
                  type="text"
                  size={compensation.length > 0 ? compensation.length : 3}
                  value={compensation}
                  onChange={(e) =>
                    setCompensation(convertToNumber(e.target.value))
                  }
                />
              </div>
              <div className="compensation-per-hour">per hour</div>
            </div>
          )}

          {step === OnboardingFlow.Steps.DEPARTMENT_LIST && (
            <div className="onboarding-content border-wrap">
              <div className="tag-list">
                {departments.map((item, index) => (
                  <div
                    key={`departments-item-${index}`}
                    className="tag-list-item"
                  >
                    <span>{item}</span>
                    <div
                      className="tag-list-item-remove-button"
                      onClick={() => handleRemoveDepartment(item)}
                    >
                      <CloseIcon color={Colors.Text.Accent.BLUE} />
                    </div>
                  </div>
                ))}
                <input
                  className="tag-input"
                  placeholder="Start typing to add a department"
                  size={typedDept.length > 0 ? typedDept.length : 30}
                  value={typedDept}
                  onChange={(e) => setTypedDept(e.target.value)}
                  onKeyDown={handleAddDepartment}
                />
              </div>
            </div>
          )}

          {step === OnboardingFlow.Steps.DEPARTMENT && (
            <div className="onboarding-content department-list-wrap">
              <div className="tag-list">
                {departments.map((item, index) => (
                  <div
                    key={`department-item-${index}`}
                    className={`tag-list-item ${
                      item === department ? "active" : ""
                    }`}
                    onClick={() => setDepartment(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          )}

          {step === OnboardingFlow.Steps.TIMEZONE && (
            <div className="onboarding-content timezone-list">
              {timezones.map((item, index) => (
                <div
                  key={`timezone-list-item-${index}`}
                  className={`timezone-list-item ${
                    timezone === item.timezone ? "active" : ""
                  }`}
                  onClick={() => setTimezone(item.timezone)}
                >
                  <span>{`(${item.gmtOffset}) ${item.timezone}`}</span>
                  {timezone === item.timezone && <img src={icTick} />}
                </div>
              ))}
            </div>
          )}

          {step === OnboardingFlow.Steps.INVITE_TEAM && (
            <div className="onboarding-content border-wrap">
              <div className="tag-list">
                {inviteList.map((item, index) => (
                  <div key={`invite-item-${index}`} className="tag-list-item">
                    <span>{item}</span>
                    <div
                      className="tag-list-item-remove-button"
                      onClick={() => handleRemoveInvite(item)}
                    >
                      <CloseIcon color={Colors.Text.Accent.BLUE} />
                    </div>
                  </div>
                ))}
                <input
                  className="tag-input"
                  placeholder="Add a email to invite the user"
                  size={typedInvite.length > 0 ? typedInvite.length : 25}
                  value={typedInvite}
                  onChange={(e) => setTypedInvite(e.target.value)}
                  onKeyDown={handleAddInvite}
                />
              </div>
            </div>
          )}
        </div>
        <div className="onboarding-footer">
          {step > 0 ? (
            <button className="footer-button back" onClick={handleBack}>
              Back
            </button>
          ) : (
            <div />
          )}
          {step === OnboardingFlow.Steps.INVITE_TEAM && (
            <button className="footer-button back skip" onClick={handleNext}>
              Invite Later
            </button>
          )}
          <button
            className="footer-button next"
            onClick={handleNext}
            disabled={
              step === OnboardingFlow.Steps.INVITE_TEAM &&
              inviteList.length === 0
            }
          >
            {step === OnboardingFlow.Steps.INVITE_TEAM
              ? "Send Invites"
              : "Next"}{" "}
            {loading && <Loader />}
          </button>
        </div>
      </div>
    </div>
  );
}

import { Colors } from "../../constants";

export const CalendarIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66671 3.9585V6.87516M13.3334 3.9585V6.87516M6.45837 8.9585H13.5417M5.62504 16.0418H14.375C15.2955 16.0418 16.0417 15.2956 16.0417 14.3752V7.29183C16.0417 6.37135 15.2955 5.62516 14.375 5.62516H5.62504C4.70457 5.62516 3.95837 6.37135 3.95837 7.29183V14.3752C3.95837 15.2956 4.70457 16.0418 5.62504 16.0418Z"
      stroke={color ?? Colors.Icon.DEFAULT}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  hasError: false,
  users: [],
  departments: [],
  totalMeetings: 0,
  totalMeetingsLoading: false,
  topMeetings: [],
  topMeetingsLoading: false,
  meetingCost: [],
  meetingCostLoading: false,
  meetingListData: {},
  meetingListDataLoading: false,
  meetingDetailsData: {},
  meetingDetailsDataLoading: false,
  matrics: {},
  matricsLoading: false,
  matricsMeetingCost: [],
  matricsMeetingCostLoading: false,
  matricsPerDepartment: [],
  matricsPerDepartmentLoading: false,
  matrixMostParticipants: [],
  matrixMostParticipantsLoading: false,
  matrixMostMeetings: [],
  matrixMostMeetingsLoading: false,
  matrixMeetingsNumberParticipants: {},
  matrixMeetingsNumberParticipantsLoading: false,
  matrixRecurringMostParticipants: [],
  matrixRecurringMostParticipantsLoading: false,
  matrixMeetingDistribution: {},
  matrixMeetingDistributionLoading: false,
  adminUserList: [],
  adminUserListLoading: false,
  adminUserDetails: [],
  adminUserDetailsLoading: false,
  userMeetingListData: [],
  userMeetingListDataLoading: false,
  userSummarySuccess: {},
  userSummarySuccessLoading: false,
  userCostlyMeeting: [],
  userCostlyMeetingLoading: false,
  userPeopleMeetMost: [],
  userPeopleMeetMostLoading: false,
  userRecentMeeting: [],
  userRecentMeetingLoading: false,
  webhookList: [],
  webhookListLoading: false,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getAdminData: (state) => {
      state.loading = true;
      state.error = false;
    },
    getAdminFilterData: (state, action) => {
      state.filterData = action.payload;
      state.loading = false;
      state.error = false;
    },
    getUsersSuccess: (state, action) => {
      state.users = action.payload;
      state.loading = false;
      state.error = false;
    },
    getUsersFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getDepartmentsSuccess: (state, action) => {
      state.departments = action.payload;
      state.loading = false;
      state.error = false;
    },
    getDepartmentsFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getAdminDashboardFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getMeetingCountSuccess: (state, action) => {
      state.totalMeetings = action.payload.count;
      state.totalMeetingsLoading = action.payload.loading;
      state.error = false;
    },
    getTopMeetingSuccess: (state, action) => {
      state.topMeetings = action.payload.topUsers;
      state.topMeetingsLoading = action.payload.loading;
      state.error = false;
    },
    getCostMeetingSuccess: (state, action) => {
      state.meetingCost = action.payload;
      state.meetingCostLoading = action.payload.loading;
      state.error = false;
    },
    getMeetingListSuccess: (state, action) => {
      state.meetingListData = action.payload;
      state.meetingListDataLoading = action.payload.loading;
      state.error = false;
    },
    getUserMeetingListSuccess: (state, action) => {
      state.userMeetingListData = action.payload;
      state.userMeetingListDataLoading = action.payload.loading;
      state.error = false;
    },
    getMeetingDetailsSuccess: (state, action) => {
      state.meetingDetailsData = action.payload;
      state.meetingDetailsDataLoading = action.payload.loading;
      state.error = false;
    },
    getMetricsSuccess: (state, action) => {
      state.matrics = action.payload;
      state.matricsLoading = action.payload.loading;
      state.error = false;
    },
    getMetricsCostOfMeetingSuccess: (state, action) => {
      state.matricsMeetingCost = action.payload;
      state.matricsMeetingCostLoading = action.payload.loading;
      state.error = false;
    },
    getMetricsMeetingPerDepartmentSuccess: (state, action) => {
      state.matricsPerDepartment = action.payload;
      state.matricsPerDepartmentLoading = action.payload.loading;
      state.error = false;
    },
    getMetricsMostParticipantsSuccess: (state, action) => {
      state.matrixMostParticipants = action.payload.matrixMostParticipants;
      state.matrixMostParticipantsLoading = action.payload.loading;
      state.error = false;
    },
    getMetricsMostMeetingsSuccess: (state, action) => {
      state.matrixMostMeetings = action.payload.matrixMostMeetings;
      state.matrixMostMeetingsLoading = action.payload.loading;
      state.error = false;
    },
    getMetricsReccuringMostParticipantsSuccess: (state, action) => {
      state.matrixRecurringMostParticipants =
        action.payload.matrixRecurringMostParticipants;
      state.matrixRecurringMostParticipantsLoading = action.payload.loading;
      state.error = false;
    },
    getMetricsNumberParticipantsSuccess: (state, action) => {
      state.matrixMeetingsNumberParticipantss = action.payload;
      state.matrixMeetingsNumberParticipantssLoading = action.payload.loading;
      state.error = false;
    },
    getMetricsMeetingDistributionSuccess: (state, action) => {
      state.matrixMeetingDistribution = action.payload;
      state.matrixMeetingDistributionLoading = action.payload.loading;
      state.error = false;
    },
    getAdminUserListSuccess: (state, action) => {
      state.adminUserList = action.payload;
      state.adminUserListLoading = action.payload.loading;
      state.error = false;
    },
    getAdminUserDetailSuccess: (state, action) => {
      state.adminUserDetails = action.payload.adminUserDetails;
      state.adminUserDetailsLoading = action.payload.loading;
      state.error = false;
    },
    getAdminUserSummarySuccess: (state, action) => {
      state.userSummarySuccess = action.payload;
      state.userSummarySuccessLoading = action.payload.loading;
      state.error = false;
    },
    getAdminUserMostMeetingsSuccess: (state, action) => {
      state.userCostlyMeeting = action.payload.costlyMeeting;
      state.userCostlyMeetingLoading = action.payload.loading;
      state.error = false;
    },
    getAdminUserPeopleMeetMostSuccess: (state, action) => {
      state.userPeopleMeetMost = action.payload.topMetUser;
      state.userPeopleMeetMostLoading = action.payload.loading;
      state.error = false;
    },
    getAdminUserRecentMeetingsSuccess: (state, action) => {
      state.userRecentMeeting = action.payload.topMetUser;
      state.userRecentMeetingLoading = action.payload.loading;
      state.error = false;
    },
    getAdminWebhookListSuccess: (state, action) => {
      state.webhookList = action.payload;
      state.webhookListLoading = action.payload.loading;
      state.error = false;
    },
  },
});

export const {
  getAdminData,
  getAdminFilterData,
  getUsersSuccess,
  getUsersFailure,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  getAdminDashboardFailure,
  getMeetingCountSuccess,
  getTopMeetingSuccess,
  getCostMeetingSuccess,
  getMeetingListSuccess,
  getUserMeetingListSuccess,
  getMeetingDetailsSuccess,
  getMetricsSuccess,
  getMetricsCostOfMeetingSuccess,
  getMetricsMeetingPerDepartmentSuccess,
  getMetricsMostParticipantsSuccess,
  getMetricsReccuringMostParticipantsSuccess,
  getMetricsNumberParticipantsSuccess,
  getMetricsMostMeetingsSuccess,
  getMetricsMeetingDistributionSuccess,
  getAdminUserListSuccess,
  getAdminUserDetailSuccess,
  getAdminUserSummarySuccess,
  getAdminUserMostMeetingsSuccess,
  getAdminUserPeopleMeetMostSuccess,
  getAdminUserRecentMeetingsSuccess,
  getAdminWebhookListSuccess,
} = adminSlice.actions;
export const adminDataSelector = (state) => state.adminDashboard;
export default adminSlice.reducer;

import React, { useCallback, useEffect, useState } from "react";
import SideBar from "../../component/sidebar/SideBar";
import UserInfo from "../../component/user-details/UserInfo";
import UserRecentMeetings from "../../component/user-details/UserRecentMeetings";
import { Currency } from "../../constants";
import { GoalList } from "../../component/goals";
import MetWithMost from "../../component/dashboard/MetWithMost";
import { useDispatch, useSelector } from "react-redux";
import { appDataSelector } from "../../redux/reducer";
import { fetchUser } from "../../services";
import UserEditModal from "../../component/users/UserEditModal";
import { getDepartments } from "../../redux/actions";
import { useParams } from "react-router-dom";

function UserDetails({ isMyProfileDetails = true }) {
  const dispatch = useDispatch();
  const { id: userIdParam } = useParams();
  const appData = useSelector(appDataSelector);

  const [showModal, setShowModal] = useState(false);

  const [compensation, setCompensation] = useState(0);
  const [currency, setCurrency] = useState(Currency.DEFAULT);
  const [userDetail, setUserDetail] = useState([]);
  const [goals, setGoals] = useState([]);
  const [lastMeetings, setLastMeetings] = useState([]);
  const [metWithMost, setMetWithMost] = useState([]);
  const [totalTime, setTotalTime] = useState();

  const loadUserInfo = useCallback(
    async (id) => {
      const res = await fetchUser(id);
      if (res && res.success) {
        console.log(res);
        setCompensation(res.data.compensation);
        setCurrency(res.data.currency);
        setUserDetail(res.data.user);
        setGoals(res.data.goals);
        setLastMeetings(res.data.lastMeetings);
        setMetWithMost(res.data.metWithMost);
        setTotalTime(res.data.totalTime);
      }

      dispatch(getDepartments());
    },
    [dispatch]
  );

  const reloadUserInfo = useCallback(() => {
    if (isMyProfileDetails && appData.userInfo) {
      loadUserInfo(appData.userInfo.id);
    } else if (userIdParam) {
      loadUserInfo(userIdParam);
    }
  }, [appData.userInfo, isMyProfileDetails, loadUserInfo, userIdParam]);

  useEffect(() => {
    reloadUserInfo();
  }, [reloadUserInfo]);

  const userInfo = isMyProfileDetails ? appData.userInfo : userDetail;
  const departments = appData.departments;

  return (
    <div
      className="d-flex vh-min-100 bg-blue-lt"
      data-kt-app-sidebar-fixed="true"
    >
      <div className="d-flex flex-column flex-root app-root">
        <div className="app-page flex-column flex-column-fluid">
          <div className="app-wrapper flex-column flex-row-fluid">
            <SideBar />
            <div className="app-main flex-column flex-row-fluid">
              <div className="app-content flex-column-fluid content-wrapper p-0">
                <div className="user-details">
                  {showModal && (
                    <UserEditModal
                      user={userInfo}
                      departments={departments}
                      handleClose={() => setShowModal(false)}
                      reload={reloadUserInfo}
                    />
                  )}
                  <div className="left-container">
                    <div className="user-content">
                      <UserInfo
                        userInfo={userDetail}
                        totalTime={totalTime}
                        handleEdit={() => setShowModal(true)}
                        isMyProfileInfo={isMyProfileDetails}
                      />
                      {isMyProfileDetails && <GoalList goals={goals} />}
                    </div>
                  </div>
                  <div className="right-container">
                    <UserRecentMeetings
                      meetings={lastMeetings}
                      compensation={compensation}
                      currency={currency}
                    />
                    <MetWithMost
                      data={metWithMost}
                      currency={currency}
                      compensation={compensation}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserDetails;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    meetingTime: { in_meeting: 0, not_in_meeting: 0 },
};

const pieChartSlice = createSlice({
    name: "pieChart",
    initialState,
    reducers: {
        getMeetingTimeSuccess: (state, action) => {
            state.meetingTime = action.payload;
            state.meetingTimeLoading = action.payload.loading
        },
    },
});

export const {
    getMeetingTimeSuccess,
} = pieChartSlice.actions;
export const pieChartDataSelector = (state) => state.pieChartData;
export default pieChartSlice.reducer;

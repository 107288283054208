import SideBar from "../../component/sidebar/SideBar";
import Header from "../../component/common/header";
import UserTable from "../../component/users/UserTable";
import { HEADER } from "../../constants";

function Users() {
  return (
    <div
      className="d-flex vh-min-100 bg-blue-lt"
      data-kt-app-sidebar-fixed="true"
    >
      <div className="d-flex flex-column flex-root app-root">
        <div className="app-page flex-column flex-column-fluid">
          <div className="app-wrapper flex-column flex-row-fluid">
            <SideBar />
            <div className="app-main flex-column flex-row-fluid">
              <Header heading={HEADER.Title.Users} />
              <div className="app-content flex-column-fluid content-wrapper">
                <UserTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Users;

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/theme/css/style.bundle.css";
// import "./assets/css/style.min.css";

import Login from "./pages/auth/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Metrics from "./pages/metrics/Metrics";
import Goals from "./pages/goals/Goals";
import Meetings from "./pages/meeting/Meetings";
import Users from "./pages/user/Users";
import UserDetails from "./pages/user/UserDetails";
import Settings from "./pages/settings/Settings";

import AuthRoute from "./provider/authRoute";

import PaymentSelect from "./pages/paymentPlan.js/paymentSelect";
import SendInvite from "./pages/sendInvite/sendInvite";
import ErrorScreen from "./pages/404/ErrorScreen";
import { isAdmin } from "./utils";
import { AppContextProvider } from "./contexts/appContext";
import { INVITE_LIST_STORAGE_KEY } from "./constants/app-config";
import { inviteUsers } from "./services";

const completeCheckoutLoader = async () => {
  try {
    console.log("Complete checkout");

    const storageData = sessionStorage.getItem(INVITE_LIST_STORAGE_KEY);
    const parsed = JSON.parse(storageData);
    if (Array.isArray(parsed)) {
      await inviteUsers(parsed);
      sessionStorage.removeItem(INVITE_LIST_STORAGE_KEY);
    }
  } catch (error) {
    console.error(error);
  } finally {
    return true;
  }
};

const rootLoader = () => {
  const params = new URLSearchParams(window.location.search);

  const psXid = params.get("ps_xid");
  const psPartnerKey = params.get("ps_partner_key");

  if (psPartnerKey && psXid) {
    sessionStorage.setItem("ps_xid", psXid);
    sessionStorage.setItem("ps_partner_key", psPartnerKey);
  }

  return true;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />} loader={rootLoader}>
      <Route path="/" element={<AuthRoute component={<Login />} />} />
      <Route
        path="/login"
        element={<AuthRoute component={<Login isLogin={true} />} />}
      />
      <Route
        path="/send-invite"
        element={<SendInvite />}
        //element={<AuthRoute component={<SendInvite />} />}
      />
      <Route
        element={
          <AuthRoute
            component={
              <AppContextProvider>
                <Outlet />
              </AppContextProvider>
            }
          />
        }
      >
        <Route
          path="/complete-checkout"
          loader={completeCheckoutLoader}
          element={<Navigate to="/" replace />}
        />
        <Route path="/admin" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route
      path="/workspace-confirm"
      element={<AuthRoute component={<WorkSpace />} />}
    /> */}
        <Route path="/select-plan" element={<PaymentSelect />} />
        <Route path="/meetings" element={<Meetings />} />
        <Route
          path="/metrics"
          element={
            <AdminRoute>
              <Metrics />
            </AdminRoute>
          }
        />
        <Route path="/goals" element={<Goals />} />
        <Route path="/users" element={<Users />} />
        <Route
          path="/users/:id"
          element={<UserDetails isMyProfileDetails={false} />}
        />
        <Route path="/my-profile" element={<UserDetails />} />
        <Route path="/settings" element={<Settings />} />
      </Route>
      <Route path="*" element={<ErrorScreen />} />
    </Route>
  )
);

function Router() {
  return <RouterProvider router={router} />;
}

function AdminRoute({ children }) {
  if (!isAdmin()) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default Router;

import React, { useState } from "react";

import icArrowRight from "../../assets/icons/ic_arrow-right.svg";
import { getFormattedDate, getFormattedTimeRange, isAdmin } from "../../utils";
import { useNavigate } from "react-router-dom";
import MeetingDetailModal from "../meetings/MeetingDetailModal";
import { formatCurrency } from "../../utils/common";
import iconDollar from "../../assets/icons/dollar.svg";

const UserRecentMeetings = ({ meetings, compensation, currency }) => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [meetingId, setMeetingId] = useState(null);

  const handleShowAll = () => {
    navigate("/meetings");
  };

  const handleClickMeeting = (meetingId) => {
    setMeetingId(meetingId);
    setShowModal(true);
  };

  return (
    <div className="data-tables user-recent-meetings mb-10">
      <div className="title-bar">
        <span className="title">Last 3 meetings</span>
        <button className="button" onClick={handleShowAll}>
          Show All
        </button>
      </div>
      <div className="table-responsive">
        <table className="table align-middle">
          <thead>
            <tr className="fs-7 fw-bold">
              <th className={`min-w-175px text-start lt-grey`}>Meeting</th>
              <th className={`text-start lt-grey`}>Date</th>
              <th className="text-start lt-grey">Time</th>
              <th className="text-start lt-grey">
                {isAdmin() ? "Cost" : "Duration"}
              </th>
              <th className="text-start lt-grey"></th>
            </tr>
          </thead>
          <tbody>
            {meetings.length > 0 ? (
              meetings.map((item, index) => (
                <tr
                  key={`recent-meeting-${index}`}
                  className="tablerow"
                  onClick={() => handleClickMeeting(item.meetingId)}
                >
                  <td className="title">{item.summary}</td>
                  <td>{getFormattedDate(item.start)}</td>
                  <td>{getFormattedTimeRange(item.start, item.end)}</td>
                  {/* <td className="cost">
                    {isAdmin()
                      ? `${currency}${
                          item.durationHours *
                          compensation *
                          item.attendees.length
                        }`
                      : item.durationHours}
                  </td> */}
                  <td className="cost tw-text-sm">
                    {isAdmin() ? (
                      <div className="align-center text-accent-red">
                        <img src={iconDollar} alt="Dollar icon" />
                        <span>
                          {`${currency}${formatCurrency(
                            `${currency}${
                              item.durationHours *
                              compensation *
                              item.attendees.length
                            }`
                          )}`}
                        </span>
                      </div>
                    ) : (
                      item.durationHours
                    )}
                  </td>
                  <td className="text-end">
                    <img src={icArrowRight} />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tablerow">
                <td colSpan="6">
                  <p className="py-2 fs-5 text-cl-grey fw-bold text-center">
                    No Data Available
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <MeetingDetailModal
        open={showModal}
        handleClose={setShowModal}
        meetingId={meetingId}
      />
    </div>
  );
};

export default React.memo(UserRecentMeetings);

import { format, parseISO } from "date-fns";

export function getGMTOffset(timezone) {
  const options = {
    timeZone: timezone,
    timeZoneName: "longOffset",
  };

  const dateText = new Intl.DateTimeFormat([], options).format(new Date());
  const offsetString = dateText.split(" ")[1];
  const offsetHoursString = offsetString.slice(3) || "0";
  const offsetHours = parseInt(offsetHoursString.split(":")[0]);
  let offsetMinutes = 0;
  if (offsetHoursString.includes(":")) {
    offsetMinutes =
      offsetHours * 60 + parseInt(offsetHoursString.split(":")[1]);
  }

  return { gmtOffset: offsetString, offset: offsetMinutes };
}

export function getFormattedTimeRange(start, end) {
  if (typeof start !== "undefined" && typeof end !== "undefined") {
    const startDate = format(parseISO(start), "hh:mm");
    const endDate = format(parseISO(end), "hh:mm aaa");
    return `${startDate}-${endDate}`;
  }
  return "---";
}

export function getFormattedDate(date) {
  if (typeof date !== "undefined") {
    return format(parseISO(date), "MMM dd, yyyy");
  }
  return "---";
}

export function getThisWeek() {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - today.getDay());
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(today);
  endDate.setDate(today.getDate() + 6 - today.getDay());
  endDate.setHours(23, 59, 59, 999);
  return {
    startDate: startDate.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }),
    endDate: endDate.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }),
  };
}

export function getLastWeek() {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - today.getDay() - 7);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(today);
  endDate.setDate(today.getDate() - today.getDay() - 1);
  endDate.setHours(23, 59, 59, 999);

  return {
    startDate: startDate.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }),
    endDate: endDate.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }),
  };
}

export function getLastMonth() {
  const today = new Date();
  const startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  endDate.setHours(23, 59, 59, 999);
  return {
    startDate: startDate.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }),
    endDate: endDate.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }),
  };
}

export function getLastQuarter() {
  const today = new Date();
  const currentQuarter = Math.floor(today.getMonth() / 3);
  const startDate = new Date(today.getFullYear(), currentQuarter * 3 - 3, 1);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(today.getFullYear(), currentQuarter * 3, 0);
  endDate.setHours(23, 59, 59, 999);
  return {
    startDate: startDate.toLocaleDateString("en-us", {
      month: "short",
    }),
    endDate: endDate.toLocaleDateString("en-us", {
      month: "short",
    }),
  };
}

export function getLastSixMonths() {
  const today = new Date();
  const startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  endDate.setHours(23, 59, 59, 999);
  return {
    startDate: startDate.toLocaleDateString("en-us", {
      month: "short",
      year: "numeric",
    }),
    endDate: endDate.toLocaleDateString("en-us", {
      month: "short",
      year: "numeric",
    }),
  };
}

import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducer/app.slice";
import authReducer from "./reducer/authSlice";
import adminReducer from "./reducer/adminSlice";
import pieChartReducer from "./reducer/pieChartSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    adminDashboard: adminReducer,
    pieChartData: pieChartReducer,
  },
});

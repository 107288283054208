import { FilterOption, Goal, Unit } from "../constants";

import icGoalComplete from "../assets/icons/ic_goal-status-complete.png";
import icGoalAlmostDone from "../assets/icons/ic_goal-status-almost-done.png";
import icGoalInProgress from "../assets/icons/ic_goal-status-in-progress.png";
import { getEmail, isAdmin } from "./cookie";
import currencyJS from "currency.js";

export function isActivePath(path) {
  return window.location.pathname.includes(path);
}

export function mergeAndUpdate(target, source) {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (typeof source[key] === "object" && !Array.isArray(source[key])) {
        // If the property is an object, recursively call mergeAndUpdate
        target[key] = mergeAndUpdate({ ...target[key] }, source[key]);
      } else {
        // If the property is not an object, update it
        target[key] = source[key];
      }
    }
  }
  return target;
}

export function getFilterOptions(departments, users) {
  const filterOptions = [];
  // if (isAdmin()) {
  filterOptions.push({
    value: FilterOption.EVERYONE,
    label: capitalizeFirstLetter(FilterOption.EVERYONE),
    group: FilterOption.EVERYONE,
  });

  for (const department of departments) {
    filterOptions.push({
      value: department.id,
      label: capitalizeFirstLetter(department.name),
      group: FilterOption.DEPARTMENT,
    });
  }

  for (const user of users) {
    filterOptions.push({
      value: user.id,
      label: user.name || user.email,
      group: FilterOption.USER,
    });
  }
  // } else {
  //   const email = getEmail();
  //   const user = users.find((item) => item.email === email);
  //   if (user) {
  //     filterOptions.push({
  //       value: user.id,
  //       label: user.name || user.email,
  //       group: FilterOption.USER,
  //     });
  //   }
  // }
  return filterOptions;
}

export function getDepartmentOptions(departments) {
  const options = [];
  options.push({
    value: FilterOption.All,
    label: "All Departments",
  });
  for (const department of departments) {
    options.push({
      value: department.id,
      label: department.name,
    });
  }
  return options;
}

export function targetValueOptions() {
  const options = [];
  for (let i = 1; i <= 50; i++) {
    options.push({ value: i, label: i });
  }
  return options;
}

export function formattedLabel(value, unit) {
  if (unit === Unit.DOLLAR) {
    return `${unit}${formatCurrency(value)}`;
  }

  return `${value} ${unit}`;
}

export function firstLetter(value) {
  if (value) {
    return value[0].toUpperCase();
  }
  return "";
}

export function capitalizeFirstLetter(value) {
  if (typeof value !== "undefined" && value) {
    return value.replace(/^\w/, (c) => c.toUpperCase());
  }
  return "";
}

export function getGoalStatusIcon(status) {
  if (status > 99) {
    return icGoalComplete;
  } else if (status > 89) {
    return icGoalAlmostDone;
  }
  return icGoalInProgress;
}

export function getTargetName(item) {
  if (item.targetType === FilterOption.EVERYONE) {
    return capitalizeFirstLetter(FilterOption.EVERYONE);
  }

  return item.targetName;
}

export function getGoalStatus(value) {
  if (value >= 100) {
    return Goal.Status.COMPLETE;
  } else if (value >= 90) {
    return Goal.Status.ALMOST_DONE;
  }
  return Goal.Status.IN_PROGRESS;
}

export function convertToNumber(value) {
  let newValue = "";
  for (const c of value) {
    if (c >= "0" && c <= "9") {
      newValue += c;
    }
  }
  return newValue;
}

export function formatCurrency(value) {
  return currencyJS(value, {
    pattern: "#",
  }).format();
}

// helper function to prefix class name with tw-
export function tw(classNames) {
  return classNames
    .split(/\s+/)
    .map((c) => `tw-${c}`)
    .join(" ");
}

import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import {
  DashboardIcon,
  GoalsIcon,
  LogOutIcon,
  MeetingsIcon,
  MetricsIcon,
  ProfileIcon,
  SettingsIcon,
  UsersIcon,
} from "../../assets/icons/sidebarIcon";
import { toast } from "react-toastify";
import { Colors, Paths } from "../../constants";
import icArrowRight from "../../assets/icons/ic_arrow-right.svg";
import { firstLetter, isActivePath, isAdmin, removeToken } from "../../utils";
import { appDataSelector } from "../../redux/reducer";
import NewLogo from "../../assets/images/new-logo.png";
import LogoIcon from "../../assets/icons/logoIcon";
import Avatar from "../common/avatar";

function SideBar() {
  const navigate = useNavigate();

  const { userInfo } = useSelector(appDataSelector);

  const handleLogout = useCallback(() => {
    removeToken();
    navigate("/");
    toast.success("Logout Successful");
  }, [navigate]);

  return (
    <div className="app-sidebar flex-column">
      <div className="app-sidebar-logo px-6 header" id="kt_app_sidebar_logo">
        <Link to={isAdmin() ? "/dashboard" : "/dashboard"}>
          <LogoIcon />
          <img
            alt="Logo2"
            src="assets/media/logos/default-small.svg"
            className="h-20px app-sidebar-logo-minimize"
          />
        </Link>
      </div>
      <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
        <div className="app-sidebar-wrapper">
          <div className="my-3 mx-3">
            <div className="menu menu-column menu-rounded fw-semibold fs-6 gap-2">
              <div
                className={`menu-item ${
                  isActivePath(Paths.DASHBOARD) ? "active" : ""
                }`}
              >
                <Link
                  className="menu-link text-decoration-none d-flex align-items-center h-100 gap-1"
                  to={isAdmin() ? "/dashboard" : "/dashboard"}
                >
                  <DashboardIcon
                    color={
                      isActivePath(Paths.DASHBOARD)
                        ? Colors.Sidebar.MenuItemActive
                        : Colors.Sidebar.MenuItem
                    }
                  />
                  <span className="menu-title">Dashboard</span>
                </Link>
              </div>

              {isAdmin() && (
                <div
                  className={`menu-item ${
                    isActivePath(Paths.METRICS) ? "active" : ""
                  }`}
                >
                  <Link
                    className="menu-link text-decoration-none d-flex align-items-center h-100 gap-1"
                    to={isAdmin() ? "/metrics" : "/metrics"}
                  >
                    <MetricsIcon
                      color={
                        isActivePath(Paths.METRICS)
                          ? Colors.Sidebar.MenuItemActive
                          : Colors.Sidebar.MenuItem
                      }
                    />
                    <span className="menu-title">Metrics</span>
                  </Link>
                </div>
              )}

              <div
                className={`menu-item ${
                  isActivePath(Paths.GOALS) ? "active" : ""
                }`}
              >
                <Link
                  className="menu-link text-decoration-none d-flex align-items-center h-100 gap-1"
                  to={isAdmin() ? "/goals" : "/goals"}
                >
                  <GoalsIcon
                    color={
                      isActivePath(Paths.GOALS)
                        ? Colors.Sidebar.MenuItemActive
                        : Colors.Sidebar.MenuItem
                    }
                  />
                  <span className="menu-title">Goals</span>
                </Link>
              </div>

              <div
                className={`menu-item ${
                  isActivePath(Paths.MEETINGS) ? "active" : ""
                }`}
              >
                <Link
                  className="menu-link text-decoration-none d-flex align-items-center h-100 gap-1"
                  to={isAdmin() ? "/meetings" : "/meetings"}
                >
                  <MeetingsIcon
                    color={
                      isActivePath(Paths.MEETINGS)
                        ? Colors.Sidebar.MenuItemActive
                        : Colors.Sidebar.MenuItem
                    }
                  />
                  <span className="menu-title">Meetings</span>
                </Link>
              </div>
              {isAdmin() && (
                <div
                  className={`menu-item ${
                    isActivePath(Paths.USERS) ? "active" : ""
                  }`}
                >
                  <Link
                    className="menu-link text-decoration-none d-flex align-items-center h-100 gap-1"
                    to="/users"
                  >
                    <UsersIcon
                      color={
                        isActivePath(Paths.USERS)
                          ? Colors.Sidebar.MenuItemActive
                          : Colors.Sidebar.MenuItem
                      }
                    />
                    <span className="menu-title">Users</span>
                  </Link>
                </div>
              )}
              <div
                className={`menu-item ${
                  isActivePath("/my-profile") ? "active" : ""
                }`}
              >
                <Link
                  className="menu-link text-decoration-none d-flex align-items-center h-100 gap-1"
                  to={`/my-profile`}
                >
                  <ProfileIcon
                    color={
                      isActivePath("/my-profile")
                        ? Colors.Sidebar.MenuItemActive
                        : Colors.Sidebar.MenuItem
                    }
                  />
                  <span className="menu-title">My Profile</span>
                </Link>
              </div>
              {/* user links end  */}
            </div>
          </div>
        </div>
      </div>

      <div className="app-sidebar-footer  flex-column-auto pt-2 pb-4 px-6">
        <div className="p-0 w-100">
          <Popup
            trigger={
              <div className="menu-link text-decoration-none d-flex align-items-center">
                <div className="d-flex align-items-center menu-div text-decoration-none p-0">
                  <div className="d-flex align-items-center rounded-4 p-3 gap-2 cursor-pointer">
                    <Avatar
                      userName={userInfo?.name}
                      color={userInfo?.avatarColor}
                    />
                    <div className="content d-flex align-items-center flex-fill">
                      <div>
                        <div className="fw-bold">
                          {userInfo?.name} {isAdmin() && "(admin)"}
                        </div>
                        <div className="text-disabled">{userInfo?.email}</div>
                      </div>
                    </div>
                  </div>
                  <img src={icArrowRight} />
                </div>
              </div>
            }
            position="right bottom"
            arrow={false}
          >
            <div>
              {isAdmin() && (
                <div className="menu-item">
                  <Link
                    className="menu-link text-decoration-none d-flex align-items-center"
                    to="/settings"
                  >
                    <span className="menu-bullet d-flex ">
                      <SettingsIcon />
                    </span>
                    <span className="menu-title">Settings</span>
                  </Link>
                </div>
              )}
              <div className="menu-item" onClick={handleLogout}>
                <div className="menu-link">
                  <span className="menu-bullet">
                    <LogOutIcon />
                  </span>
                  <span className="menu-title">Log out</span>
                </div>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </div>
  );
}
export default React.memo(SideBar);

import axios from "axios";
export const getRequest = async (URL, token) => {
  try {
    const response = await axios.get(URL, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

import { Colors } from "../../constants";

export const UserIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7083 6.66683C12.7083 8.1626 11.4957 9.37516 9.99998 9.37516C8.50421 9.37516 7.29164 8.1626 7.29164 6.66683C7.29164 5.17106 8.50421 3.9585 9.99998 3.9585C11.4957 3.9585 12.7083 5.17106 12.7083 6.66683Z"
      stroke={color ?? Colors.Icon.DEFAULT}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.70623 16.0418H14.2937C15.2453 16.0418 15.9783 15.2236 15.5339 14.3821C14.8803 13.1444 13.39 11.6668 9.99998 11.6668C6.60999 11.6668 5.1197 13.1444 4.46601 14.3821C4.02162 15.2236 4.75463 16.0418 5.70623 16.0418Z"
      stroke={color ?? Colors.Icon.DEFAULT}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

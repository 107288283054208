import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Header from "../../component/common/header";
import SideBar from "../../component/sidebar/SideBar";

import TotalChart from "../../component/dashboard/TotalChart";
import TimesInMeetings from "../../component/dashboard/TimesInMeetings";
import TimePieChart from "../../component/dashboard/TimePieChart";
import MetWithMost from "../../component/dashboard/MetWithMost";

import { getDashboardData } from "../../services";
import { DateRange, HEADER } from "../../constants";
import OnboardingModal from "../../component/dashboard/OnboardingModal";
import { appDataSelector } from "../../redux/reducer";
import { GoalList } from "../../component/goals";
import UpgradeModal from "../../component/modal/UpgradeModal";

function Dashboard() {
  const { userInfo, dateRange, compensation, currency } =
    useSelector(appDataSelector);

  const [loading, setLoading] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [upgradeModalQuantity, setUpgradeModalQuantity] = useState(null);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const [totalTime, setTotalTime] = useState(0);
  const [avgTime, setAvgTime] = useState(0);
  const [barChartData, setBarChartData] = useState();
  const [pieChartData1, setPieChartData1] = useState({});
  const [pieChartData2, setPieChartData2] = useState({});
  const [metWithMost, setMetWithMost] = useState([]);
  const [goals, setGoals] = useState([]);

  const loadDashboardData = async (dateRange) => {
    setLoading(true);
    const params = { dateRange: dateRange || DateRange.LAST_WEEK };
    const res = await getDashboardData(params);
    if (res && res.success) {
      const data = res.data;
      setTotalTime(data.totalTime);
      setBarChartData(data.chartData.barChartData);
      setPieChartData1(data.chartData.pieChartData1);
      setPieChartData2(data.chartData.pieChartData2);
      setMetWithMost(data.metWithMost);
      setAvgTime(data.avgTime * 60);

      if (res.data.goals) {
        setGoals(res.data.goals);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (dateRange) {
      loadDashboardData(dateRange.value);
    }
  }, [dateRange]);

  useEffect(() => {
    if (userInfo) {
      setShowOnboardingModal(!userInfo.isRegister);
    }
  }, [userInfo]);

  return (
    <div
      className="d-flex vh-min-100 bg-blue-lt dashboard"
      data-kt-app-sidebar-fixed="true"
    >
      <div className="d-flex flex-column flex-root app-root">
        <div className="app-page flex-column flex-column-fluid">
          <div className="app-wrapper flex-column flex-row-fluid">
            <SideBar />
            <div className="app-main flex-column flex-row-fluid bg-white">
              <Header heading={HEADER.Title.Dashboard} />
              <div className="app-content flex-column-fluid content-wrapper">
                <div className="pb-8 d-flex">
                  <div className="left-box">
                    <TotalChart
                      loading={loading}
                      totalTime={totalTime}
                      chartData={barChartData}
                    />
                    <TimesInMeetings totalTime={totalTime} avgTime={avgTime} />
                    <TimePieChart
                      loading={loading}
                      chartData1={pieChartData1}
                      chartData2={pieChartData2}
                    />
                  </div>
                  <div className="right-box">
                    <GoalList goals={goals} />
                    <MetWithMost
                      data={metWithMost}
                      compensation={compensation}
                      currency={currency}
                    />
                  </div>
                </div>
                {showOnboardingModal && (
                  <OnboardingModal
                    handleClose={() => setShowOnboardingModal(false)}
                    reload={loadDashboardData}
                    onOpenUpgradeModal={(quantity) => {
                      setUpgradeModalQuantity(quantity);
                      setOpenUpgradeModal(true);
                    }}
                  />
                )}
                <UpgradeModal
                  open={openUpgradeModal}
                  onClose={() => setOpenUpgradeModal(false)}
                  quantity={upgradeModalQuantity}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;

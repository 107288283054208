import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../component/common/header";
import SideBar from "../../component/sidebar/SideBar";
import { getGoalsData } from "../../services";
import { HEADER } from "../../constants";
import { appDataSelector } from "../../redux/reducer";
import {
  AddEditGoalModal,
  GoalSuccessRateChart,
  GoalTable,
} from "../../component/goals";

function Goals() {
  const { meetingType, dateRange, users, departments } =
    useSelector(appDataSelector);

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [chartData, setChartData] = useState();
  const [activeGoals, setActiveGoals] = useState([]);
  const [archivedGoals, setArchivedGoals] = useState([]);
  const [successRate, setSuccessRate] = useState(0);

  const loadGoals = useCallback(async () => {
    if (meetingType && dateRange) {
      setLoading(true);

      const params = {
        dateRange: dateRange.value,
        filterOption: meetingType.group,
        filterValue: meetingType.value,
      };
      const res = await getGoalsData(params);
      if (res) {
        const data = res.data;
        setChartData(data.chartData);
        setActiveGoals(data.activeGoals);
        setArchivedGoals(data.archivedGoals);
        setSuccessRate(data.successRate);
      }

      setLoading(false);
    }
  }, [dateRange, meetingType]);

  useEffect(() => {
    loadGoals();
  }, [meetingType, dateRange, loadGoals]);

  return (
    <div
      className="d-flex vh-min-100 bg-blue-lt goals"
      data-kt-app-sidebar-fixed="true"
    >
      <div className="d-flex flex-column flex-root app-root">
        <div className="app-page flex-column flex-column-fluid">
          <div className="app-wrapper flex-column flex-row-fluid">
            <SideBar />
            <div className="app-main flex-column flex-row-fluid">
              <Header
                heading={HEADER.Title.Goals}
                handleAddGoal={() => setShowModal(true)}
              />
              <div className="app-content flex-column-fluid content-wrapper">
                {showModal && (
                  <AddEditGoalModal
                    reload={loadGoals}
                    users={users}
                    departments={departments}
                    handleClose={() => setShowModal(false)}
                  />
                )}
                {/* <GoalSuccessRateChart
                  loading={loading}
                  chartData={chartData}
                  successRate={successRate}
                /> */}
                {/* <div className="divider" /> */}
                <GoalTable
                  reload={loadGoals}
                  activeGoals={activeGoals}
                  archivedGoals={archivedGoals}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Goals;

import axios from "axios";
import { appConfig } from "../constants";
import { getHeaders } from "../utils";

export async function saveOnboardingData(data) {
  try {
    const url = `${appConfig.apiBaseUrl}/onboarding`;
    const headers = getHeaders();
    const res = await axios.post(url, data, { headers });
    return res.data;
  } catch (err) {
    console.log(err);
  }
  return null;
}

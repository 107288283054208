export const CalenderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14 6.66665H2M10.6667 1.33331V3.99998M5.33333 1.33331V3.99998M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4487C13.2843 14.2569 13.5903 13.951 13.782 13.5746C14 13.1468 14 12.5868 14 11.4666V5.86665C14 4.74654 14 4.18649 13.782 3.75867C13.5903 3.38234 13.2843 3.07638 12.908 2.88463C12.4802 2.66665 11.9201 2.66665 10.8 2.66665H5.2C4.0799 2.66665 3.51984 2.66665 3.09202 2.88463C2.71569 3.07638 2.40973 3.38234 2.21799 3.75867C2 4.18649 2 4.74654 2 5.86665V11.4666C2 12.5868 2 13.1468 2.21799 13.5746C2.40973 13.951 2.71569 14.2569 3.09202 14.4487C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
      stroke="#3F4254"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_1393_6379)">
      <path
        d="M7.99967 3.99998V7.99998L10.6663 9.33331M14.6663 7.99998C14.6663 11.6819 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6819 1.33301 7.99998C1.33301 4.31808 4.31778 1.33331 7.99967 1.33331C11.6816 1.33331 14.6663 4.31808 14.6663 7.99998Z"
        stroke="#3F4254"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1393_6379">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DollarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4 10.6666C4 12.1394 5.19391 13.3333 6.66667 13.3333H9.33333C10.8061 13.3333 12 12.1394 12 10.6666C12 9.19389 10.8061 7.99998 9.33333 7.99998H6.66667C5.19391 7.99998 4 6.80607 4 5.33331C4 3.86055 5.19391 2.66665 6.66667 2.66665H9.33333C10.8061 2.66665 12 3.86055 12 5.33331M8 1.33331V14.6666"
      stroke="#3F4254"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const WebIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <rect width="64" height="64" rx="32" fill="#EBF1F4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8945 18.7834V25.5284H23.6082V18.7834H16.8945Z"
      fill="#C8DBFD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.3922 45.7832H47.1058V39.0383H40.3922H33.6785H26.9648V45.7832H33.6785H40.3922Z"
      fill="#216DF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6082 39.0383H16.8945V45.7832H23.6082V39.0383Z"
      fill="#C8DBFD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6082 28.9211H16.8945V35.6661H23.6082H30.3219H37.0355V28.9211H30.3219H23.6082Z"
      fill="#216DF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.1062 28.9211H40.3926V35.6661H47.1062V28.9211Z"
      fill="#D4DBEB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.3922 25.5284H47.1058V18.7834H40.3922H33.6785H26.9648V25.5284H33.6785H40.3922Z"
      fill="#216DF4"
    />
  </svg>
);
export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M18.5645 6.53516L6.56445 18.5352M6.56445 6.53516L18.5645 18.5352"
      stroke="#3F4254"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

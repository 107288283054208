import React from "react";
import Loader from "../common/loader";

export default function ConfirmDeleteUserModal({
  onClose,
  onConfirm,
  user,
  loading,
}) {
  return (
    <div className="pop-wrapper user-update-modal">
      <div className="inner-wrapper wrapper">
        <div className="head p-24">
          <h3 className="title">Delete user</h3>
        </div>
        <div className="pop-content value-form px-24">
          <div>Are you sure want to delete {user.name} profile?</div>
        </div>
        <div className="footer-pop p-24 d-flex justify-content-end">
          <button className="btn grey-button fw-bold me-3" onClick={onClose}>
            Cancel
          </button>
          <button
            disabled={loading}
            className="btn-primary tc-white fw-700 d-flex"
            onClick={onConfirm}
          >
            Confirm {loading && <Loader />}
          </button>
        </div>
      </div>
    </div>
  );
}

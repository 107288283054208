import { appConfig } from "../../constants";
import {
  getAdminData,
  getAdminDashboardFailure,
  getMeetingListSuccess,
  getUserMeetingListSuccess,
  getMeetingDetailsSuccess,
  getMetricsSuccess,
  getAdminUserListSuccess,
  getAdminUserDetailSuccess,
  getMetricsMostParticipantsSuccess,
  getMetricsMostMeetingsSuccess,
  getMetricsReccuringMostParticipantsSuccess,
  getAdminUserSummarySuccess,
  getAdminUserMostMeetingsSuccess,
  getAdminUserRecentMeetingsSuccess,
  getAdminUserPeopleMeetMostSuccess,
  getAdminWebhookListSuccess,
} from "../reducer/adminSlice";

import { getRequest } from "./request";

export function adminMeetingList(option2Value, search, token, mode, pageCount) {
  return async (dispatch) => {
    dispatch(getAdminData());
    //dispatch(getMeetingListSuccess({ loading: true }))
    await getRequest(
      `${appConfig.apiBaseUrl}/meetings/list/?filter=${option2Value}&search=${search}&sortOrder&page=${pageCount}&pageSize=10&mode=${mode}`,
      token
    )
      .then((res) => dispatch(getMeetingListSuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
  };
}

export function userMeetingList(option2Value, search, token, mode, pageCount) {
  return async (dispatch) => {
    //dispatch(getUserMeetingListSuccess({ loading: true }))
    await getRequest(
      `${appConfig.apiBaseUrl}/meetings/list/?filter=${option2Value}&search=${search}&page=${pageCount}&pageSize=10&mode=personal`,
      token
    )
      .then((res) => dispatch(getUserMeetingListSuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
  };
}

export function adminMeetingDetails(id, token, mode) {
  return async (dispatch) => {
    dispatch(getAdminData());
    //dispatch(getMeetingDetailsSuccess({ loading: true }))
    await getRequest(
      `${appConfig.apiBaseUrl}/meetings/${id}?mode=${mode}`,
      token
    )
      .then((res) => dispatch(getMeetingDetailsSuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
  };
}

export function adminMetrics(option3Value, option2Value, token, mode) {
  return async (dispatch) => {
    dispatch(getAdminData());
    dispatch(getMetricsSuccess({ loading: true }));
    dispatch(getMetricsMostParticipantsSuccess({ loading: true }));
    dispatch(getMetricsMostMeetingsSuccess({ loading: true }));
    dispatch(getMetricsReccuringMostParticipantsSuccess({ loading: true }));

    await getRequest(
      `${appConfig.apiBaseUrl}/matrics?filter=${option2Value}&dataType=${option3Value}&mode=${mode}`,
      token
    )
      .then((res) => dispatch(getMetricsSuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));

    await getRequest(
      `${appConfig.apiBaseUrl}/matrics/most-participant?filter=${option2Value}&dataType=${option3Value}&mode=${mode}`,
      token
    )
      .then((res) =>
        dispatch(
          getMetricsMostParticipantsSuccess({
            matrixMostParticipants: res.data,
          })
        )
      )
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
    await getRequest(
      `${appConfig.apiBaseUrl}/matrics/users-most-meetings?filter=${option2Value}&dataType=${option3Value}&mode=${mode}`,
      token
    )
      .then((res) =>
        dispatch(
          getMetricsMostMeetingsSuccess({ matrixMostMeetings: res.data })
        )
      )
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
    await getRequest(
      `${appConfig.apiBaseUrl}/matrics/recurring-most-participant?filter=${option2Value}&dataType=${option3Value}&mode=${mode}`,
      token
    )
      .then((res) =>
        dispatch(
          getMetricsReccuringMostParticipantsSuccess({
            matrixRecurringMostParticipants: res.data,
          })
        )
      )
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
  };
}

export function adminUserListData(search, token, mode, pageCount) {
  return async (dispatch) => {
    dispatch(getAdminData());
    //dispatch(getAdminUserListSuccess({ loading: true }))

    await getRequest(
      `${appConfig.apiBaseUrl}/users?page=${pageCount}&pageSize=10&search=${search}&mode=${mode}`,
      token
    )
      .then((res) => dispatch(getAdminUserListSuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
  };
}

export function adminUserDetailData(id, token, option2Value) {
  return async (dispatch) => {
    dispatch(getAdminData());
    dispatch(getAdminUserDetailSuccess({ loading: true }));
    dispatch(getAdminUserSummarySuccess({ loading: true }));
    dispatch(getAdminUserMostMeetingsSuccess({ loading: true }));
    dispatch(getAdminUserPeopleMeetMostSuccess({ loading: true }));
    dispatch(getAdminUserRecentMeetingsSuccess({ loading: true }));

    await getRequest(
      `${appConfig.apiBaseUrl}/users/getUserDetails?id=${id}`,
      token
    )
      .then((res) =>
        dispatch(getAdminUserDetailSuccess({ adminUserDetails: res.data }))
      )
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
    await getRequest(
      `${appConfig.apiBaseUrl}/profile/summary?filter=${option2Value}&user_id=${id}`,
      token
    )
      .then((res) => dispatch(getAdminUserSummarySuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
    await getRequest(
      `${appConfig.apiBaseUrl}/profile/most-costly-meeting?filter=${option2Value}&user_id=${id}`,
      token
    )
      .then((res) => dispatch(getAdminUserMostMeetingsSuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
    await getRequest(
      `${appConfig.apiBaseUrl}/profile/people-met-most?filter=${option2Value}&user_id=${id}`,
      token
    )
      .then((res) => dispatch(getAdminUserPeopleMeetMostSuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
    await getRequest(
      `${appConfig.apiBaseUrl}/profile/recent-meetings?filter=${option2Value}&user_id=${id}`,
      token
    )
      .then((res) => dispatch(getAdminUserRecentMeetingsSuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
  };
}

export function adminWebhookListData(search, token, mode, pageCount) {
  return async (dispatch) => {
    dispatch(getAdminData());

    await getRequest(
      `${appConfig.apiBaseUrl}/webhooks?page=${pageCount}&pageSize=10&search=${search}&mode=${mode}`,
      token
    )
      .then((res) => dispatch(getAdminWebhookListSuccess(res.data)))
      .catch((err) => dispatch(getAdminDashboardFailure(err.message)));
  };
}

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../common/inputField";
import { EventTypes, Platforms, appConfig } from "../../constants";
import SelectField from "../common/selectField";
import Loader from "../common/loader";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

export default function AddEditWebhookUrlModal({
  reload,
  handleClose,
  webhook,
}) {
  const token = Cookies.get("tokens");

  const [loading, setLoading] = useState(false);

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);

    if (webhook) {
      axios
        .put(`${appConfig.apiBaseUrl}/webhooks/${webhook._id}`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);

            reload();
            handleClose();
          } else {
            toast.error(res.data.message);
          }

          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setLoading(false);
        });
    } else {
      axios
        .post(`${appConfig.apiBaseUrl}/webhooks`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);

            reload();
            handleClose();
          } else {
            toast.error(res.data.message);
          }

          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (webhook) {
      setValue("url", webhook.url);
      setValue("eventType", webhook.eventType);
      setValue("platform", webhook.platform);
    } else {
      reset();
    }
  }, [webhook]);

  return (
    <div className="pop-wrapper">
      <form className="inner-wrapper" onSubmit={handleSubmit(onSubmit)}>
        <div className="head p-24 border-bottom">
          <h3 className="fw-bold">{webhook ? "Edit" : "Add"} webhook</h3>
        </div>
        <div className="pop-content value-form p-24 py-3">
          <div className="align-items-center gap-2 mb-4">
            <InputField
              className="mt-4"
              name="url"
              label="Webhook url"
              placeholder="Webhook url"
              type="text"
              {...register("url", { required: true })}
              error={errors.url}
            />

            <SelectField
              className="mt-4"
              name="eventType"
              label="Event type"
              placeholder="Event type"
              control={control}
              options={EventTypes}
              error={errors.eventType}
            />
            <SelectField
              className="mt-4"
              name="platform"
              label="Platform"
              placeholder="Platform"
              control={control}
              options={Platforms}
              error={errors.eventType}
            />
            {/* {platform && PlatformFields[platform]?.map((item, index) => (
              <InputField
                key={index}
                className="mt-4"
                name={item.field}
                label={item.label}
                placeholder={item.label}
                type="text"
                {...register(item.field, { required: true })}
                error={errors.webhookUrl}
              />
            ))} */}
          </div>
        </div>
        <div className="footer-pop p-24 border-top d-flex justify-content-center">
          <button
            className="btn grey-button fw-bold me-3"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn primary-button tc-white fw-bold"
            type="submit"
            onClick={() => handleSubmit()}
          >
            {webhook ? "Save" : "Add"} {loading && <Loader />}
          </button>
        </div>
      </form>
    </div>
  );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimezoneSelect from "react-timezone-select";

import Loader from "../common/loader";
import { convertToNumber, isAdmin } from "../../utils";
import { updateUser } from "../../services";
import { appDataSelector, updateCompensation } from "../../redux/reducer";

export default function UserEditModal({
  user,
  departments,
  handleClose,
  reload,
}) {
  const dispatch = useDispatch();

  const { compensation } = useSelector(appDataSelector);

  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState(user.name);
  const [editableCompensation, setCompensation] = useState(
    user.compensation || compensation
  );
  const [department, setDepartment] = useState(user.departmentId);
  const [timezone, setTimezone] = useState(
    user.timezone
      ? user.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const handleSave = async () => {
    const params = {
      name: username,
      department,
      timezone: typeof timezone === "string" ? timezone : timezone.value,
    };
    if (isAdmin()) {
      params["compensation"] = editableCompensation;
    }

    setLoading(true);
    const res = await updateUser(user.id, params);
    if (res && res.success) {
      dispatch(updateCompensation(editableCompensation));
      reload();
      handleClose();
    }
    setLoading(false);
  };

  return (
    <div className="pop-wrapper user-update-modal">
      <div className="inner-wrapper wrapper">
        <div className="head p-24">
          <h3 className="title">Edit a user</h3>
        </div>
        <div className="pop-content value-form px-24">
          <div className="input-wrap">
            <div>Name</div>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          {isAdmin() && (
            <div className="compensation-wrap mt-6">
              <div>Compensation</div>
              <div className="compensation-info mt-2">
                <span className="text-blue-gradient compensation-currency">
                  $
                </span>
                <input
                  className="text-blue-gradient compensation-value"
                  type="text"
                  size={
                    editableCompensation.length > 0
                      ? editableCompensation.length
                      : 3
                  }
                  value={editableCompensation}
                  onChange={(e) =>
                    setCompensation(convertToNumber(e.target.value))
                  }
                />
              </div>
            </div>
          )}
          <div className="mt-6">
            <div>Department</div>
            <div className="tag-list mt-2">
              {departments.map((item, index) => (
                <div
                  key={`department-item-${index}`}
                  className={`tag-list-item cursor-pointer ${
                    item.id === department ? "active" : ""
                  }`}
                  onClick={() => setDepartment(item.id)}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <div className="text-input-wrap mt-6">
            <div>Time zone</div>
            <TimezoneSelect
              className="timezone-select"
              name="timezone"
              value={timezone}
              onChange={setTimezone}
            />
          </div>
        </div>
        <div className="footer-pop p-24 d-flex justify-content-end">
          <button
            className="btn grey-button fw-bold me-3"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn-primary tc-white fw-700 d-flex"
            onClick={() => handleSave()}
          >
            Save {loading && <Loader />}
          </button>
        </div>
      </div>
    </div>
  );
}

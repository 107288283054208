import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useMemo, useState } from "react";
import jwt_decode from "jwt-decode";
import UpdateUserSeatsModal from "./UpdateUserSeatsModal";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import icVisa from "../../assets/icons/ic_visa.svg";
import { CalendarIcon, UserIcon } from "../icons";
import { Colors, Subscription } from "../../constants";
import { checkout, editCard, updateSeats } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { appDataSelector, updateNumOfUsers } from "../../redux/reducer";
import { saveSettings } from "../../services";
import UpgradeModal from "../modal/UpgradeModal";
import moment from "moment/moment";
import { formatCurrency } from "../../utils/common";

export default function BillingSettings({
  transactions,
  settings,
  latestTransactions,
  currentSubscription,
  maximum_limit,
  paginationData,
  setPageCount,
  setReloadData,
}) {
  const dispatch = useDispatch();

  const { currency, numOfUsers } = useSelector(appDataSelector);

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [seatsQuantity, setSeatsQuantity] = useState(0);
  const [userData, setUserData] = useState(null);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

  const billingInfoQuery = useQuery({
    queryKey: ["billingInfo"],
    queryFn: async () => {
      const token = Cookies.get("tokens");
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/user/billing-info`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return data.data;
    },
  });

  useEffect(() => {
    const token = Cookies.get("tokens");
    if (token) {
      (async () => {
        const data = await jwt_decode(token);
        setUserData(data);
      })();
    }
  }, []);

  async function handleSubmit(seats) {
    // if (seats > 3) {
    //   const params = {
    //     lookup_keys: ["calendyze_per_seat_test"],
    //     customer_email: "braiden@calendyze.com", //tokenDecoded.email,
    //     quantity: seats,
    //     req_from_page: "settings",
    //   };
    //   const res = await checkout(params);
    //   if (res.error) {
    //     toast.error(res.error.response.data.message);
    //   } else {
    //     window.location.href = res.url;
    //   }
    // }
    try {
      const settingInfo = {
        numOfUsers: seats,
      };
      const res = await updateSeats(settingInfo);
      if (res.success) {
        dispatch(updateNumOfUsers(seats));
        setShowModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleEditCard() {
    const params = {
      subscription_id: currentSubscription?.subscription_id,
      customer_id: settings?.customer_id,
    };

    const res = await editCard(params);
    const url = res.url;
    window.location.href = url;
  }

  const billingHistory = useMemo(() => {
    if (billingInfoQuery.isSuccess && billingInfoQuery.data.billing_history) {
      return billingInfoQuery.data.billing_history;
    }

    return [];
  }, [billingInfoQuery.data, billingInfoQuery.isSuccess]);

  const paymentMethod = billingInfoQuery.data?.payment_method;

  return (
    <div className="billing">
      {showModal && (
        <UpdateUserSeatsModal
          numOfUsers={numOfUsers}
          handleSubmit={handleSubmit}
          handleClose={() => setShowModal(false)}
          setSeatsQuantity={setSeatsQuantity}
          seatsQuantity={seatsQuantity}
          setError={setError}
          setErrorMessage={setErrorMessage}
          error={error}
          errorMessage={errorMessage}
        />
      )}
      <div className="billing-detail mt-6">
        {billingInfoQuery.isSuccess && billingInfoQuery.data.subscription && (
          <div className="billing-detail-item">
            <div className="title">Next Invoice Date</div>
            <div className="value text-blue-gradient">
              {moment(
                billingInfoQuery.data.subscription.next_invoice_date
              ).format("MMM Do, YYYY")}
            </div>
          </div>
        )}
        {/* <div
          className="billing-detail-item cursor-pointer"
          onClick={() => setShowModal(true)}
        >
          <div className="title">Seats Purchased</div>
          <div className="value text-blue-gradient">{numOfUsers} seats</div>
        </div> */}
        {billingInfoQuery.isSuccess && (
          <div className="billing-detail-item">
            <div className="title">Subscription Plan</div>
            <div className="tw-flex tw-items-center tw-gap-2">
              <span className="value text-blue-gradient">
                {billingInfoQuery.data.subscription_plan}
              </span>
              {billingInfoQuery.isSuccess &&
                userData &&
                userData.isAdmin &&
                !billingInfoQuery.data.subscription && (
                  <button
                    type="button"
                    className="btn-primary"
                    onClick={() => setOpenUpgradeModal(true)}
                  >
                    Upgrade
                  </button>
                )}
            </div>
          </div>
        )}
        {billingInfoQuery.isSuccess && billingInfoQuery.data.subscription && (
          <div className="billing-detail-item">
            <div className="title">Monthly Total</div>
            <div className="value text-blue-gradient">
              $
              {formatCurrency(billingInfoQuery.data.subscription.monthly_total)}
            </div>
          </div>
        )}
      </div>
      <div className="billing-history mt-6">
        <div className="billing-history-table data-tables">
          <div className="table-responsive pagination">
            <table className="table align-middle gs-0 gy-3 my-0 ">
              <thead>
                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                  <th className="text-start lt-grey">Date</th>
                  <th className="text-start lt-grey">Amount Due</th>
                  <th className="text-start lt-grey">Amount Paid</th>
                  <th className="text-start lt-grey">Invoice Url</th>
                </tr>
              </thead>
              <tbody>
                {billingHistory.length > 0 ? (
                  billingHistory.map((item, index) => (
                    <tr key={index} className="tablerow">
                      <td className="title-date">
                        {moment(item.date_created).format("MMM Do, YYYY")}
                      </td>
                      <td>${formatCurrency(item.amount_due)}</td>
                      <td>${formatCurrency(item.amount_paid)}</td>
                      <td>
                        <a
                          href={item.hosted_invoice_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.invoice_number}
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="tablerow">
                    <td colSpan="6">
                      <p className="py-2 fs-5 text-cl-grey fw-bold text-center">
                        No History Data
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {paymentMethod && (
          <div className="payment-detail">
            <div className="payment-detail-title">Payment Details</div>
            <div className="payment-detail-info">
              <img src={icVisa} />
              <div className="payment-info">
                <div>•••• {paymentMethod.last4}</div>
                <div className="sub-info">
                  <UserIcon color={Colors.Text.Accent.GRAY} />
                  <span className="me-4">{paymentMethod.name_on_card}</span>
                  <CalendarIcon color={Colors.Text.Accent.GRAY} />
                  <span>
                    {paymentMethod.exp_month}/{paymentMethod.exp_year}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {innerData ? (
        <div className="billing-setting-inner ">
          <div className="d-flex gap-4">
            <div className="bg-white  rounded-3 w-100 p-4 d-flex flex-column align-items-center justify-conetent-center">
              <h4 className="text-cl-grey fw-bold mb-2">
                {currentSubscription?.cancelled
                  ? "Cancellation Date"
                  : "Next Invoice Date"}
              </h4>
              <p className="fs-24 lt-grey fw-bold mb-2">
                {currentSubscription?.end_date &&
                  format(
                    new Date(currentSubscription?.end_date),
                    "MMM dd, yyyy"
                  )}
              </p>
            </div>
            <div className="bg-white  rounded-3 w-100 p-4 d-flex flex-column align-items-center justify-conetent-center">
              <h4 className="text-cl-grey fw-bold mb-2">
                {currentSubscription?.cancelled
                  ? "Current Seats"
                  : "Seats Purchased"}
              </h4>
              <p className="fs-24 lt-grey fw-bold mb-2">
                {settings?.total_seats}
              </p>
            </div>
            {currentSubscription?.cancelled ? null : (
              <div className="bg-white  rounded-3 w-100 p-4 d-flex flex-column align-items-center justify-conetent-center">
                <h4 className="text-cl-grey fw-bold mb-2">Monthly Total</h4>
                <p className="fs-24 lt-grey fw-bold mb-2">
                  ${settings?.totalCost}
                </p>
                <button
                  type="button"
                  className="btn border-0 p-0 fs-14 fw-bold text-blue"
                  onClick={() => {
                    setcostBreakDown(true);
                  }}
                >
                  See cost breakdown
                </button>
              </div>
            )}
            <div className="bg-white  rounded-3 w-100 p-4 d-flex flex-column align-items-center justify-conetent-center">
              <h4 className="text-cl-grey fw-bold mb-2">Card Details</h4>
              <p className="fs-14 lt-grey fw-semibold mb-0">
                {latestTransactions?.card_name}
              </p>
              <p className="fs-14 lt-grey fw-semibold mb-0">
                xxxx xxxx xxxx {latestTransactions?.card_last4}
              </p>
              <p className="fs-14 lt-grey fw-semibold mb-0">
                Card expries at {latestTransactions?.card_exp_month}/
                {latestTransactions?.card_exp_year}
              </p>
              <button
                type="button"
                className="btn border-0 p-0 fs-14 fw-bold text-blue"
                onClick={() => {
                  handleEditCard();
                }}
              >
                Edit
              </button>
            </div>
          </div>
          <BillingHistory
            transactions={transactions}
            currentSubscription={currentSubscription}
            paginationData={paginationData}
            setPageCount={setPageCount}
            settings={settings}
            setReloadData={setReloadData}
            maximum_limit={maximum_limit}
          />
        </div>
      ) : (
        <div className="billing-settings p-4 bg-white  rounded-3">
          <LogoIcon />
          <h2 className="mt-4 fw-bold">
            Real time costs in-calendar with more data and metrics to help your
            team thrive
          </h2>
          <h3 className="text-cl-grey fw-bold">$3 user / month</h3>
          <p className="lt-grey fw-semibold fs-14 mt-4">
            You'll enjoy all the benefits of our Basic plan along with
            additional premium features.
          </p>
          <ul className="p-0 mx-0 d-flex flex-column cost-plugin">
            <li>In calendar costs plugin</li>
            <li>Advanced Company Metrics</li>
            <li>Advanced User Metrics</li>
            <li>Calendar Meetings of last six months</li>
          </ul>
          <button
            type="button"
            className="btn primary-button tc-white fw-bold cursor-pointer"
            onClick={() => setPopUp(true)}
          >
            Upgrade to Pro
          </button>
        </div>
      )} */}
      {/* {costBreakDown && (
        <CostBreakDown
          handleClose={handleClose}
          totalLicenses={settings?.total_seats}
          totalCost={`${settings?.totalCost}`}
        />
      )} */}
      {/* {editUser && <EditUser handleClose={handleClose} />} */}
      <UpgradeModal
        open={openUpgradeModal}
        onClose={() => setOpenUpgradeModal(false)}
      />
    </div>
  );
}

import { BehaviorSubject } from "rxjs";
import axios from "axios";

class SubscriptionService {
  constructor() {
    this.subscriptionRequired = new BehaviorSubject(false);
  }

  initAxiosResponseInterceptor() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response?.status === 402) {
          this.subscriptionRequired.next(true);
        }
        return Promise.reject(error);
      }
    );
  }
}

const subscriptionService = new SubscriptionService();
subscriptionService.initAxiosResponseInterceptor();

export { subscriptionService };

import { createContext, useContext, useEffect, useState } from "react";
import UpgradeModal from "../component/modal/UpgradeModal";
import { subscriptionService } from "../services/subscription.service";

const defaultValue = {
  subscriptionRequired: false,
};

const AppContext = createContext(defaultValue);

function AppContextProvider({ children }) {
  const [subscriptionRequired, setSubscriptionRequired] = useState(
    defaultValue.subscriptionRequired
  );

  useEffect(() => {
    subscriptionService.subscriptionRequired.subscribe({
      next: (isRequired) => {
        setSubscriptionRequired(isRequired);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={{ subscriptionRequired }}>
      {children}
      <UpgradeModal open={subscriptionRequired} />
    </AppContext.Provider>
  );
}

const useAppContext = () => useContext(AppContext);

export { AppContextProvider, useAppContext };

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Loader from "../common/loader";
import SelectField from "../common/selectField";
import { Goal } from "../../constants/goal";
import { addGoal } from "../../services";
import { getFilterOptions, targetValueOptions } from "../../utils";
import { DurationOptions } from "../../constants";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    borderColor: state.hasValue ? "#0075AD" : "#D1D1DB",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.hasValue ? "#0075AD" : "#A9A9BC",
    fontWeight: state.hasValue ? 700 : 400,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.hasValue ? "#0075AD" : "#3F3F50",
  }),
};

export default function AddEditGoalModal({
  users,
  departments,
  reload,
  handleClose,
  goal,
}) {
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState(Goal.Type.TIME);

  const [targetOptions, setTargetOptions] = useState([]);

  const [target, setTarget] = useState();
  const [targetValue, setTargetValue] = useState();
  const [duration, setDuration] = useState();

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const params = {
      type,
      target: data.target,
      targetType: target.group,
      targetValue: data.targetValue,
      duration: data.duration,
    };

    setLoading(true);
    const res = await addGoal(params);
    if (res) {
      if (reload) {
        reload();
      }

      toast.success("Goal added successfully");

      handleClose();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (goal) {
      setValue("target", goal.target);
      setValue("targetValue", goal.targetValue);
      setValue("duration", goal.duration);
    } else {
      setTarget(null);
      setTargetValue(null);
      setDuration(null);

      reset();
    }
  }, [goal, type]);

  useEffect(() => {
    setTargetOptions(getFilterOptions(departments, users));
  }, [users, departments]);

  return (
    <div className="pop-wrapper goal-modal">
      <form className="inner-wrapper wrapper" onSubmit={handleSubmit(onSubmit)}>
        <div className="head p-24">
          <h3 className="title">{goal ? "Edit a" : "Create a new"} Goal</h3>
          <h5 className="subtitle">
            Add goals for your team to keep everyone on target.
          </h5>
        </div>
        <div className="pop-content value-form px-24">
          <div className="align-items-center gap-2 mb-4">
            <div className="label">Type</div>
            <div className="d-flex gap-3 mt-2">
              <div
                className={`button ${type === Goal.Type.TIME && "active"}`}
                onClick={() => setType(Goal.Type.TIME)}
              >
                Save Time
              </div>
              {/* <div
                className={`button ${type === Goal.Type.MONEY && "active"}`}
                onClick={() => setType(Goal.Type.MONEY)}
              >
                Money
              </div> */}
              <div
                className={`button ${
                  type === Goal.Type.PARTICIPANTS && "active"
                }`}
                onClick={() => setType(Goal.Type.PARTICIPANTS)}
              >
                Reduce Participants
              </div>
            </div>

            <div className="label mt-10">Target</div>

            <div className="input-wrap">
              {Goal.Text[type][0] && (
                <div className="input-item mx-3 pt-2">{Goal.Text[type][0]}</div>
              )}
              <SelectField
                className="input-item"
                name="target"
                placeholder={Goal.Placeholder[type][0]}
                control={control}
                value={target}
                options={targetOptions}
                {...register("target", { required: true })}
                error={errors.target}
                components={{ IndicatorSeparator: () => null }}
                styles={customStyles}
                handleChange={(e) => setTarget(e)}
              />
              
              <div className="input-item mx-3 pt-2">{Goal.Text[type][1]}</div>
              <SelectField
                className="input-item"
                name="targetValue"
                placeholder={Goal.Placeholder[type][1]}
                control={control}
                value={targetValue}
                options={targetValueOptions()}
                {...register("targetValue", { required: true })}
                error={errors.targetValue}
                components={{ IndicatorSeparator: () => null }}
                styles={customStyles}
                handleChange={(e) => setTargetValue(e)}
              />
              {type !== Goal.Type.MONEY && (
                <div className="input-item mx-3 pt-2">{Goal.Text[type][2]}</div>
              )}
              {type === Goal.Type.TIME && (
                <SelectField
                  className="input-item"
                  name="duration"
                  placeholder={Goal.Placeholder[type][2]}
                  control={control}
                  value={duration}
                  options={DurationOptions}
                  {...register("duration", { required: true })}
                  error={errors.param3}
                  components={{ IndicatorSeparator: () => null }}
                  styles={customStyles}
                  handleChange={(e) => setDuration(e)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="footer-pop p-24 d-flex justify-content-end">
          <button
            className="btn grey-button fw-bold me-3"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn-primary tc-white fw-700"
            type="submit"
            onClick={() => handleSubmit()}
          >
            Save {loading && <Loader />}
          </button>
        </div>
      </form>
    </div>
  );
}

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getToken, isAdmin, removeToken } from "../utils";
import { fetchMe } from "../services";
import { authSuccess } from "../redux/reducer";
import { startLoading } from "../redux/reducer/app.slice";

export default function AuthRoute({ component }) {
  const navigate = useNavigate();
  const token = getToken();
  const dispatch = useDispatch();

  console.log(
    "[AuthRoute] Token:",
    typeof token === "string" ? token.slice(0, 10) + '...' : token
  );

  const refershToken = async () => {
    try {
      dispatch(startLoading());
      const res = await fetchMe();
      if (res.success) {
        dispatch(authSuccess(res.data));
        if (res.data.user.isAdmin !== isAdmin()) {
          toast.error("Role has been change from admin");
          removeToken();
          navigate("/");
        }
      } else {
        toast.error("Get me faild");
        removeToken();
        navigate("/");
      }
    } catch (err) {
      if (err?.response?.status !== 429) {
        toast.error("Token Validation failed");
        removeToken();
      } else {
        toast.error(err?.response?.data?.message || "Network Error");
      }
      navigate("/");
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/");
    } else if (token) {
      refershToken();
    }
  }, [token]);

  return component;
}

export default function Avatar({ userName, color, size = 40 }) {
  const letter = userName ? userName[0]?.toUpperCase() || "" : "";

  const style = {
    "--size": `${size}px`,
    width: "var(--size)",
    height: "var(--size)",
    background: color,
    fontSize: `${size * 0.4}px`,
    borderRadius: "50%",
  };

  return (
    <div
      className="avatar tw-flex tw-justify-center tw-items-center tw-p-1"
      style={style}
    >
      <span style={{ color: "#fff" }}>{letter}</span>
    </div>
  );
}

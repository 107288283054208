import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CloseIcon } from "../../assets/icons/meetingIcons";
import { Colors } from "../../constants";
import { inviteUsers } from "../../services";
import Loader from "../common/loader";
import { getDecodedUserData } from "../../services/auth.service";
import axios from "axios";
import UpgradeModal from "../modal/UpgradeModal";
import { INVITE_LIST_STORAGE_KEY } from "../../constants/app-config";

const getInviteListFromStorage = () => {
  try {
    const storageData = sessionStorage.getItem(INVITE_LIST_STORAGE_KEY);
    const parsed = JSON.parse(storageData);
    if (Array.isArray(parsed)) {
      return parsed;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export default function SendInviteUserModal({ handleClose, reload }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [inviteList, setInviteList] = useState(getInviteListFromStorage());
  const [typedInvite, setTypedInvite] = useState("");
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [upgradeModalWasClosed, setUpgradeModalWasClosed] = useState(false);
  const [upgradeModalQuantity, setUpgradeModalQuantity] = useState(null);

  function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  async function handleSendInvite() {
    if (inviteList.length === 0) {
      toast.error("Enter any email");
      return;
    }
    // else if (maximumLimit !== null && checkedEmails.length > maximumLimit) {
    //   toast.error(`Maximum invite limit exceeded`);
    //   return;
    // }

    setLoading(true);
    const token = Cookies.get("tokens");
    const fetchUsers = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/users?searchText=`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const totalUsers = fetchUsers.data.data.totalCount;
    const invitesCount = inviteList.length;
    const totalCount = totalUsers + invitesCount;

    if (totalCount > 3 && !upgradeModalWasClosed) {
      const userData = await getDecodedUserData();
      if (userData.isAdmin) {
        const billingHistory = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/user/billing-info`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (billingHistory.data && !billingHistory.data.data.subscription) {
          setOpenUpgradeModal(true);
          setUpgradeModalQuantity(totalCount);
          sessionStorage.setItem(
            INVITE_LIST_STORAGE_KEY,
            JSON.stringify(inviteList)
          );
          setLoading(false);
          return;
        }
      }
    }

    try {
      const res = await inviteUsers(inviteList);
      console.log("===== res: ", res);
      if (res.success) {
        sessionStorage.removeItem(INVITE_LIST_STORAGE_KEY);
        if (res.data.invited.length > 0) {
          toast.success(res.message);
        }
        if (res.data.notInvited.length > 0) {
          res.data.notInvited.map((item) => {
            toast.error(`Email not sent to ${item}`);
          });
        }
        handleClose();
        reload("");
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    }

    setLoading(false);
  }

  const handleCloseUpgradeModal = () => {
    setUpgradeModalWasClosed(true);
    setOpenUpgradeModal(false);
    handleClose();
    reload("");
  };

  const handleAddInvite = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (typedInvite.length > 0) {
        if (!validateEmail(typedInvite)) {
          toast.error("Enter valid email address");
          return;
        }
        const invite = inviteList.find(
          (item) => item.toLowerCase() === typedInvite.toLowerCase()
        );

        if (typeof invite === "undefined") {
          setInviteList([...inviteList, typedInvite]);
        }
      }
      setTypedInvite("");
    }
  };

  const handleRemoveInvite = (invite) => {
    const idx = inviteList.indexOf(invite);
    if (idx > -1) {
      inviteList.splice(idx, 1);
      setInviteList([...inviteList]);
    }
  };

  return (
    <>
      <div className="pop-wrapper">
        <div className="inner-wrapper onboarding-modal">
          <div className="onboarding-header">
            <div className="btn-wrap">
              <div
                className="btn-close"
                onClick={() => {
                  sessionStorage.removeItem(INVITE_LIST_STORAGE_KEY);
                  handleClose();
                }}
              />
            </div>
            <div className="title-wrap">
              <div className="onboarding-title">Invite users</div>
              <div className="onboarding-subtitle">Enter Email Address</div>
            </div>
          </div>
          <div className="onboarding-content-wrap">
            <div className="onboarding-content border-wrap">
              <div className="tag-list">
                {inviteList.map((item, index) => (
                  <div key={`invite-item-${index}`} className="tag-list-item">
                    <span>{item}</span>
                    <div
                      className="tag-list-item-remove-button"
                      onClick={() => handleRemoveInvite(item)}
                    >
                      <CloseIcon color={Colors.Text.Accent.BLUE} />
                    </div>
                  </div>
                ))}
                <input
                  className="tag-input"
                  placeholder="Add a email to invite the user"
                  size={typedInvite.length > 0 ? typedInvite.length : 25}
                  value={typedInvite}
                  onChange={(e) => setTypedInvite(e.target.value)}
                  onKeyDown={handleAddInvite}
                />
              </div>
            </div>
          </div>
          <div className="onboarding-footer">
            <div />
            <button
              className="footer-button next"
              onClick={handleSendInvite}
              disabled={inviteList.length === 0}
            >
              {"Send Invites"} {loading && <Loader />}
            </button>
          </div>
        </div>
      </div>
      <UpgradeModal
        open={openUpgradeModal}
        onClose={handleCloseUpgradeModal}
        quantity={upgradeModalQuantity}
      />
    </>
  );
}

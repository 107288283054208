import { Colors } from "../../constants";

export const CircleDollarIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.875 7.29183H9.47918C8.73129 7.29183 8.12501 7.89811 8.12501 8.646C8.12501 9.39388 8.73129 10.0002 9.47918 10.0002H10.5208C11.2687 10.0002 11.875 10.6064 11.875 11.3543C11.875 12.1022 11.2687 12.7085 10.5208 12.7085H8.12501M10 6.4585V6.87516M10 13.1252V13.5418M16.0417 10.0002C16.0417 13.3369 13.3367 16.0418 10 16.0418C6.66329 16.0418 3.95834 13.3369 3.95834 10.0002C3.95834 6.66344 6.66329 3.9585 10 3.9585C13.3367 3.9585 16.0417 6.66344 16.0417 10.0002Z"
      stroke={color ?? Colors.Icon.DEFAULT}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

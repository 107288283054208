import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Cookies from "js-cookie";

import SendInviteUserModal from "./SendInviteUserModal";
import { WebIcon } from "../../assets/icons/meetingIcons";
import Loader from "../common/loader";
import SelectType from "../common/SelectType";
import { UserRole } from "../../constants";
import { fetchUsers } from "../../services";
import { appDataSelector } from "../../redux/reducer";
import SubscriptionModal from "./SubscriptionModal";
import { firstLetter, isAdmin } from "../../utils";
import { deleteUser, reInvite } from "../../services/user.service";
import { UserRoundX } from "lucide-react";
import { getDecodedUserData } from "../../services/auth.service";
import ConfirmDeleteUserModal from "./ConfirmDeleteUserModal";
import Avatar from "../common/avatar";

const UserTable = () => {
  const dispatch = useDispatch();
  const token = useMemo(() => Cookies.get("tokens"), []);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [userForDelete, setUserForDelete] = useState("");
  const [isDeleteUserLoading, setIsDeleteUserLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setCurrentUserData(await getDecodedUserData());
    })();
  }, []);

  const { department } = useSelector(appDataSelector);

  const [userModal, setUserModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState({ name: "ss", id: "" });

  const [page, setPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  const loadUsers = useCallback(
    async (search) => {
      try {
        const params = {
          searchText: search,
          page,
        };
        if (department) {
          params["department"] = department.value;
        }
        const res = await fetchUsers(params);
        if (res.success) {
          setUserList(res.data.data);
          setPageCount(res.data.totalPages);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [department, page]
  );

  const searchHandler = useCallback(
    (e) => {
      setSearchText(e.target.value);
      loadUsers(e.target.value);
    },
    [loadUsers]
  );

  const reInviteHandler = useCallback(async (email) => {
    try {
      const res = await reInvite(email);
      if (res) {
        toast.success(res.message);
      } else {
        toast.error("Reinvite failed");
      }
    } catch (err) {
      toast.error(err.message);
    }
  }, []);

  const updateStatusHandler = useCallback(
    async (id, status) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/updateStatus`,
          { _id: id, status: status },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success(response.data.message);
        loadUsers("");
      } catch (error) {
        toast.error(error.response.data.message);
        setValue({ name: "", id });
        setTimeout(() => {
          setValue({ name: "ss", id: "" });
        }, 900);
      }
    },
    [token, loadUsers]
  );

  const updateRoleHandler = useCallback(
    async (id, role) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/update-role`,
          { userId: id, isAdmin: role === UserRole.ADMIN },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success(response.data.message);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [token]
  );

  const handlePageClick = useCallback((event) => {
    setPage(event.selected + 1);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleDeleteUser = useCallback(async (id) => {
    setIsDeleteUserLoading(true);
    try {
      await deleteUser(id);
    } catch (error) {
      console.error(error);
    }
    loadUsers("");
    setUserForDelete(null);
    setIsDeleteUserLoading(false);
  }, []);

  useEffect(() => {
    if (department) {
      loadUsers("");
    }
  }, [department, page]);

  return (
    <div className="data-tables overflow-hidden">
      <div className="d-flex align-items-center justify-content-between">
        <div className="search">
          <input
            className="inputlabel"
            placeholder="Search users"
            type="text"
            value={searchText}
            onChange={searchHandler}
          />
        </div>
        <button
          type="button"
          className="btn-primary"
          onClick={() => setUserModal(true)}
        >
          Add User
        </button>
      </div>

      <div className="table-responsive pagination mt-6">
        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0 ">
          <thead>
            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
              <th className="min-w-175px text-start">User</th>
              <th className="text-start">Compensation</th>
              <th className="text-start">Department</th>
              <th className="text-start">Role</th>
              <th className="text-start">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userList.length > 0 ? (
              userList.map((item, index) => (
                <tr key={index} className="tablerow">
                  <td>
                    {isAdmin() ? (
                      <Link
                        to={`/users/${item.id}`}
                        className={`d-flex align-items-center gap-3 user-list-data`}
                      >
                        <UserCell item={item} index={index} />
                      </Link>
                    ) : (
                      <div
                        className={`d-flex align-items-center gap-3 user-list-data`}
                      >
                        <UserCell item={item} index={index} />
                      </div>
                    )}
                  </td>

                  <td>
                    <p className="fs-14 fw-bold m-0 lt-grey">
                      {item.currency}
                      {item.compensation}
                    </p>
                  </td>
                  <td>
                    <p className="fs-14 fw-bold m-0 lt-grey text-capitalize">
                      {item.departmentName ? item.departmentName : "N/A"}
                    </p>
                  </td>
                  <td>
                    <div className="user-list-select">
                      <SelectType
                        options={[
                          { value: UserRole.ADMIN, label: UserRole.ADMIN },
                          { value: UserRole.USER, label: UserRole.USER },
                        ]}
                        defaultValue={{
                          value: item.isAdmin ? UserRole.ADMIN : UserRole.USER,
                          label: item.isAdmin ? UserRole.ADMIN : UserRole.USER,
                        }}
                        isSearchable={false}
                        handleChange={(e) =>
                          updateRoleHandler(item.id, e.value)
                        }
                      />
                    </div>
                  </td>
                  <td>
                    {item.workspaceAdmin ? (
                      <div className="user-list-select d-flex align-items-center px-4">
                        <div
                          className={`circle-status ${
                            item.status ? "active" : "inactive"
                          }`}
                        />
                        <SelectType
                          options={[
                            { value: true, label: "Active" },
                            { value: false, label: "Inactive" },
                          ]}
                          defaultValue={{
                            value: item.status,
                            label: item.status ? "Active" : "Inactive",
                          }}
                          isSearchable={false}
                          handleChange={(e) =>
                            updateStatusHandler(item._id, e.value)
                          }
                        />
                      </div>
                    ) : item.profileId ? (
                      value.id === item.id ? (
                        <span className="d-flex justify-content-center w-100">
                          <Loader />
                        </span>
                      ) : (
                        <div className="user-list-select d-flex align-items-center px-4">
                          <div
                            className={`circle-status ${
                              item.status ? "active" : "inactive"
                            }`}
                          />
                          <SelectType
                            options={[
                              { value: true, label: "Active" },
                              { value: false, label: "Inactive" },
                            ]}
                            defaultValue={{
                              value: item.status,
                              label: item.status ? "Active" : "Inactive",
                            }}
                            isSearchable={false}
                            handleChange={(e) =>
                              updateStatusHandler(item.id, e.value)
                            }
                          />
                        </div>
                      )
                    ) : (
                      <button
                        type="button"
                        // className="btn-reinvite border-0"
                        className="btn-primary btn-reinvite border-0"
                        onClick={() => {
                          reInviteHandler(item.email);
                        }}
                      >
                        Re-invite
                      </button>
                    )}
                  </td>
                  <td>
                    {currentUserData && currentUserData.id !== item.id && (
                      <>
                        <button
                          type="button"
                          onClick={() => setUserForDelete(item)}
                          className="tw-text-red-500 tw-border tw-border-red-500 hover:tw-bg-red-600 hover:tw-text-white focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-red-300 tw-font-medium tw-rounded-lg tw-text-sm tw-p-2 tw-text-center tw-inline-flex tw-items-center"
                        >
                          <UserRoundX size={16} />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="tablerow">
                <td colSpan="6">
                  <p className="py-2 fs-5 text-cl-grey fw-bold text-center">
                    No Data Available
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {userList?.length > 0 && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className={"pagination"}
          />
        )}
      </div>
      {userModal && (
        <SendInviteUserModal
          handleClose={() => setUserModal(false)}
          reload={loadUsers}
        />
      )}
      {showSubscriptionModal && <SubscriptionModal />}
      {userForDelete && (
        <ConfirmDeleteUserModal
          user={userForDelete}
          onClose={() => setUserForDelete(null)}
          onConfirm={() => handleDeleteUser(userForDelete.id)}
          loading={isDeleteUserLoading}
        />
      )}
    </div>
  );
};

function UserCell({ item, index }) {
  return (
    <>
      <Avatar userName={item.name || item.email} color={item.avatarColor} />
      <span className="d-flex flex-column">
        <h5 className="fs-14 fw-bold m-0 text-capitalize">{item.name}</h5>
        <p className="font-12 fw-semibold m-0 lt-grey">{item.email}</p>
      </span>
    </>
  );
}

export default UserTable;

import { fetchDepartments, fetchUsers } from "../../services";
import { failure, updateDepartments, updateUsers } from "../reducer";

export function getMe() {
  return async (dispatch) => {
    try {
    } catch (err) {
      console.log(err);
    }
  };
}

export function getDepartments(params) {
  return async (dispatch) => {
    try {
      const res = await fetchDepartments(params);
      if (res.success) {
        dispatch(updateDepartments(res.data));
      }
    } catch (err) {
      console.log(err);
      dispatch(failure(err.message));
    }
  };
}

export function getUsers(params) {
  return async (dispatch) => {
    try {
      const res = await fetchUsers(params);
      if (res.success) {
        dispatch(updateUsers(res.data.data));
      }
    } catch (err) {
      console.log(err);
      dispatch(failure(err.message));
    }
  };
}

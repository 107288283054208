import React from "react";

export default function GoogleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M29.3097 13.6434H28.2357V13.5881H16.2357V18.9214H23.771C22.6717 22.0261 19.7177 24.2547 16.2357 24.2547C11.8177 24.2547 8.23568 20.6727 8.23568 16.2547C8.23568 11.8367 11.8177 8.25472 16.2357 8.25472C18.275 8.25472 20.1303 9.02405 21.543 10.2807L25.3143 6.50939C22.933 4.29005 19.7477 2.92139 16.2357 2.92139C8.87234 2.92139 2.90234 8.89139 2.90234 16.2547C2.90234 23.6181 8.87234 29.5881 16.2357 29.5881C23.599 29.5881 29.569 23.6181 29.569 16.2547C29.569 15.3607 29.477 14.4881 29.3097 13.6434Z"
        fill="#FFC107"
      />
      <path
        d="M4.43945 10.0487L8.82012 13.2614C10.0055 10.3267 12.8761 8.25472 16.2355 8.25472C18.2748 8.25472 20.1301 9.02405 21.5428 10.2807L25.3141 6.50939C22.9328 4.29005 19.7475 2.92139 16.2355 2.92139C11.1141 2.92139 6.67279 5.81272 4.43945 10.0487Z"
        fill="#FF3D00"
      />
      <path
        d="M16.2352 29.5882C19.6792 29.5882 22.8086 28.2702 25.1746 26.1269L21.0479 22.6349C19.6643 23.6871 17.9735 24.2562 16.2352 24.2549C12.7672 24.2549 9.82257 22.0435 8.71323 18.9575L4.36523 22.3075C6.5719 26.6255 11.0532 29.5882 16.2352 29.5882Z"
        fill="#4CAF50"
      />
      <path
        d="M29.3094 13.6437H28.2354V13.5884H16.2354V18.9217H23.7707C23.2448 20.3993 22.2976 21.6905 21.046 22.6357L21.048 22.6344L25.1747 26.1264C24.8827 26.3917 29.5687 22.9217 29.5687 16.255C29.5687 15.361 29.4767 14.4884 29.3094 13.6437Z"
        fill="#1976D2"
      />
    </svg>
  );
}

import React, { memo, useEffect, useState } from "react";
import { Chart, Colors, Unit } from "../../constants";
import Loader from "../common/loader";
import ChartComponent from "./ChartComponent";
import { formattedLabel } from "../../utils";

const PieChart = ({ loading, title, chartData, unit = Unit.HR }) => {
  const [data, setData] = useState();
  const [options, setOptions] = useState();

  const [percentOne, setPercentOne] = useState(0);
  const [percentTwo, setPercentTwo] = useState(0);

  const getStopPos = (sum, value, index) => {
    let st = 0;
    let ed = 0;
    if (index == 0) {
      st = 0;
      ed = value / sum;
    } else {
      st = 1 - value / sum;
      ed = 1;
    }

    return { start: st, end: ed };
  };

  const startColor = (index) => {
    if (index === 0) {
      return Colors.Gradient.Green.START;
    }

    return Colors.Gradient.Pink.START;
  };

  const stopColor = (index) => {
    if (index === 0) {
      return Colors.Gradient.Green.END;
    }

    return Colors.Gradient.Pink.END;
  };

  const backgroundColor = (context) => {
    const { chart, datasetIndex, index } = context;
    const ds = chart.data.datasets[datasetIndex];
    const value = ds.data[index];
    const meta = chart.getDatasetMeta(datasetIndex);
    const data = meta.data[index];
    const { x, y } = data;
    let totalValue = 0;
    for (const val of ds.data) {
      totalValue += val;
    }
    if (
      totalValue > 0 &&
      x &&
      y &&
      typeof x !== "undefined" &&
      typeof y !== "undefined"
    ) {
      const stopPos = getStopPos(totalValue, value, index);
      const ctx = chart.ctx;
      const gradient = ctx.createConicGradient(Math.PI / 2, x, y);
      gradient.addColorStop(stopPos.start, startColor(index));
      gradient.addColorStop(stopPos.end, stopColor(index));
      return gradient;
    }
  };

  useEffect(() => {
    if (chartData && chartData.labels && chartData.data) {
      const tempData = JSON.parse(JSON.stringify(Chart.Pie.DATA));
      tempData.datasets[0].data = chartData.data;
      tempData.datasets[0].backgroundColor = backgroundColor;
      setData(tempData);

      const tempOptions = JSON.parse(JSON.stringify(Chart.Pie.OPTIONS));
      setOptions(tempOptions);

      if (chartData.data.length > 0) {
        const sum = chartData.data[0] + chartData.data[1];
        if (sum !== 0) {
          const tempPercentOne = parseInt((chartData.data[0] * 100) / sum);
          setPercentOne(tempPercentOne);
          if (chartData.data[1] !== 0) {
            setPercentTwo(100 - tempPercentOne);
          }
        }
      }
    }
  }, [chartData]);

  return (
    <div className="pie-chart">
      {loading && <Loader className="mx-auto" />}
      {!loading && chartData && chartData.labels && (
        <div>
          <div className="title mb-4">{title}</div>
          <ChartComponent
            chartType={Chart.Type.DOUGHNUT}
            data={data}
            options={options}
          />
          <div className="d-flex info">
            <div className="w-100 text-align-center left">
              <div className="label">{chartData.labels[0]}</div>
              <div className="d-flex align-items-center justify-content-center gap-4">
                <span className="value">
                  {formattedLabel(chartData.data[0], unit)}
                </span>
                <span className="diff">{percentOne}%</span>
              </div>
            </div>
            <div className="w-100 text-align-center right">
              <div className="label">{chartData.labels[1]}</div>
              <div className="d-flex align-items-center justify-content-center gap-4">
                <span className="value">
                  {formattedLabel(chartData.data[1], unit)}
                </span>
                <span className="diff">{percentTwo}%</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(PieChart);

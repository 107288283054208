import { Link } from "react-router-dom";

function ErrorScreen() {
  return (
    <div className="vh-min-100 d-flex justify-content-center align-items-center w-100 flex-column h-100">
      <h2>Page not found</h2>
      <Link to="/dashboard">Click Here</Link>
    </div>
  );
}
export default ErrorScreen;

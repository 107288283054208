import axios from "axios";
import { getHeaders } from "../utils";
import { appConfig } from "../constants";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export async function authenticateWithGoogle(code) {
  try {
    const url = `${appConfig.apiBaseUrl}/auth/google`;
    const res = await axios.post(url, { code });
    return res.data;
  } catch (err) {
    console.log(err);
  }
  return null;
}

export async function fetchMe() {
  try {
    const url = `${appConfig.apiBaseUrl}/auth/me`;
    const headers = getHeaders();
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (err) {
    console.log(err);
  }
  return null;
}

export async function getDecodedUserData() {
  const token = Cookies.get("tokens");
  return jwt_decode(token);
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LogoIcon from "../../assets/icons/logoIcon";
import Loader from "../../component/common/loader";
import { getToken, removeToken, setToken } from "../../utils";
import SendInviteUserModal from "../../component/users/SendInviteUserModal";

function SendInvite() {
  const [loading, setLoading] = useState(true);
  const token = getToken();
  const navigate = useNavigate();

  const paymentSelected = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/payment-selected`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setToken(res.data.data.updatedToken);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        toast.error("Token Validation failed");
        navigate("/");
      });
  };
  useEffect(() => {
    if (!token) {
      removeToken();
      navigate("/");
      toast.error("Token Validation failed");
    } else {
      paymentSelected();
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex flex-column align-items-center justify-content-center vh100">
          <Loader />
          <p className="fw-bold  px-0 pt-0 fs-24">Fetching Data</p>
        </div>
      ) : (
        <div className="vh-min-100 d-flex">
          <div className="d-flex flex-column flex-lg-row w-100 w-lg-50 ">
            <div className="d-flex flex-center w-lg-50 flex-column w-100">
              <SendInviteUserModal />
            </div>
            <div className="d-flex w-100 w-lg-50 order-1 order-lg-2 bg-blue-lt">
              <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
                <LogoIcon />
                {/*<img src="../assets/images/logo.svg" alt="logo" />*/}
                <img
                  className="mt-16"
                  src="../assets/images/cuate.svg"
                  width={450}
                  alt="calender"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default SendInvite;

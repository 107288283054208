import React, { useEffect, useState } from "react";
import TimezoneSelect from "react-timezone-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SelectType from "../common/SelectType";
import Loader from "../common/loader";
import { Colors, CurrencyOptions } from "../../constants";
import { useNavigate } from "react-router-dom";
import {
  appDataSelector,
  updateCompensation,
  updateCurrency,
} from "../../redux/reducer";
import { fetchSettings, saveSettings } from "../../services";
import { CloseIcon } from "../icons";
import { getDepartments } from "../../redux/actions";

export default function TeamPofile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { departments, compensation, currency } = useSelector(appDataSelector);

  const [settings, setSettings] = useState();

  const [loading, setLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [typedDept, setTypedDept] = useState("");
  const [department, setDepartment] = useState("");
  const [currencyOptions] = useState(CurrencyOptions);
  const [editableCurrency, setCurrency] = useState();
  const [editableCompensation, setCompensation] = useState(compensation);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [oneParticipantExclude, setOneParticipantExclude] = useState(false);

  const loadSettings = async () => {
    setLoading(true);
    const res = await fetchSettings();
    if (res && res.success) {
      setSettings(res.data);
      setOneParticipantExclude(res.data.oneParticipantExclude);
    }
    setLoading(false);
  };

  const initDepartmentList = () => {
    const tempDepartmentList = [];
    for (const dept of departments) {
      tempDepartmentList.push(dept.name);
    }
    setDepartmentList(tempDepartmentList);
  };

  const handleAddDepartment = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (typedDept.length > 0) {
        const dept = departmentList.find(
          (item) => item.toLowerCase() === typedDept.toLowerCase()
        );

        if (typeof dept === "undefined") {
          setDepartmentList([...departmentList, typedDept]);
        }
      }
      setTypedDept("");
    }
  };

  const handleRemoveDepartment = (dept) => {
    const idx = departmentList.indexOf(dept);
    if (idx > -1) {
      if (departmentList[idx] === department) {
        setDepartment("");
      }

      departmentList.splice(idx, 1);
      setDepartmentList([...departmentList]);
    }
  };

  const handleSave = async () => {
    try {
      if (department === "") {
        toast.error("Please select your department");
        return;
      }
      const removedDepartments = [];
      const addedDepartments = [];

      for (const dept of departmentList) {
        const idx = departments.findIndex((item) => item.name === dept);
        if (idx === -1) {
          addedDepartments.push(dept);
        }
      }

      for (const dept of departments) {
        const idx = departmentList.findIndex((item) => item === dept.name);
        if (idx === -1) {
          removedDepartments.push(dept.id);
        }
      }

      const params = {
        department,
        compensation: editableCompensation,
        currency: currency.value,
        timezone: typeof timezone === "string" ? timezone : timezone.value,
        oneParticipantExclude,
        addedDepartments,
        removedDepartments,
      };

      setLoading(true);
      const res = await saveSettings(params);
      if (res && res.success) {
        setSettings(res.data);
        dispatch(updateCompensation(res.data.compensation));
        dispatch(updateCurrency(res.data.currency));
        dispatch(getDepartments());
      }
    } catch (err) {
      console.log(err);
      toast.error(JSON.stringify(err));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (departments.length > 0) {
      initDepartmentList();
    }

    if (typeof settings !== "undefined" && settings) {
      setCompensation(settings.compensation);
      const idx = CurrencyOptions.findIndex(
        (item) => item.value === settings.currency
      );
      if (idx > -1) {
        setCurrency(CurrencyOptions[idx]);
      }

      if (settings?.timezone) {
        setTimezone(settings?.timezone);
      }

      if (
        typeof settings.departmentId !== "undefined" &&
        settings.departmentId &&
        departments.length > 0
      ) {
        const dept = departments.find(
          (item) => item.id === settings.departmentId
        );
        if (typeof dept !== "undefined") {
          setDepartment(dept.name);
        }
      }
    }
  }, [departments, settings]);

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <>
      <div className="team-profile">
        {/* <div className="text-input-wrap mt-10">
          <label htmlFor="teamName">Team name</label>
          <input className="input" name="teamName" placeholder="Team name" />
        </div> */}
        <div className="text-input-wrap mt-10">
          <label>Departments</label>
          <div className="department-wrap">
            <div className="tag-list">
              {departmentList.map((item, index) => (
                <div
                  key={`departments-item-${index}`}
                  className={`cursor-pointer tag-list-item ${
                    item === department ? "active" : ""
                  }`}
                >
                  <span onClick={() => setDepartment(item)}>{item}</span>
                  <div
                    className="tag-list-item-remove-button"
                    onClick={() => handleRemoveDepartment(item)}
                  >
                    <CloseIcon
                      color={
                        item === department
                          ? Colors.WHITE
                          : Colors.Text.Accent.BLUE
                      }
                    />
                  </div>
                </div>
              ))}
              <input
                className="tag-input"
                size={typedDept.length > 0 ? typedDept.length : 1}
                value={typedDept}
                onChange={(e) => setTypedDept(e.target.value)}
                onKeyDown={handleAddDepartment}
              />
            </div>
          </div>
        </div>
        <div className="text-input-wrap mt-6">
          <label>Average hourly rate</label>
          <div className="input-wrap">
            <label>{editableCurrency?.value}</label>
            <input
              type="number"
              value={editableCompensation}
              onChange={(e) => setCompensation(e.target.value)}
            />
            <SelectType
              isSearchable={false}
              value={editableCurrency}
              options={currencyOptions}
              handleChange={(e) => setCurrency(e)}
            />
          </div>
        </div>

        <div className="text-input-wrap mt-6">
          <label htmlFor="timezone">Time zone</label>
          <TimezoneSelect
            className="timezone-select"
            name="timezone"
            value={timezone}
            onChange={setTimezone}
          />
        </div>
        <div className="text-input-wrap">
          <div className="form-check form-switch mt-6">
            <label className="form-switch-label">
              Exclude all meetings that only have one participant
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              value={oneParticipantExclude}
              checked={oneParticipantExclude}
              onChange={(e) => setOneParticipantExclude(e.target.checked)}
            />
          </div>
        </div>
        <div className="d-flex mt-10 gap-4">
          <button
            className="btn-primary fw-bold d-flex gap-3 align-items-center px-10"
            onClick={handleSave}
            disabled={loading}
          >
            Save {loading && <Loader />}
          </button>
          <button
            className="btn fw-bold me-3"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

const avatarColors = {
  PURPLE: "#7047EB",
  GREEN: "#2DCA72",
  PINK: "#F75FDE",
  BLUE: "#00ACFF",
  YELLOW: "#FFC233",
  ORANGE: "#FF7D52",
  RED: "#F53D6B",
};

export function assignBrandingColorsToUsersList(users) {
  const colors = Object.values(avatarColors);

  return users.map((user, index) => {
    const brandingColor = colors[index % colors.length];
    return {
      brandingColor,
      ...user,
    };
  });
}

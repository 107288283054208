import React, { useState } from "react";
import { CloseIcon } from "../icons";

import icCircleMinus from "../../assets/icons/ic_circle-minus.svg";
import icCirclePlus from "../../assets/icons/ic_circle-plus.svg";

export default function UpdateUserSeatsModal({
  numOfUsers,
  handleSubmit,
  handleClose,
  setSeatsQuantity,
  setError,
  setErrorMessage,
  error,
  errorMessage,
  seatsQuantity,
}) {
  const [seats, setSeats] = useState(numOfUsers);

  const handleClickMinus = () => {
    if (seats > 0) {
      setSeats(seats - 1);
    }
  };

  const handleClickPlus = () => {
    setSeats(seats + 1);
  };

  return (
    <div className="pop-wrapper update-user-seats-modal">
      <div className="inner-wrapper">
        <div className="head p-24">
          <div
            className="d-flex justify-content-end cursor-pointer"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
          <div className="fs-24 fw-700 tc-body">Update User Seats</div>
          <div className="fs-16 tc-secondary">Add or remove users</div>
        </div>
        <div className="pop-content p-24 py-3">
          <div className="d-flex align-items-center justify-content-center">
            <img
              className="circle-button"
              src={icCircleMinus}
              onClick={handleClickMinus}
            />
            <div className="fs-96 fw-700 text-blue-gradient mx-4">{seats}</div>
            <img
              className="circle-button"
              src={icCirclePlus}
              onClick={handleClickPlus}
            />
          </div>
        </div>
        <div className="footer-pop p-24 d-flex justify-content-end">
          <button
            className="btn grey-button fw-bold me-3"
            type="button"
            onClick={handleClose}
          >
            Back
          </button>
          <button
            className="btn-primary tc-white fw-700"
            type="button"
            // disabled={!seatsQuantity || error}
            onClick={() => handleSubmit(seats)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";
import { appConfig } from "../constants";
import { getHeaders } from "../utils";

export async function getDashboardData(params) {
  try {
    const url = `${appConfig.apiBaseUrl}/dashboard/get-data`;
    const headers = getHeaders();
    const res = await axios.get(url, {headers, params});
    return res.data;
  } catch(err) {
    console.log(err);
  }

  return null;
}

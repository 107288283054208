import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SideBar from "../../component/sidebar/SideBar";
import Header from "../../component/common/header";
import TotalChart from "../../component/dashboard/TotalChart";
import TimePieChart from "../../component/dashboard/TimePieChart";

import { HEADER, MetricsType, Unit } from "../../constants";
import { getMetrics } from "../../services";
import { capitalizeFirstLetter, formattedLabel } from "../../utils";
import { appDataSelector } from "../../redux/reducer";

function Metrics() {
  const { metricsType, meetingType, dateRange } = useSelector(appDataSelector);

  const [loading, setLoading] = useState(false);

  const [totalTime, setTotalTime] = useState(0);
  const [barChartData, setBarChartData] = useState([]);
  const [pieChartData1, setPieChartData1] = useState({});
  const [pieChartData2, setPieChartData2] = useState({});
  const [avgUnit, setAvgUnit] = useState();
  const [avgTime, setAvgTime] = useState(0);
  const [mostMet, setMostMet] = useState("---");
  const [mostDay, setMostDay] = useState("---");

  const loadMetrics = async (params) => {
    setLoading(true);

    const res = await getMetrics(params);
    if (res) {
      const data = res.data;

      setBarChartData(data.chartData.barChartData);
      setPieChartData1(data.chartData.pieChartData1);
      setPieChartData2(data.chartData.pieChartData2);

      setTotalTime(data.totalTime);
      setAvgUnit(data.avgUnit);
      setAvgTime(data.avgTime);
      setMostMet(data.mostMet || "---");
      setMostDay(data.mostDay || "---");
    }

    setLoading(false);
  };

  useEffect(() => {
    if (meetingType) {
      const params = {
        metricsType,
        dateRange: dateRange.value,
        filterOption: meetingType.group,
        filterValue: meetingType.value,
      };

      loadMetrics(params);
    }
  }, [metricsType, meetingType, dateRange]);

  return (
    <div
      className="d-flex vh-min-100 bg-blue-lt metrics"
      data-kt-app-sidebar-fixed="true"
    >
      <div className="d-flex flex-column flex-root app-root">
        <div className="app-page flex-column flex-column-fluid">
          <div className="app-wrapper flex-column flex-row-fluid">
            <SideBar />
            <div className="app-main flex-column flex-row-fluid">
              <Header heading={HEADER.Title.Metrics} />
              <div className="app-content flex-column-fluid content-wrapper p-0">
                <div className="d-flex">
                  <TotalChart
                    className="p-10 flex-grow-1"
                    loading={loading}
                    totalTime={totalTime}
                    chartData={barChartData}
                    metricsType={metricsType}
                  />
                  <div className="metrics-info p-8">
                    <div className="item">
                      <div className="label">
                        Avg {metricsType === MetricsType.COST ? "cost" : "time"}{" "}
                        in meeting per {avgUnit}
                      </div>
                      <div className="value text-blue-gradient">
                        {formattedLabel(
                          avgTime,
                          metricsType === MetricsType.COST
                            ? Unit.DOLLAR
                            : Unit.HRS
                        )}
                      </div>
                    </div>
                    <div className="item">
                      <div className="label">
                        Most met with department / person
                      </div>
                      <div className="value text-blue-gradient">
                        {capitalizeFirstLetter(mostMet)}
                      </div>
                    </div>
                    {/* <div className="item">
                        <div className="label">Most met with team</div>
                        <div className="value text-blue-gradient">Dev</div>
                      </div> */}
                    <div className="item">
                      <div className="label">Day with most meeting time</div>
                      <div className="value text-blue-gradient">{mostDay}</div>
                    </div>
                  </div>
                </div>
                <TimePieChart
                  className="p-10"
                  loading={loading}
                  chartData1={pieChartData1}
                  chartData2={pieChartData2}
                  metricsType={metricsType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Metrics;

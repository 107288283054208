import { Colors } from "../../constants";

export const CloseIcon = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 4.5L4.5 11.5M4.5 4.5L11.5 11.5"
      stroke={color ?? Colors.Icon.DEFAULT}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

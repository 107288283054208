import axios from "axios";
import { appConfig } from "../constants";
import { getHeaders } from "../utils";

export async function getMeetingDetail(meetingId) {
  try {
    const url = `${appConfig.apiBaseUrl}/meetings/${meetingId}`;
    const headers = getHeaders();
    const res = await axios.get(url, { headers });
    return res.data;
  } catch (err) {
    console.log(err);
  }

  return null;
}

export async function getPaginatedMeetings(params) {
  try {
    const url = `${appConfig.apiBaseUrl}/meetings`;
    const headers = getHeaders();
    const res = await axios.get(url, { headers, params });
    return res.data;
  } catch (err) {
    console.log(err);
  }

  return null;
}

export async function excludeGoal(meetingId, params) {
  try {
    const url = `${appConfig.apiBaseUrl}/meetings/${meetingId}/exclude-goal`;
    const headers = getHeaders();
    const res = await axios.post(url, params, { headers });
    return res.data;
  } catch (err) {
    console.log(err);
  }

  return null;
}

export async function excludeMetrics(meetingId, params) {
  try {
    const url = `${appConfig.apiBaseUrl}/meetings/${meetingId}/exclude-metrics`;
    const headers = getHeaders();
    const res = await axios.post(url, params, { headers });
    return res.data;
  } catch (err) {
    console.log(err);
  }

  return null;
}
